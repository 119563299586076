export const backend = 'https://api.pizzabunga.pl/';
// export const backend = 'http://localhost:8080/';

// Login
export const login = 'api/authenticate';

// Get person data
export const getPersonData = 'api/user/admin';

// Update user
export const updateUser = 'api/account/secured/profile';

// Get all pizzas and products
export const getAllPizzasAndProducts = 'api/product-order-numbers';

// Get all pizzas
export const getAllPizzas = 'api/pizzas';

// Get all categories
export const getAllCategories = 'api/categories';

// Get all products
export const getAllProducts = 'api/products';

// Get all special offers
export const getAllSpecialOffers = 'api/special-offers';

// Create Pizza Purchase
export const createPizzaPurchase = 'api/purchases';

// Get discount
export const getDiscount = 'api/discounts/redeem'

// Get all ingriedients
export const getAllIngriedients = 'api/allIngriedients';

// Send work CV
export const sendWorkCV = 'api/job-applies';

// Send contact message
export const sendContactMessage = 'api/contact-forms';

export const getPremises = 'api/premises';
export const getPostCodeData = 'api/addresses/premises-id';
export const getServerTime = 'api/premises/time';
export const getAddressData = 'api/addresses/postcode/address';
export const cities = 'api/cities';
export const getPolygon = 'api/addresses';