import React, {Component} from "react";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";

class Privacy extends Component{
    componentDidMount() {
        let nav = document.querySelectorAll('.navbar__link');
        for (let i = 0; i < nav.length; i++) {
            nav[i].classList.remove('is-active');
        }
        console.log(window.location.href)
    }
    render() {
        return (
            <>
                <Navbar sum={this.props.sum} counter={this.props.counter} summary={this.props.summary} />
                <section className="text-section">
                    <p>Polityka prywatności</p>
                    <p>§1 Przepisy ogólne</p>
                    <p>⦁ Administratorem danych osobowych Usługobiorcy przekazanych Usługodawcy w&nbsp;ramach świadczenia
                        przez Usługodawcę usług drogą elektroniczną jest {window.location.href.includes('poleska') ? 'M&M GASTRO Michał Bartczyszyn Chopina 24/2, kod pocztowy 48-340, NIP: 7532434659,' : 'Kraft Polska spółka z ograniczoną odpowiedzialnością z siedzibą we Wrocławiu przy ul. Rogowskiej 7, kod pocztowy 54 – 400, NIP: 8942996311, o kapitale zakładowym w wysokości 100&nbsp;000 zł której akta rejestrowe są prowadzone przez Sąd Rejonowy dla Wrocławia – Fabrycznej we Wrocławiu, VI Wydział Gospodarczy pod numerem KRS: 0000353723.'}<br />⦁ Usługodawca oświadcza, iż dane osobowe Usługobiorcy nie będą przekazywane do państw
                        trzecich ani organizacji międzynarodowych.<br />⦁ Usługodawca dokonuje profilowania w postaci
                        przedstawiania informacji o produktach, nowościach i promocjach, które mogą potencjalnie
                        zainteresować Usługobiorcę.<br/>⦁ Usługodawca udostępnia mail kontaktowy dla Usługobiorców:
                        biuro@pizzabunga.pl.<br />⦁ Znaczenie nadane pojęciom na podstawie §2 Regulaminu
                        świadczeniu usług drogą elektroniczną znajdują zastosowanie również w niniejszej
                        Polityce prywatności i polityce cookies.</p>
                    <p>§2 Przetwarzanie danych</p>
                    <p>⦁ Usługodawca przetwarza dane osobowe Usługobiorców, tj. imię i nazwisko, firma, NIP, dane
                        teleadresowe i adresowe, numer telefonu, numer konta, adres IP, treść zapytania/reklamacji/uwag, a
                        także inne identyfikatory oraz informacje gromadzone poprzez pliki cookies.<br />⦁ Dane osobowe
                        Usługobiorców są rejestrowane w logach systemowych.<br />⦁ Usługodawca przetwarza dane osobowe
                        Usługobiorców nie posiadających Konta, korzystających ze strony internetowej, w
                        następujących&nbsp;celach:<br />⦁ przeprowadzenia rekrutacji spośród zgłoszonych kandydatur na
                        stanowisko pracy, a podstawą przetwarzania danych jest zgoda Usługobiorcy zgodnie z
                        przepisem art. 6 ust. 1 lit. a) RODO,<br />⦁ realizacji umowy o świadczeniu usług drogą
                        elektroniczną w zakresie udostępniania zasobów strony internetowej, a podstawą
                        przetwarzania jest niezbędność do realizacji umowy zgodnie z przepisem art. 6 ust. 1
                        lit b) RODO,<br />⦁ obsługi zapytania/uwag, a podstawą przetwarzania jest niezbędność
                        do realizacji umowy zgodnie z przepisem art. 6 ust. 1 lit b) RODO,<br />⦁ obsługi
                        reklamacji, a podstawą przetwarzania jest obowiązek prawny, zgodnie z
                        przepisem art. 6 ust. 1 lit. c) RODO;<br />⦁ analitycznych i statystycznych w
                        postaci analizy aktywności Usługobiorców, ich preferencji, a podstawą
                        przetwarzania jest prawnie uzasadniony interes Usługodawcy zgodnie z
                        przepisem art. 6 ust. 1 lit. f) RODO;<br />⦁ technicznych,
                        administracyjnych w związku z koniecznością zapewnienia
                        bezpieczeństwa systemu informatycznego oraz jego administrowaniem, a
                        podstawą przetwarzania jest prawnie uzasadniony interes Usługodawcy
                        zgodnie z przepisem art. 6 ust. 1 lit. f) RODO;<br />⦁ prowadzenia
                        korespondencji e-mail między Usługodawcą i Usługobiorcą w
                        związku z działalnością gospodarczą Usługodawcy, a podstawą
                        przetwarzania jest prawnie uzasadniony interes Usługodawcy
                        zgodnie z przepisem art. 6 ust. 1 lit. f) RODO;<br />⦁ dochodzenia
                        lub ustalenia roszczeń albo obrony przed nimi, a podstawą
                        przetwarzania jest prawnie uzasadniony interes Usługodawcy
                        zgodnie z przepisem art. 6 ust. 1 lit. f) RODO, w związku z
                        ochroną jego praw.<br />⦁ Usługodawca przetwarza dane osobowe
                        Usługobiorców posiadających Konto, w
                        następujących&nbsp;celach:<br />⦁ realizacji umowy o
                        świadczeniu usług drogą elektroniczną, a podstawą
                        przetwarzania jest niezbędność do realizacji umowy
                        zgodnie z przepisem art. 6 ust. 1 lit b) RODO,<br />⦁
                        obsługi zapytania/uwag, a podstawą przetwarzania
                        jest niezbędność do realizacji umowy zgodnie z
                        przepisem art. 6 ust. 1 lit b) RODO,<br />⦁
                        obsługi reklamacji, a podstawą przetwarzania
                        jest obowiązek prawny, zgodnie z przepisem
                        art. 6 ust. 1 lit. c) RODO;<br />⦁ prowadzenia
                        korespondencji e-mail lub telefonicznej
                        między Usługodawcą i Usługobiorcą w
                        związku z działalnością gospodarczą
                        Usługodawcy, a podstawą przetwarzania
                        jest prawnie uzasadniony interes
                        Usługodawcy zgodnie z przepisem art. 6
                        ust. 1 lit. f) RODO.<br />⦁ dochodzenia
                        lub ustalenia roszczeń albo obrony
                        przed nimi, a podstawą przetwarzania
                        jest prawnie uzasadniony interes
                        Usługodawcy zgodnie z przepisem art.
                        6 ust. 1 lit. f) RODO, w związku z
                        ochroną jego praw.<br />⦁
                        analitycznych i statystycznych w
                        postaci analizy aktywności
                        Usługobiorców, ich preferencji,
                        a podstawą przetwarzania jest
                        prawnie uzasadniony interes
                        Usługodawcy zgodnie z przepisem
                        art. 6 ust. 1 lit. f) RODO.<br />⦁
                        Usługodawca przetwarza dane
                        osobowe Usługobiorców
                        składających Zamówienie, w
                        następujących&nbsp;celach:<br />⦁
                        realizacji umowy o
                        świadczeniu usług drogą
                        elektroniczną, a
                        podstawą przetwarzania
                        jest niezbędność do
                        realizacji umowy zgodnie
                        z przepisem art. 6 ust.
                        1 lit b) RODO,<br />⦁
                        realizacji
                        Zamówienia złożonego
                        przez Usługobiorcę,
                        a podstawą
                        przetwarzania jest
                        niezbędność do
                        realizacji umowy
                        zgodnie z przepisem
                        art. 6 ust. 1 lit b)
                        RODO,<br />⦁ obsługi
                        zapytania/uwag,
                        a podstawą
                        przetwarzania
                        jest niezbędność
                        do realizacji
                        umowy zgodnie z
                        przepisem art. 6
                        ust. 1 lit b)
                        RODO,<br />⦁
                        realizacji
                        obowiązków
                        wynikających
                        w
                        szczególności
                        z przepisów
                        podatkowych,
                        a podstawą
                        przetwarzania
                        jest
                        obowiązek
                        prawny,
                        zgodnie z
                        przepisem
                        art. 6 ust.
                        1 lit. c)
                        RODO;<br />⦁
                        obsługi
                        reklamacji,
                        a
                        podstawą
                        przetwarzania
                        jest
                        obowiązek
                        prawny,
                        zgodnie
                        z
                        przepisem
                        art. 6
                        ust. 1
                        lit. c)
                        RODO;<br />⦁
                        prowadzenia
                        korespondencji
                        e-mail
                        lub
                        telefonicznej
                        między
                        Usługodawcą
                        i
                        Usługobiorcą
                        w
                        związku
                        z
                        działalnością
                        gospodarczą
                        Usługodawcy,
                        a
                        podstawą
                        przetwarzania
                        jest
                        prawnie
                        uzasadniony
                        interes
                        Usługodawcy
                        zgodnie
                        z
                        przepisem
                        art.
                        6
                        ust.
                        1
                        lit.
                        f)
                        RODO.<br />⦁
                        analitycznych
                        i
                        statystycznych
                        w
                        postaci
                        analizy
                        aktywności
                        Usługobiorców,
                        ich
                        preferencji,
                        a
                        podstawą
                        przetwarzania
                        jest
                        prawnie
                        uzasadniony
                        interes
                        Usługodawcy
                        zgodnie
                        z
                        przepisem
                        art.
                        6
                        ust.
                        1
                        lit.
                        f)
                        RODO;<br />⦁
                        dochodzenia
                        lub
                        ustalenia
                        roszczeń
                        albo
                        obrony
                        przed
                        nimi,
                        a
                        podstawą
                        przetwarzania
                        jest
                        prawnie
                        uzasadniony
                        interes
                        Usługodawcy
                        zgodnie
                        z
                        przepisem
                        art.
                        6
                        ust.
                        1
                        lit.
                        f)
                        RODO,
                        w
                        związku
                        z
                        ochroną
                        jego
                        praw.<br />⦁
                        Ponadto,
                        Usługodawca
                        przetwarza
                        dane
                        osobowe
                        Usługobiorcy
                        w
                        celach
                        marketingowych,
                        a
                        podstawą
                        przetwarzania
                        jest
                        prawnie
                        uzasadniony
                        interes
                        Usługodawcy
                        zgodnie
                        z
                        przepisem
                        art.
                        6
                        ust.
                        1
                        lit.
                        f)
                        RODO.
                        Przetwarzanie
                        takie
                        polega
                        na:<br />⦁
                        tworzeniu
                        profilu
                        Usługobiorcy
                        i
                        przedstawianiu
                        informacji
                        o
                        produktach,
                        nowościach
                        i
                        promocjach,
                        które
                        mogą
                        go
                        zainteresować
                        (dotyczy
                        wyłącznie
                        Usługobiorców
                        posiadających
                        Konto)<br />⦁
                        prowadzeniu
                        marketingu
                        bezpośredniego
                        w
                        zakresie
                        usług
                        oferowanych
                        przez
                        Usługodawcę. <br />⦁
                        Usługodawca
                        przetwarza
                        dane
                        osobowe
                        Usługobiorcy
                        odwiedzającego
                        profile
                        Usługodawcy
                        prowadzone
                        w
                        mediach
                        społecznościowych,
                        w
                        szczególności
                        na
                        portalu
                        Facebook.
                        Dane
                        te
                        są
                        przetwarzane
                        wyłącznie
                        w
                        związku
                        z
                        prowadzeniem
                        profilu,
                        w
                        celu
                        informowania
                        Usługobiorcy
                        o
                        aktywności
                        Usługodawcy,
                        promowania
                        usług,
                        badania
                        satysfakcji
                        Usługobiorców
                        w
                        związku
                        z
                        jakością
                        usług
                        oferowanych
                        przez
                        Usługodawcę,
                        jak
                        również
                        komunikowania
                        się
                        z
                        Usługobiorcą.
                        Podstawą
                        prawną
                        przetwarzania
                        danych
                        przez
                        Usługodawcę
                        jest
                        uzasadniony
                        interes,
                        zgodnie
                        z
                        przepisem
                        art.
                        6
                        ust.
                        1
                        lit.
                        f)
                        RODO
                        polegający
                        na
                        promowaniu
                        własnej
                        marki.<br />⦁
                        Usługodawca
                        przetwarza
                        dane
                        osobowe
                        Usługobiorcy
                        w
                        ramach
                        monitoringu
                        wizyjnego
                        stosowanego
                        w
                        Lokalach,
                        w
                        celu
                        zapewnienia
                        bezpieczeństwa
                        i
                        porządku
                        oraz
                        ustalenia,
                        dochodzenia
                        i
                        obrony
                        przed
                        roszczeniami.
                        Podstawą
                        prawną
                        przetwarzania
                        danych
                        przez
                        Usługodawcę
                        jest
                        uzasadniony
                        interes,
                        zgodnie
                        z
                        przepisem
                        art.
                        6
                        ust.
                        1
                        lit.
                        f)
                        RODO
                        polegający
                        na
                        zapewnieniu
                        bezpieczeństwa
                        wszelkim
                        osobom
                        przebywającym
                        na
                        terenie
                        Lokalu
                        oraz
                        ochronie
                        praw
                        Usługodawcy.
                        Nagrania
                        z
                        monitoringu
                        są
                        przechowywane
                        przez
                        okres
                        2
                        tygodni,
                        po
                        jego
                        upływie
                        ulegają
                        nadpisaniu.<br />⦁
                        Usługobiorca,
                        dokonując
                        Rejestracji,
                        podaje
                        dane
                        osobowe,
                        a
                        następnie
                        oświadcza,
                        iż
                        zapoznał
                        się
                        z
                        Regulaminem
                        i
                        akceptuje
                        jego
                        treść. <br />⦁
                        Usługobiorca,
                        składając
                        Zamówienie
                        podaje
                        dane
                        osobowe,
                        w
                        szczególności
                        dane
                        adresowe
                        w
                        celu
                        zawarcia
                        umowy. <br />⦁
                        Usługobiorca
                        składający
                        Zamówienie
                        oświadcza,
                        iż
                        zapoznał
                        się
                        z
                        Regulaminem
                        i
                        akceptuje
                        jego
                        treść.<br />⦁
                        Usługodawca
                        przetwarza
                        dane
                        osobowe
                        Usługobiorcy,
                        za
                        jego
                        uprzednią
                        zgodą,
                        w
                        celu
                        przeprowadzenia
                        rekrutacji
                        na
                        określone
                        stanowisko
                        pracy.
                        W
                        tym
                        celu,
                        Usługobiorca
                        zaznacza
                        odpowiedni
                        odnośnik.<br />⦁
                        Brak
                        wyrażenia
                        zgody
                        w
                        powyższym
                        przypadku
                        nie
                        ma
                        wpływu
                        na
                        korzystanie
                        z
                        pozostałych
                        usług
                        gwarantowanych
                        przez
                        Usługodawcę.<br />⦁
                        Dane
                        są
                        przetwarzane
                        w
                        związku
                        z
                        realizacją
                        umowy,
                        na
                        podstawie
                        zgody,
                        w
                        wyniku
                        realizacji
                        obowiązku
                        prawnego
                        oraz
                        w
                        oparciu
                        o
                        prawnie
                        uzasadniony
                        interes
                        administratora,
                        o
                        czym
                        mowa
                        w
                        przepisie
                        art.
                        6
                        ust.
                        1
                        lit.
                        a),
                        b)
                        c),
                        f)
                        RODO.<br />⦁
                        Zgoda
                        na
                        przetwarzanie
                        danych
                        osobowych
                        przez
                        Usługodawcę
                        w
                        ramach
                        przeprowadzanej
                        rekrutacji
                        jest
                        dobrowolna,
                        zgodnie
                        z
                        art.
                        6
                        ust.
                        1
                        lit.
                        a)
                        RODO.
                        Brak
                        wyrażenia
                        zgody
                        uniemożliwi
                        Usługobiorcy
                        uczestnictwo
                        w
                        rekrutacji.<br />⦁
                        Podanie
                        danych
                        przez
                        Usługobiorcę
                        w
                        ramach
                        wykonywania
                        umowy
                        o
                        świadczenie
                        usług
                        drogą
                        elektroniczną,
                        realizacji
                        Zamówienia
                        czy
                        wysyłania
                        zapytania
                        jest
                        wymogiem
                        umownym.
                        Usługobiorca
                        jest
                        uprawniony
                        do
                        ich
                        podania,
                        a
                        ewentualne
                        niepodanie
                        danych
                        uniemożliwi
                        realizowanie
                        przedmiotowej
                        umowy.<br />⦁
                        Niepodanie
                        danych
                        osobowych
                        przez
                        Usługobiorcę,
                        które
                        Usługodawca
                        przetwarza
                        w
                        oparciu
                        o
                        przepis
                        art.
                        6
                        ust.
                        1
                        lit
                        c)
                        uniemożliwi
                        wykonanie
                        obowiązku
                        prawnego
                        ciążącego
                        na
                        Usługodawcy.<br />⦁
                        Niepodanie
                        danych
                        osobowych
                        przez
                        Usługobiorcę,
                        które
                        Usługodawca
                        przetwarza
                        w
                        oparciu
                        o
                        prawnie
                        uzasadniony
                        interes,
                        uniemożliwi
                        realizowanie
                        przez
                        Usługodawcę
                        tychże
                        prawnie
                        uzasadnionych
                        interesów.<br />⦁
                        Określenie
                        adresu
                        IP
                        Usługobiorcy
                        umożliwia
                        Usługodawcy
                        potwierdzenie
                        wyrażenia
                        zgody
                        przez
                        Usługobiorcę
                        na
                        przetwarzanie
                        danych
                        osobowych
                        lub
                        potwierdzenie
                        akceptacji
                        Regulaminu. <br />⦁
                        Dane
                        osobowe
                        uzyskane
                        przez
                        Usługodawcę,
                        w
                        celu
                        wykonania
                        umowy
                        służą
                        w
                        szczególności
                        do
                        realizacji
                        Zamówienia.
                    </p>
                    <p>&nbsp;</p>
                    <p>§3 Odbiorcy danych. Umowa powierzenia</p>
                    <p>⦁ W celu wykonania umowy zawartej między Usługodawcą i Usługobiorcą, Usługodawca w niezbędnym
                        zakresie przekazuje dane osobowe Usługobiorcy zewnętrznym podmiotom, w szczególności dostawcy usług
                        informatycznych, usług płatniczych, bankom, a także podmiotom świadczącym usługi księgowe.<br />⦁
                        Usługodawca zastrzega sobie prawo ujawnienia danych osobowych Usługobiorcy właściwym organom
                        bądź osobom trzecim, które zgłoszą żądanie w tym przedmiocie, zgodnie z przepisami prawa
                        powszechnie obowiązującego.<br />⦁ Przekazanie danych osobowych następuje na podstawie umowy
                        powierzenia danych osobowych, o której mowa w przepisie art. 28 ust. 3 RODO.<br />⦁ Dane
                        Usługobiorcy zostają wysłane w trakcie składania zamówienia do PayU S.A. lub PayPro
                        S.A., który przejmuje funkcje usługi płatności online i depozytariusza oraz ochrony
                        kupującego.<br />⦁ W ramach systemu PayU lub PayPro S.A. zostają przekazane następujące
                        dane osobowe: imię, nazwisko, adres zamieszkania, numer telefonu, adres IP, adres
                        e-mail lub inne dane wymagane do realizacji zamówienia. <br />⦁ Przekazanie powyższych
                        danych osobowych jest konieczne do realizacji zamówienia przy wybranej metodzie
                        płatności.<br />⦁ PayU lub PayPro S.A. może przekazywać dane osobowe swoim
                        podwykonawcom lub innym powiązanym podmiotom w zakresie niezbędnym do
                        realizacji zamówienia.<br />⦁ Usługodawca prowadzi rejestr czynności
                        przetwarzania danych osobowych. Rejestr ten zawiera:<br />⦁ imię i
                        nazwisko, a także dane kontaktowe Usługodawcy,<br />⦁ cele
                        przetwarzania danych osobowych,<br />⦁ opis kategorii osób,
                        których dane dotyczą, oraz kategorii danych osobowych,<br />⦁
                        podmiotów, którym przekazano dane osobowe
                        Usługobiorcy,<br />⦁ informacje o przekazywaniu danych
                        osobowych do państwa trzeciego,<br />⦁ planowane
                        terminy usunięcia poszczególnych kategorii
                        danych,<br />⦁ ogólny opis technicznych i
                        organizacyjnych środków bezpieczeństwa.</p>
                    <p>⦁ Podmioty przetwarzające dane osobowe w imieniu Usługodawcy prowadzą rejestr kategorii czynności,
                        określony w przepisie art. 30 ust. 2 RODO.</p>
                    <p>&nbsp;</p>
                    <p>§4 Uprawnienia Usługobiorcy</p>
                    <p>⦁ Usługobiorca ma prawo do stałego dostępu do swoich danych osobowych. Usługobiorca ma prawo do
                        sprostowania umieszczonych danych osobowych na podstawie przepisu art. 16 RODO.<br />⦁ Usługobiorca ma
                        prawo żądać usunięcia swoich danych osobowych na podstawie przepisu art. 17 RODO.<br />⦁ Przepis
                        §4 ust. 2 nie ma zastosowania w zakresie, w jakim przetwarzanie jest niezbędne na podstawie
                        przepisu art. 17 ust 3 lit. e) RODO<br />⦁ Usługobiorca ma prawo uzyskać potwierdzenie
                        przetwarzania jego danych osobowych, informacje o celach przetwarzania, kategorii danych
                        osobowych, a także informacji o źródłach pozyskania danych, jeżeli nie zostały one
                        uzyskane od Usługobiorcy.<br />⦁ Usługobiorca ma prawo żądać ograniczenia przetwarzania
                        jego danych osobowych przez Usługodawcę, zgodnie z przepisem art. 18 RODO.<br />⦁
                        Usługobiorcy służy prawo do wniesienia sprzeciwu wobec przetwarzania jego danych
                        osobowych, zgodnie z przepisem art. 21 RODO.<br />⦁ W dowolnym momencie
                        Usługobiorca może cofnąć udzieloną zgodę na przetwarzanie danych osobowych.
                        Cofnięcie zgody nie ma wpływu na skuteczność czynności zawartych uprzednio
                        oraz istniejące zobowiązania między Usługobiorcą a Usługodawcą.<br />⦁
                        Usługobiorca ma prawo do złożenia skargi do Prezesa Urzędu Ochrony
                        Danych Osobowych, jeżeli uzna, iż doszło do naruszenia przepisów o
                        ochronie danych osobowych. <br />⦁ Bez uszczerbku dla prawa wskazanego w
                        ust. 8, Usługobiorca, na podstawie przepisu art. 79 ust. 1 RODO ma
                        prawo do wniesienia dochodzenia swoich uprawnień drogą sądową.<br />⦁
                        Usługobiorca ma prawo otrzymać od Usługodawcy, w pliku PDF, dane
                        osobowe, dostarczone uprzednio Usługodawcy.<br />⦁ Na żądanie
                        Usługobiorcy, Usługodawca jest zobowiązany przekazać dane
                        osobowe, w formie określonej w ust. 10, innemu
                        administratorowi zgodnie z przepisami prawa powszechnie
                        obowiązującego.<br />⦁ Uprawnienia, o których mowa §4, z
                        wyjątkiem ust. 8 – 9 Usługobiorca realizuje za pomocą
                        poczty elektronicznej, poprzez wskazanie swojego żądania
                        w wiadomości e-mail. Usługodawca jest zobowiązany
                        niezwłocznie zastosować się do żądania.</p>
                    <p><br />§5 Bezpieczeństwo danych</p>
                    <p>⦁ Dane osobowe Usługobiorców, z zastrzeżeniem §2 ust. 14 oraz §5 ust. 2, będą przechowywane przez
                        Usługodawcę przez okres nie dłuższy, niż jest to niezbędne do celów, w których te dane są
                        przetwarzane, w myśl przepisu art. 5 ust. 1 lit. e) Rozporządzenia Parlamentu Europejskiego i Rady
                        (UE) 2016/679 z dnia 27 kwietnia 2016 roku w sprawie ochrony osób fizycznych w związku z
                        przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia
                        dyrektywy 95/46/WE, co do zasady przez okres przedawnienia roszczeń cywilnoprawnych – 6 lat.<br />⦁
                        Dane osobowe Usługobiorców składających CV w ślad za opublikowaną na stronie internetowej ofertą
                        pracy będą przetwarzane co do zasady przez okres nie dłuższy niż czas trwania rekrutacji.<br />⦁
                        Usługodawca chroni przekazane mu dane osobowe. Odbywa się to zwłaszcza poprzez szyfrowanie,
                        używanie bezpiecznego protokołu, pseudonimizację, zapewnianie poufności, integralności,
                        dostępności i odporności systemów i usług przetwarzania, zdolność do szybkiego przywrócenia
                        dostępności danych osobowych i dostępu do nich oraz regularne ocenianie skuteczności środków
                        technicznych zapewniających bezpieczeństwo.<br />⦁ Usługodawca dba, by dane osobowe
                        przetwarzane były w sposób bezpieczny, dostęp do nich miały jedynie osoby upoważnione, w
                        zakresie niezbędnym do wykonywanych zadań.<br />⦁ Usługodawca informuje organ nadzorczy o
                        przypadkach naruszenia bezpieczeństwa dotyczących danych osobowych, zgodnie z
                        przepisem art. 33 RODO. Usługodawca dokumentuje naruszenia ochrony danych osobowych.<br />⦁
                        Usługodawca zawiadamia osobę, której dane dotyczą, o takim naruszeniu, zgodnie z
                        przepisem art. 34 RODO.<br />⦁ Usługodawca nie udostępnia danych osobowych
                        Usługobiorcy osobom trzecim, z zastrzeżeniem §3 ust. 1, ust. 2.</p>
                    <p><br />§6 Polityka cookies</p>
                    <p>⦁ Serwis internetowy korzysta z plików cookies – rozumie się przez to dane informatyczne,
                        przechowywane na urządzeniu końcowym Usługobiorcy, w szczególności pliki z nazwą strony
                        internetowej, czasem przechowywania na urządzeniu końcowym oraz unikalny numer.<br />⦁ Pliki cookies
                        służą do korzystania z serwisu internetowego.<br />⦁ Usługodawca poprzez pliki cookies otrzymuje
                        informacje dotyczące przeglądarki, oprogramowania oraz urządzenia końcowego.<br />⦁
                        Usługodawca zamieszcza na urządzeniu końcowym pliki cookies oraz uzyskuje do nich
                        dostęp. <br />⦁ W ramach serwisu stosowane są:<br />⦁ cookies „sesyjne”: są to tymczasowe
                        pliki, przechowywane na urządzeniu końcowym Usługobiorcy i pozostają tam do chwili
                        zakończenia sesji. Informacje są trwale usuwane z pamięci urządzenia. Mechanizm nie
                        pozwala na pobieranie danych osobowych,<br />⦁ cookies „trwałe”: są to pliki
                        przechowywane na urządzeniu końcowym Usługobiorcy i pozostają do tam do momentu
                        ich usunięcia. Termin usunięcia ustala się w ustawieniach przeglądarki,<br />⦁
                        Cookies „bezpośrednie”: służą do zapamiętania informacji o Usługobiorcy i
                        jego ustawieniach,<br />⦁ Cookies „zewnętrzne”: to informacje pochodzące np. z
                        serwerów podmiotów zewnętrznych. Pliki cookies wykorzystywane są przez
                        Usługodawcę, aby strona wyświetlała się w sposób prawidłowy.<br />⦁
                        Usługodawca wykorzystuje pliki cookies w celu:<br />⦁ automatycznego
                        logowania usługobiorcy do serwisu,<br />⦁ zapamiętania produktów
                        dodanych do Koszyka,<br />⦁ automatycznej identyfikacji
                        Usługobiorcy,<br />⦁ Usługodawca stosuje pliki cookies w
                        celu utrzymania sesji w trakcie korzystania ze
                        strony przez Usługobiorcę, zbierając informację czy
                        Usługobiorca jest zalogowany.<br />⦁ Pliki cookies
                        wykorzystywane są przez Usługodawcę również w
                        celu dostosowania strony internetowej do
                        preferencji danego Usługobiorcy, aby strona
                        wyświetlała się w sposób prawidłowy.<br />⦁ Pliki
                        cookies portalu społecznościowego „Facebook”
                        zbierają dane osobowe w celu tworzenia
                        anonimowych statystyk.<br />⦁ Pliki cookies za
                        pośrednictwem narzędzia „Google
                        Analitycs”, usługi analizy internetowej
                        Google Inc., 1600 Amphitheatre Parkway,
                        Mountain View, CA 94043, USA, (Google)
                        zbierają informacje w celu tworzenia
                        anonimowych statystyk, ułatwienia oceny
                        korzystania ze strony internetowej. <br />⦁
                        W ramach „Google Analitycs” adres IP
                        zostaje zanonimizowany. Google używa
                        informacji w celu oceny korzystania
                        ze strony internetowej, sporządzania
                        stosownych raportów oraz świadczenia
                        innych usług związanych z
                        korzystaniem ze strony internetowej.<br />⦁
                        Google może przekazać informacje
                        osobom trzecim, jeśli wymagają
                        tego przepisy prawa lub jeśli
                        osoby trzecie przetwarzają te
                        informacje w imieniu Google.<br />⦁
                        W zakresie informacji
                        gromadzonych przez sieć
                        reklamową Google,
                        Usługobiorca ma możliwość
                        przeglądania i edytowania
                        tych informacji przy pomocy
                        narzędzia udostępnionego
                        przez Google.<br />⦁
                        Usługobiorca ma
                        możliwość zmiany
                        ustawień plików
                        „cookies” w ustawieniach
                        przeglądarki
                        internetowej, z której
                        aktualnie korzysta. <br />⦁
                        Usługobiorca może
                        zablokować
                        automatyczną obsługę
                        plików „cookies”.
                        Usługodawca
                        zastrzega
                        jednocześnie, iż
                        zmiany ustawień w
                        przeglądarce
                        internetowej mogą
                        utrudnić lub
                        uniemożliwić
                        prawidłowe
                        korzystanie z
                        serwisu
                        internetowego.</p>
                </section>
                <Footer />
            </>
        )
    }
}

export default Privacy;