import React, {Component} from "react";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";
import { Helmet } from "react-helmet"; // Import react-helmet

class Franchise extends Component {
    componentDidMount() {
        let nav = document.querySelectorAll('.navbar__link');
        for (let i = 0; i < nav.length; i++) {
            nav[i].classList.remove('is-active');
        }
        document.querySelector('.navbar__left-box .navbar__link:nth-child(5)').classList.add('is-active');
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>Franczyza - Pizza Bunga</title>
                    <meta name="description" content="Zostań franczyzobiorcą naszej pizzerii! Oferujemy sprawdzony model biznesowy, wsparcie na każdym etapie oraz rozpoznawalną markę. Dołącz do nas i rozwijaj się z sukcesem!" />
                    <meta property="og:title" content="Franczyza - Pizza Bunga" />
                    <meta property="og:description" content="Zostań franczyzobiorcą naszej pizzerii! Oferujemy sprawdzony model biznesowy, wsparcie na każdym etapie oraz rozpoznawalną markę. Dołącz do nas i rozwijaj się z sukcesem!" />
                    <meta property="og:image" content="https://pizzabunga.pl/static/media/logo.cf443991.svg" />
                    <meta property="og:url" content="https://www.pizzabunga.pl/" />
                </Helmet>
                <Navbar sum={this.props.sum} counter={this.props.counter} summary={this.props.summary} />
                <section className="franchise">
                    <div className="franchise__items-wrapper">
                        <h1 className="text__medium--2 text__w500">Wszystkie pytania prosimy kierować na adres <a href="mailto:mj@kraft-pol.pl">mj@kraft-pol.pl</a></h1>
                    </div>
                </section>
                <Footer />
            </>
        )
    }
}

export default Franchise;