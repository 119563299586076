import React, {Component} from "react";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";
import vegetables from '../../src/icons/vegetables.png';
import { Helmet } from "react-helmet"; // Import react-helmet

class About extends Component{
    componentDidMount() {
        let nav = document.querySelectorAll('.navbar__link');
        for (let i = 0; i < nav.length; i++) {
            nav[i].classList.remove('is-active');
        }
        document.querySelector('.navbar__left-box .navbar__link:nth-child(3)').classList.add('is-active');
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>O nas - Pizza Bunga</title>
                    <meta name="description" content="Tworzymy pyszną pizzę z pasją, używając tylko świeżych składników najwyższej jakości. Nasza misja to dostarczanie niezapomnianych smaków, które pokochasz od pierwszego kęsa!" />
                    <meta property="og:title" content="O nas - Pizza Bunga" />
                    <meta property="og:description" content="Tworzymy pyszną pizzę z pasją, używając tylko świeżych składników najwyższej jakości. Nasza misja to dostarczanie niezapomnianych smaków, które pokochasz od pierwszego kęsa!" />
                    <meta property="og:image" content="https://pizzabunga.pl/static/media/logo.cf443991.svg" />
                    <meta property="og:url" content="https://www.pizzabunga.pl/" />
                </Helmet>
                <Navbar sum={this.props.sum} counter={this.props.counter} summary={this.props.summary} />
                <section className="about-section">
                    <div className="about-section__header">
                        <h2 className="about-section__main-text text__large text__w600">Świeże składniki, puszyste ciasto i
                            wysokiej jakości ser,</h2>
                        <h1 className="about-section__sub-text text__large--2 text__w700">Musisz tego spróbować!</h1>
                    </div>
                    <div className="about-section__content">
                        <article className="about-section__article about-section__article--1">
                            <h3 className="about-section__article-header about-section__article-header--1 text__large text__w600 ">Dlaczego
                                będzie smacznie?</h3>
                            <span className="about-section__text text__medium text__w300">Świeże składniki, grube ciasto i prawdziwy ser - to nasz przepis na pyszną pizzę. Z nami Twój dzień stanie się lepszy! Dlaczego będzie smacznie? Dziesięcioletnie doświadczenie nauczyło nas jak doskonale zadowalać podniebienia i żołądki. Ciągle doskonalona receptura oferuje chrupkie z zewnątrz i puszyste w środku ciasto, które rozpływa się w ustach. Nasza miła i wykwalifikowana obsługa sprosta nawet najtrudniejszym oczekiwaniom, a kurierzy szybko dostarczą ulubione dania pod same drzwi. Najważniejsze w naszej pracy jest to, że wszystko co robimy - robimy z pasją. Każdy zadowolony klient jest dla nas powodem do dumy. "Dostarczamy najlepszy posiłek dnia" – to nasze motto, dzięki któremu udaje się nam utrzymać najwyższą jakość i powtarzalność dań.</span>
                            <div className="about-section__vegetables-article-bottom">&nbsp;</div>
                            <div className="about-section__basil-article-bottom">&nbsp;</div>
                        </article>
                        <article className="about-section__article about-section__article--2">
                            <img className="about-section__vegetables"
                                 src={vegetables}
                                 alt="vegetables" />
                            <div className="about-section__article-right">
                                <h3 className="about-section__article-header mrg-btm-medium text__large text__w600 ">Lokalni dostawcy</h3>
                                <span className="about-section__text text__medium text__w300">Współpracujemy z lokalnymi dostawcami, którzy codziennie rano wybierają dla nas tylko świeże warzywa. A do bardziej egzotycznych kompozycji potrafimy ściągnąć składniki z każdego zakątka świata. Nie wierzysz? W okresie zimowym zjesz u nas apetyczny Karpacz, na którym znajdziesz ser wędzony przez samych górali. Regularnie możesz też znaleźć w naszym menu pizzę o nazwie Kalabria, a Nduja która się na niej znajduje pozwoli Ci na podróż po oblanych słońcem Włoszech. Wolisz coś bardziej hardcorowego? Dla fanów pikantności sprowadzamy z Holandii Carolina Reaper - najostrzejszą paprykę na świecie!</span>
                            </div>
                            <div className="about-section__tomatoes-article-bottom">&nbsp;</div>
                        </article>
                    </div>
                </section>
                <div className="background-about">
                    <div className="background-about__container">
                        <div className="background-about__tomatoes">&nbsp;</div>
                        <div className="background-about__black-pepper">&nbsp;</div>
                        <div className="background-about__pizza">&nbsp;</div>
                        <div className="background-about__pizza-mobile">&nbsp;</div>
                        <div className="background-about__basil">&nbsp;</div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}

export default About;