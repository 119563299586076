import React, {Component} from "react";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";
import {Connect} from "../components/connect/Connect";
import * as endpoint from "../endpoints/Endpoints";
import { Helmet } from "react-helmet"; // Import react-helmet

class Premises extends Component{
    state = {
        premises: []
    }

    componentDidMount() {
        let nav = document.querySelectorAll('.navbar__link');
        for (let i = 0; i < nav.length; i++) {
            nav[i].classList.remove('is-active');
        }
        document.querySelector('.navbar__left-box .navbar__link:nth-child(2)').classList.add('is-active');
        Connect(
            `${endpoint.backend}${endpoint.getPremises}`,
            "GET",
            null
        ).then((response) => {
            this.setState({
                premises: response,
            });
        });
    }

    changeMap = (e, value) => {
        e.preventDefault();
        let maps = document.querySelectorAll('.localizations-section__map');
        for (let i = 0; i < maps.length; i++) {
            maps[i].classList.remove('d-none');
            maps[i].classList.add('d-none');
        }
        document.querySelector(`#jsMap${value}`).classList.remove('d-none');
        this.scrollIt(document.querySelector(`#jsMap${value}`));
    }

    scrollIt = (element) => {
        element.scrollIntoView();
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>Lokale - Pizza Bunga</title>
                    <meta name="description" content="Gorąca pizza z puszystym ciastem, szybka i darmowa dostawa we Wrocławiu! Zamów pyszne pizze z dowozem do domu i ciesz się wyjątkowym smakiem już dziś!" />
                    <meta property="og:title" content="Kontakt - Pizza Bunga" />
                    <meta property="og:description" content="Gorąca pizza z puszystym ciastem, szybka i darmowa dostawa we Wrocławiu! Zamów pyszne pizze z dowozem do domu i ciesz się wyjątkowym smakiem już dziś!" />
                    <meta property="og:image" content="https://pizzabunga.pl/static/media/logo.cf443991.svg" />
                    <meta property="og:url" content="https://www.pizzabunga.pl/kontakt/" />
                </Helmet>
                <Navbar sum={this.props.sum} counter={this.props.counter} summary={this.props.summary} />
                <section className="localizations-section">
                    <div className="localizations-section__circle-box">
                        {this.state.premises.map((data, index) => (
                            data.menuSite !== true &&
                                <div className="localizations-section__circle">
                                    <a href="https://www.google.com/maps?q=ul.+In%C5%BCynierska+27a&um=1&ie=UTF-8&sa=X&ved=2ahUKEwjD3JnJ_an2AhUVrYsKHRlQDKAQ_AUoAXoECAEQAw" onClick={(e) => this.changeMap(e, `${index}`)}
                                       target="_blank" className="contact-box__description text__small text__w500">
                                        <span data-location="loc1" className="js-location text__medium--2 text__w500 localizations-section__circle__header">{data.name.replace('-', ' ')}</span>
                                    </a>
                                    <span className="text__medium text__w300 localizations-section__circle__adress">{data.address}</span>
                                    <a href={`tel:${data.phone}`}
                                       className="text__medium text__w300 localizations-section__circle__phone">tel: {data.phone}</a>
                                </div>
                        ))}
                    </div>
                    <div className="background-localization">
                        <div className="background-localization__black-pepper" title="black-pepper">&nbsp;</div>
                        <div className="background-localization__black-pepper-mobile" title="black-pepper">&nbsp;</div>
                        <div className="background-localization__basil" title="basil">&nbsp;</div>
                    </div>
                    {/*{JSON.parse(sessionStorage.getItem('googleMaps')).map((data, index) => (*/}
                    {/*    <iframe id={"jsMap" + index} className={index === 0 ? "js-map localizations-section__map" : "js-map localizations-section__map d-none"} width="100%" height="450" style={{border: '0'}}*/}
                    {/*            loading="lazy" allowFullScreen=""*/}
                    {/*            src={data.mapLink}></iframe>*/}
                    {/*))}*/}

                    <iframe id="jsMap0" className="js-map localizations-section__map" width="100%" height="450" style={{border: '0'}}
                            loading="lazy" allowFullScreen=""
                            src="https://www.google.com/maps/embed/v1/place?q=place_id:Eh5JbsW8eW5pZXJza2EsIFdyb2PFgmF3LCBQb2xhbmQiLiosChQKEgnfUGhfIMIPRxGZRgScSEmA5RIUChIJv4q11MLpD0cR9eAFwq5WCbc&amp;key=AIzaSyA6wDV5eFhmJbBf3yQ5GOTYHko3wxazUMo"></iframe>
                    <iframe id="jsMap1" className="js-map localizations-section__map d-none" width="100%" height="450" style={{border: '0'}}
                            loading="lazy" allowFullScreen=""
                            src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJoYQ6_nPqD0cR8XcYr6KgWYE&amp;key=AIzaSyA6wDV5eFhmJbBf3yQ5GOTYHko3wxazUMo"></iframe>
                    <iframe id="jsMap2" className="js-map localizations-section__map d-none" width="100%" height="450" style={{border: '0'}}
                            loading="lazy" allowFullScreen=""
                            src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJU4mFd-fpD0cRDhA9LcLokOc&amp;key=AIzaSyA6wDV5eFhmJbBf3yQ5GOTYHko3wxazUMo"></iframe>
                    <iframe id="jsMap3" className="js-map localizations-section__map d-none" width="100%" height="450" style={{border: '0'}}
                            loading="lazy" allowFullScreen=""
                            src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJ4fGxlOnCD0cRC9KRyBOxBio&amp;key=AIzaSyA6wDV5eFhmJbBf3yQ5GOTYHko3wxazUMo"></iframe>
                    <iframe id="jsMap4" className="js-map localizations-section__map d-none" width="100%" height="450" style={{border: '0'}}
                            loading="lazy" allowFullScreen=""
                            src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJ9S4QUo_oD0cRtFb-4Af4UkU&amp;key=AIzaSyA6wDV5eFhmJbBf3yQ5GOTYHko3wxazUMo"></iframe>
                </section>
                <Footer />
            </>
        )
    }
}

export default Premises;