import React, { Component } from "react";
import Navbar from "../components/navbar/Navbar";
import { Connect } from "../components/connect/Connect";
import * as endpoint from "../endpoints/Endpoints";
import Footer from "../components/footer/Footer";

class OwnPizza extends Component {
  state = {
    data: [],
    ingriedients: [],
    dough: [],
    sauce: [],
    saucePizza: [],
    tempChoice: sessionStorage.getItem("tempChoice") ? sessionStorage.getItem("tempChoice") : []
  };
  componentDidMount() {
    const { id } = this.props.match.params;
    let nav = document.querySelectorAll(".navbar__link");
    for (let i = 0; i < nav.length; i++) {
      nav[i].classList.remove("is-active");
    }
    sessionStorage.removeItem("customPizza");
    if (parseInt(sessionStorage.getItem("sizeHistory")) === 3) {
      document.querySelector(".menu-item__size:nth-child(5)").classList.add("menu-item__size--active");
    } else if (parseInt(sessionStorage.getItem("sizeHistory")) === 2) {
      document.querySelector(".menu-item__size:nth-child(3)").classList.add("menu-item__size--active");
    } else {
      document.querySelector(".menu-item__size:first-child").classList.add("menu-item__size--active");
    }
    Connect(
      `${endpoint.backend}${endpoint.getAllPizzas}/${id}`,
      "GET",
      null
    ).then((response) => {
      let tempSaucePizza;
      let tempPizza = response;
      response.ingriedients.map((item) => {
        if (item.type === "SAUCE") {
          tempSaucePizza = item.name;
        }
      });
      this.setState({
        data: response,
        saucePizza: tempSaucePizza,
      });
      Connect(
        `${endpoint.backend}${endpoint.getAllIngriedients}`,
        "GET",
        null
      ).then((response) => {
        let tempDough = [];
        let tempSauce = [];
        let tempIngriedientsID = [];
        let tempIngriedients = [];
        tempPizza.ingriedients.map((pizza) => {
          tempIngriedientsID.push(pizza.id);
        })
        response.map((item) => {
          if (item.type === "DOUGH") {
            tempDough.push(item);
          }
          if (item.type === "SAUCE" && item.id !== 601025 && item.id !== 501601 && item.id !== 304053) {
            tempSauce.push(item);
          }
          let add = true;
          tempIngriedientsID.map((id) => {
            if (id === item.id) {
              add = false;
            }
          })
          if (add === true) {
            tempIngriedients.push(item);
          }
        });
        this.setState({
          ingriedients: tempIngriedients,
          dough: tempDough,
          sauce: tempSauce,
        });
        document.querySelector(".wppizza-categories .cat-item:first-child a").click();
      });
      let self = this;
      setTimeout(function () {
        self.summaryPizza();
      }, 10);
    });
  }
  changeCategory = (element) => {
    let category = element.getAttribute("href");
    let items = document.querySelectorAll(`.addedIngredients`);
    for (let i = 0; i < items.length; i++) {
      items[i].classList.remove("active");
      if (items[i].getAttribute("data-category") === category) {
        items[i].classList.add("active");
      }
    }
  };
  handleChangeCategory = (e) => {
    e.preventDefault();
    if (e.target.getAttribute("title") !== "active") {
      document
        .querySelector('.wppizza-categories .cat-item a[title="active"]')
        .setAttribute("title", "");
      e.target.setAttribute("title", "active");
      this.changeCategory(e.target);
    }
  };
  summaryPizza = () => {
    let currentPriceNumberDefault = document
      .querySelector(".menu-item__size--active")
      .getAttribute("data-size");
    let currentPriceDefault = 0;
    if (currentPriceNumberDefault === "1") {
      currentPriceDefault = this.state.data.sizes[0].price;
    } else if (currentPriceNumberDefault === "2") {
      currentPriceDefault = this.state.data.sizes[1].price;
    } else {
      currentPriceDefault = this.state.data.sizes[2].price;
    }
    let typeOfCake = 0;
    if (
      document.querySelector(".typeOfCake.species-menu__item--active") !== null
    ) {
      typeOfCake = document
        .querySelector(".typeOfCake.species-menu__item--active")
        .getAttribute("data-price");
    }
    let customizeIngredientsContent = "";
    let currentIngredientsContent = "";
    let customSauceContent = "";
    let ingredientPrice = document.querySelectorAll(".customize-item__price");
    let ingredientsPrice = 0;
    for (let i = 0; i < ingredientPrice.length; i++) {
      let counter = ingredientPrice[i]
        .closest(".customize-item__side-box")
        .querySelector(".counter__item-count");
      if (counter.innerText === "0") {
        ingredientPrice[i]
          .closest(".customize-item__side-box")
          .querySelector(".counter__minus")
          .classList.add("d-hidden");
        ingredientPrice[i]
          .closest(".customize-item__side-box")
          .querySelector(".counter__plus")
          .classList.remove("d-hidden");
      } else if (counter.innerText === "3") {
        ingredientPrice[i]
          .closest(".customize-item__side-box")
          .querySelector(".counter__plus")
          .classList.add("d-hidden");
        ingredientPrice[i]
          .closest(".customize-item__side-box")
          .querySelector(".counter__minus")
          .classList.remove("d-hidden");
      } else {
        ingredientPrice[i]
          .closest(".customize-item__side-box")
          .querySelector(".counter__minus")
          .classList.remove("d-hidden");
        ingredientPrice[i]
          .closest(".customize-item__side-box")
          .querySelector(".counter__plus")
          .classList.remove("d-hidden");
      }
      let val = ingredientPrice[i]
        .closest(".customize-item__side-box")
        .querySelector(".counter__item-count").innerText;
      let currentPrice = ingredientPrice[i]
        .closest(".customize-item")
        .querySelector(".customize-item__name")
        .getAttribute(`data-price-size${currentPriceNumberDefault}`);
      if (ingredientPrice[i].classList.contains("newAdded")) {
        ingredientPrice[i]
          .closest(".customize-item")
          .querySelector(".customize-item__name span").innerText = currentPrice;
      }
      let tempPrice = currentPrice.substring(
        0,
        currentPrice.lastIndexOf(" zł")
      );
      let price = tempPrice.replace(",", ".");
      if (val > 1 && !ingredientPrice[i].classList.contains("newAdded")) {
        let newPrice = (val - 1) * price;
        ingredientPrice[i].innerText = `+${newPrice.toFixed(2)} zł`;
        ingredientPrice[i].setAttribute("data-price", newPrice);
        if (
          ingredientPrice[i]
            .closest(".customize-item")
            .getAttribute("data-category") !== "SAUCE"
        ) {
          currentIngredientsContent += `<div><span data-id="${ingredientPrice[i]
            .closest(".customize-item")
            .getAttribute("data-post")}" data-name="${ingredientPrice[
            i
          ].getAttribute(
            "data-name"
          )}" data-size="${val}" data-price="${price}">${ingredientPrice[
            i
          ].getAttribute("data-name")} x ${val}</span><span>${
            ingredientPrice[i].innerText
          }</span></div>`;
        } else {
          let saucePrice = ingredientPrice[i].innerText;
          let sauceName = ingredientPrice[i].getAttribute("data-name");
          if (saucePrice === "+0.00 zł") {
            saucePrice = "+0 zł";
          }
          customSauceContent = `<div><span data-id="${ingredientPrice[i]
            .closest(".customize-item")
            .getAttribute(
              "data-post"
            )}">${sauceName.replace('sos ', '')}</span><span>${saucePrice}</span></div>`;
        }
      } else if (val > 0 && ingredientPrice[i].classList.contains("newAdded")) {
        let newPrice = val * price;
        ingredientPrice[i].innerText = `+${newPrice.toFixed(2)} zł`;
        ingredientPrice[i].setAttribute("data-price", newPrice);
        if (
          ingredientPrice[i]
            .closest(".customize-item")
            .getAttribute("data-category") !== "SAUCE"
        ) {
          customizeIngredientsContent += `<div><span data-id="${ingredientPrice[
            i
          ]
            .closest(".customize-item")
            .getAttribute("data-post")}" data-name="${ingredientPrice[
            i
          ].getAttribute(
            "data-name"
          )}" data-size="${val}" data-price="${price}">${ingredientPrice[
            i
          ].getAttribute("data-name")} x ${val}</span><span>${
            ingredientPrice[i].innerText
          }</span></div>`;
        } else {
          let saucePrice = ingredientPrice[i].innerText;
          let sauceName = ingredientPrice[i].getAttribute("data-name");
          if (saucePrice === "+0.00 zł") {
            saucePrice = "+0 zł";
          }
          customSauceContent = `<div><span data-id="${ingredientPrice[i]
            .closest(".customize-item")
            .getAttribute(
              "data-post"
            )}">${sauceName.replace('sos ', '')}</span><span>${saucePrice}</span></div>`;
          console.log(ingredientPrice[i]);
        }
      } else if (
        val > 0 &&
        !ingredientPrice[i].classList.contains("newAdded")
      ) {
        ingredientPrice[i].innerText = "+0 zł";
        ingredientPrice[i].setAttribute("data-price", 0);
        if (
          ingredientPrice[i]
            .closest(".customize-item")
            .getAttribute("data-category") !== "SAUCE"
        ) {
          currentIngredientsContent += `<div><span data-id="${ingredientPrice[i]
            .closest(".customize-item")
            .getAttribute("data-post")}" data-name="${ingredientPrice[
            i
          ].getAttribute(
            "data-name"
          )}" data-size="${val}" data-price="${price}">${ingredientPrice[
            i
          ].getAttribute("data-name")} x ${val}</span><span>${
            ingredientPrice[i].innerText
          }</span></div>`;
        } else {
          let saucePrice = ingredientPrice[i].innerText;
          let sauceName = ingredientPrice[i].getAttribute("data-name");
          if (saucePrice === "+0.00 zł") {
            saucePrice = "+0 zł";
          }
          customSauceContent = `<div><span data-id="${ingredientPrice[i]
            .closest(".customize-item")
            .getAttribute(
              "data-post"
            )}">${sauceName.replace('sos ', '')}</span><span>${saucePrice}</span></div>`;
        }
      } else {
        ingredientPrice[i].innerText = "+0 zł";
        ingredientPrice[i].setAttribute("data-price", 0);
      }
      if (val === "0" && !ingredientPrice[i].classList.contains("newAdded")) {
        if (
          ingredientPrice[i]
            .closest(".customize-item")
            .getAttribute("data-category") !== "SAUCE"
        ) {
          currentIngredientsContent += `<div><span data-id="${ingredientPrice[i]
            .closest(".customize-item")
            .getAttribute("data-post")}" data-name="${ingredientPrice[
            i
          ].getAttribute(
            "data-name"
          )}" data-size="${val}" data-price="${price}">${ingredientPrice[
            i
          ].getAttribute("data-name")} x ${val}</span><span>${
            ingredientPrice[i].innerText
          }</span></div>`;
        }
      }
      ingredientsPrice += parseFloat(
        ingredientPrice[i].getAttribute("data-price")
      );
      ingredientPrice[i]
        .closest(".customize-item")
        .setAttribute("data-counter", val);
      ingredientPrice[i]
        .closest(".customize-item")
        .setAttribute("data-price", ingredientPrice[i].innerText);
    }
    document.querySelector(".customizeIngredients").innerHTML =
      customizeIngredientsContent;
    document.querySelector(".customizeContent").innerHTML =
      currentIngredientsContent;
    document.querySelector(".customizeSauce").innerHTML = customSauceContent;
    let price =
      parseFloat(currentPriceDefault) +
      parseInt(typeOfCake) +
      parseFloat(ingredientsPrice);
    document.querySelector(".customizePrice").innerText =
      price.toFixed(2).toString().replace(".", ",") + " zł";
    document
      .querySelector(".customizePrice")
      .setAttribute("data-size", currentPriceNumberDefault);
  };
  changeSize = (e, value) => {
    if (!e.target.classList.contains("menu-item__size--active")) {
      e.target
        .closest(".menu-item__size-menu")
        .querySelector(".menu-item__size--active")
        .classList.remove("menu-item__size--active");
      e.target.classList.add("menu-item__size--active");
      sessionStorage.setItem('sizeHistory', value);
      this.summaryPizza();
    }
  };
  typeOfCake = (e, value) => {
    if (!e.target.classList.contains("species-menu__item--active")) {
      e.target
        .closest(".species-menu")
        .querySelector(".species-menu__item--active")
        .classList.remove("species-menu__item--active");
      e.target.classList.add("species-menu__item--active");
      let dataText = e.target.getAttribute("data-name");
      let price = e.target.getAttribute("data-price");
      let id = e.target.getAttribute("data-id");
      document.querySelector(
        ".customizeCake"
      ).innerHTML = `<div><span data-id="${id}">${dataText}</span><span>+${price} zł</span></div>`;
      // sessionStorage.setItem('cakeHistory', value);
      this.summaryPizza();
    }
  };
  typeOfSauce = (e) => {
    if (!e.target.classList.contains("species-menu__item--active")) {
      if ( e.target.closest(".species-menu").querySelector(".species-menu__item--active") !== null) {
        e.target.closest(".species-menu").querySelector(".species-menu__item--active").classList.remove("species-menu__item--active");
      }
      e.target.classList.add("species-menu__item--active");
      let sauces = document.querySelectorAll(
        '.customize-item[data-category="SAUCE"]'
      );
      for (let i = 0; i < sauces.length; i++) {
        sauces[i].querySelector(".counter__item-count").innerText = "0";
      }
      let name = e.target.getAttribute("data-name");
      if (
        document.querySelector(`.customize-item[data-name="${name}"]`) !== null
      ) {
        document
          .querySelector(`.customize-item[data-name="${name}"]`)
          .querySelector(".counter__item-count").innerHTML = "1";
      }
      this.summaryPizza();
    }
  };
  counterPlus = (e) => {
    let val = e.target
      .closest(".counter")
      .querySelector(".counter__item-count").innerText;
    let newVal = parseInt(val) + 1;
    e.target
      .closest(".counter")
      .querySelector(".counter__item-count").innerText = newVal;
    this.summaryPizza();
  };
  counterMinus = (e) => {
    let val = e.target
      .closest(".counter")
      .querySelector(".counter__item-count").innerText;
    if (parseInt(val) > 0) {
      let newVal = parseInt(val) - 1;
      e.target
        .closest(".counter")
        .querySelector(".counter__item-count").innerText = newVal;
      this.summaryPizza();
    }
  };
  addToCart = () => {
    let localCarts = [];
    if (JSON.parse(sessionStorage.getItem("localCart"))) {
      localCarts = JSON.parse(sessionStorage.getItem("localCart"));
    }
    let currentNumber = document
      .querySelector(".customizePrice")
      .getAttribute("data-size");
    let post = this.state.data && this.state.data.id;
    let name = this.state.data && this.state.data.name;
    let image = this.state.data && this.state.data.image;
    let price = document.querySelector(".customizePrice").innerText;
    let size = document.querySelector(".menu-item__size--active").innerText;
    let sizeId = document
      .querySelector(".menu-item__size--active")
      .getAttribute("id");
    let allProducts = document.querySelectorAll(
      ".customize-pizza__customize-box .customize-item"
    );

    let localInfo = [];
    let cakeID = document
      .querySelector(".customizeCake span:first-child")
      .getAttribute("data-id");
    let cakeName = document.querySelector(
      ".customizeCake span:first-child"
    ).innerText;
    let cakePrice = document.querySelector(
      ".customizeCake span:nth-child(2)"
    ).innerText;
    let localInfoCake = {
      id: cakeID,
      type: "cake",
      name: cakeName,
      price: cakePrice,
      counter: 1,
    };
    localInfo.push(localInfoCake);

    let sauceID = 0;
    let sauceName = "";
    if (document.querySelector(".customizeSauce span:first-child") !== null) {
      sauceID = document
        .querySelector(".customizeSauce span:first-child")
        .getAttribute("data-id");
      sauceName = document.querySelector(
        ".customizeSauce span:first-child"
      ).innerText;
    }
    let saucePrice = "";
    if (document.querySelector(".customizeSauce span:nth-child(2)") !== null) {
      saucePrice = document.querySelector(
        ".customizeSauce span:nth-child(2)"
      ).innerText;
    }
    let localInfoSauce = {
      id: sauceID,
      type: "sauce",
      name: sauceName,
      price: saucePrice,
      counter: 1,
    };
    localInfo.push(localInfoSauce);

    let localInfoBase = document.querySelectorAll(".customizeContent div");
    for (let i = 0; i < localInfoBase.length; i++) {
      let id = localInfoBase[i]
        .querySelector("span:first-child")
        .getAttribute("data-id");
      let name = localInfoBase[i]
        .querySelector("span:first-child")
        .getAttribute("data-name");
      let price = localInfoBase[i].querySelector("span:nth-child(2)").innerText;
      let size = localInfoBase[i]
        .querySelector("span:first-child")
        .getAttribute("data-size");
      let localInfoBaseD = {
        id: id,
        type: "base",
        name: name,
        price: price,
        counter: parseInt(size),
      };
      if (size !== "1") {
        localInfo.push(localInfoBaseD);
      }
    }
    let localInfoExtend = document.querySelectorAll(
      ".customizeIngredients div"
    );
    for (let i = 0; i < localInfoExtend.length; i++) {
      let id = localInfoExtend[i]
        .querySelector("span:first-child")
        .getAttribute("data-id");
      let name = localInfoExtend[i]
        .querySelector("span:first-child")
        .getAttribute("data-name");
      let price =
        localInfoExtend[i].querySelector("span:nth-child(2)").innerText;
      let size = localInfoExtend[i]
        .querySelector("span:first-child")
        .getAttribute("data-size");
      let localInfoExtendD = {
        id: id,
        type: "extend",
        name: name,
        price: price,
        counter: parseInt(size),
      };
      localInfo.push(localInfoExtendD);
    }
    let localProduct = {
      id: post,
      name: name,
      price: price,
      counter: 1,
      size: size,
      info: localInfo,
      pizza: true,
      sizeId: sizeId,
      image: image,
    };
    localCarts.push(localProduct);
    sessionStorage.setItem("localCart", JSON.stringify(localCarts));
    document.querySelector(".navbar__logo").click();
  };
  render() {
    return (
      <>
        <Navbar
          sum={this.props.sum}
          counter={this.props.counter}
          summary={this.props.summary}
        />
        <section className='customize-pizza'>
          <div className='customize-pizza__left-side'>
            <h1 className='customize-pizza__header text__large text__w700'>
              Pizza {this.state.data && this.state.data.name}
            </h1>
            <div
              id='currentIngredients'
              current-ingrediens=''
              className='customize-pizza__customize-box '
            >
              <h3
                id='customizeSize'
                className='customize-pizza__box-header text__medium text__w500'
              >
                1.Aktualne składniki Twojej pizzy
              </h3>
              <form className='menu-item__size-menu' action='#'>
                <label
                  id={
                    this.state.data &&
                    this.state.data.sizes &&
                    this.state.data.sizes[0].id
                  }
                  className='menu-item__size'
                  data-size='1'
                  htmlFor='size1'
                  onClick={(e) => this.changeSize(e, 1)}
                >
                  {this.state.data &&
                    this.state.data.sizes &&
                    this.state.data.sizes[0].name}
                </label>
                <input
                  className='menu-item__size-input'
                  check=''
                  id='size1'
                  type='checkbox'
                />
                <label
                  id={
                    this.state.data &&
                    this.state.data.sizes &&
                    this.state.data.sizes[1].id
                  }
                  className='menu-item__size '
                  data-size='2'
                  htmlFor='size2'
                  onClick={(e) => this.changeSize(e, 2)}
                >
                  {this.state.data &&
                    this.state.data.sizes &&
                    this.state.data.sizes[1].name}
                </label>
                <input
                  className='menu-item__size-input'
                  check=''
                  id='size2'
                  type='checkbox'
                />
                <label
                  id={
                    this.state.data &&
                    this.state.data.sizes &&
                    this.state.data.sizes[2].id
                  }
                  className='menu-item__size '
                  data-size='3'
                  htmlFor='size3'
                  onClick={(e) => this.changeSize(e, 3)}
                >
                  {this.state.data &&
                    this.state.data.sizes &&
                    this.state.data.sizes[2].name}
                </label>
                <input
                  className='menu-item__size-input'
                  check=''
                  id='size3'
                  type='checkbox'
                />
              </form>
              {this.state.data &&
                this.state.data.ingriedients &&
                this.state.data.ingriedients.map((data) =>
                  data.type === "DOUGH" || data.type === "SAUCE" ? (
                    <></>
                  ) : (
                    <div
                      className='customize-item currentIngredients'
                      data-category={data.type}
                      data-post={data.id}
                      data-counter='1'
                      data-name={data.name}
                      data-price='+0 zł'
                    >
                      <span
                        className='customize-item__name text__medium text__w300'
                        data-price-size1={data && data.sizes && data.sizes.length > 0 ? data.sizes[0].price.toFixed(2).replace('.', ',') + ' zł' : '4,00 zł'}
                        data-price-size2={data && data.sizes && data.sizes.length > 1 ? data.sizes[1].price.toFixed(2).replace('.', ',') + ' zł' : '5,50 zł'}
                        data-price-size3={data && data.sizes && data.sizes.length > 2 ? data.sizes[2].price.toFixed(2).replace('.', ',') + ' zł' : '7,00 zł'}
                      >
                        {data.name}
                      </span>
                      <div className='customize-item__side-box'>
                        <span
                          className='customize-item__price'
                          data-name={data.name}
                          data-price='0'
                        >
                          +0 zł
                        </span>
                        <div className='counter currentIngredient'>
                          <div
                            className='counter__minus'
                            onClick={(e) => this.counterMinus(e)}
                          >
                            -
                          </div>
                          <div className='counter__item-count'>1</div>
                          <span
                            className='counter__plus'
                            onClick={(e) => this.counterPlus(e)}
                          >
                            +
                          </span>
                        </div>
                      </div>
                    </div>
                  )
                )}
            </div>
            <div className='customize-pizza__customize-box'>
              <h3
                subheader=''
                className='customize-pizza__box-header text__medium text__w500'
              >
                2.Wybierz rodzaj ciasta:
              </h3>
              <div className='species-menu'>
                {this.state.dough &&
                  this.state.dough.map((data, index) => (
                    <div
                      className={
                        index === 0
                          ? "species-menu__item typeOfCake species-menu__item--active"
                          : "species-menu__item typeOfCake"
                      }
                      data-size='1'
                      data-id={data.id}
                      data-price={data.sizes[0].price}
                      data-name={data.name}
                      onClick={(e) => this.typeOfCake(e, index + 1)}
                    >
                      {data.name}
                    </div>
                  ))}
              </div>
            </div>
            {this.state.data.id !== 601285 ?
              <div className='customize-pizza__customize-box '>
                <h3
                  subheader=''
                  className='customize-pizza__box-header text__medium text__w500'
                >
                  3.Wybierz rodzaj sosu:
                </h3>
                <div className='species-menu '>
                  {this.state.sauce &&
                    this.state.sauce.map((data) => (
                      <div
                        className={
                          data.name === this.state.saucePizza
                            ? "species-menu__item typeOfSauce species-menu__item--active"
                            : "species-menu__item typeOfSauce"
                        }
                        data-post={data.id}
                        data-name={data.name}
                        onClick={(e) => this.typeOfSauce(e)}
                      >
                        {data.name.replace('sos ', '')}
                      </div>
                    ))}
                </div>
              </div>
            :
              ''}
            <div className='customize-pizza__customize-box twoSauce d-none'>
              <h3
                subheader=''
                className='customize-pizza__box-header text__medium text__w500'
              >
                3. Wybierz 2 ulubione sosy:
              </h3>
            </div>
            <div
              id='addCurrentIngredients'
              className='customize-pizza__customize-box '
            >
              <h3 className='customize-pizza__box-header customize-pizza__box-header--1 text__medium text__w500'>
                {this.state.data.id !== 601285 ? '4' : '3'}.Dodaj składniki
              </h3>
              <h3 className='customize-pizza__box-header customize-pizza__box-header--2 text__medium text__w500'>
                4.Dodaj składniki
              </h3>
              <div className='mrg-bottom-40'>
                <div className='species-menu category'>
                  <ul id='wppizza-categories-1' className='wppizza-categories'>
                    <li className='cat-item cat-item-69'>
                      <a
                        href='MEAT'
                        onClick={(e) => this.handleChangeCategory(e)}
                      >
                        mięso
                      </a>
                    </li>
                    <li className='cat-item cat-item-70'>
                      <a
                        href='VEGETABLE'
                        title='active'
                        onClick={(e) => this.handleChangeCategory(e)}
                      >
                        warzywa
                      </a>
                    </li>
                    <li className='cat-item cat-item-68'>
                      <a
                        href='CHEESE'
                        onClick={(e) => this.handleChangeCategory(e)}
                      >
                        ser
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              {this.state.ingriedients &&
                this.state.ingriedients.map((data) => (
                  <div
                    className={
                      data.id === 601019 ||
                      data.id === 1451 ||
                      data.id === 601028 ||
                      data.id === 601027 ||
                      data.id === 601031
                        ? "customize-item addedIngredients d-none"
                        : "customize-item addedIngredients"
                    }
                    data-category={data.type}
                    data-post={data.id}
                    data-counter='0'
                    data-name={data.name}
                    data-price='+0 zł'
                  >
                    <span
                      className='customize-item__name text__medium text__w300'
                      data-price-size1={
                        data.name === this.state.saucePizza ||
                        data.name === "sos pomidorowy"
                          ? "0,00 zł"
                          : `${data && data.sizes && data.sizes.length > 0 ? data.sizes[0].price.toFixed(2).replace('.', ',') + ' zł' : '4,00 zł'}`
                      }
                      data-price-size2={
                        data.name === this.state.saucePizza ||
                        data.name === "sos pomidorowy"
                          ? "0,00 zł"
                          : `${data && data.sizes && data.sizes.length > 1 ? data.sizes[1].price.toFixed(2).replace('.', ',') + ' zł' : '5,50 zł'}`
                      }
                      data-price-size3={
                        data.name === this.state.saucePizza ||
                        data.name === "sos pomidorowy"
                          ? "0,00 zł"
                          : `${data && data.sizes && data.sizes.length > 2 ? data.sizes[2].price.toFixed(2).replace('.', ',') + ' zł' : '7,00 zł'}`
                      }
                    >
                      {data.name} (<span>{data.sizes[0].price} zł</span>)
                    </span>
                    <div className='customize-item__side-box'>
                      <span
                        className='customize-item__price newAdded'
                        data-name={data.name}
                        data-price='0'
                      >
                        +0 zł
                      </span>
                      <div className='counter currentIngredient'>
                        <div
                          className='counter__minus d-hidden'
                          onClick={(e) => this.counterMinus(e)}
                        >
                          -
                        </div>
                        <div className='counter__item-count'>
                          {data.name === this.state.saucePizza ? "1" : "0"}
                        </div>
                        <span
                          className='counter__plus'
                          onClick={(e) => this.counterPlus(e)}
                        >
                          +
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className='customize-pizza__right-side'>
            <div className='customize-result sticky'>
              <h3 className='text__medium--2 text__w500'>Podsumowanie</h3>
              <div className='customize-result__result-item'>
                <span className='customize-result__result-type text__medium text__w500'>
                  Rodzaj ciasta:
                </span>
                <span className='customize-result__result text__medium text__w400 customizeBox customizeCake'>
                  <div>
                    <span data-id='301151'>grube</span>
                    <span>+0 zł</span>
                  </div>
                </span>
              </div>
              <div className={this.state.data.id !== 601285 ? 'customize-result__result-item ' : 'd-none'}>
                <span className='customize-result__result-type text__medium text__w500'>
                  Rodzaj sosu:
                </span>
                <span className='customize-result__result text__medium text__w400 customizeBox customizeSauce'></span>
              </div>
              <div className='customize-result__result-item '>
                <span className='customize-result__result-type text__medium text__w500'>
                  Składniki bazowe:
                </span>
                <span className='customize-result__result text__medium text__w400 customizeBox customizeContent'>
                  {this.state.data &&
                    this.state.data.ingriedients &&
                    this.state.data.ingriedients.map((data) => (
                      <div>
                        <span
                          data-name={data.name}
                          data-size='1'
                          data-price='3.00'
                        >
                          {data.name} x 1
                        </span>
                        <span>+0 zł</span>
                      </div>
                    ))}
                </span>
              </div>
              <div className='customize-result__result-item '>
                <span className='customize-result__result-type text__medium text__w500'>
                  Składniki dodatkowe:
                </span>
                <span className='customize-result__result text__medium text__w400 customizeBox customizeIngredients'></span>
              </div>
              <div className='customize-result__result-item d-none'>
                <span className='customize-result__result-type text__medium text__w500'>
                  Ulubione smaki:
                </span>
                <span className='customize-result__result text__medium text__w400 customizeBox customizePizzaFavourite'></span>
              </div>
              <div className='customize-result__result-item d-none'>
                <span className='customize-result__result-type text__medium text__w500'>
                  Ulubione sosy:
                </span>
                <span className='customize-result__result text__medium text__w400 customizeBox customizeSauceFavourite'></span>
              </div>
              <div className='customize-pizza__summary'>
                <div className='customize-pizza__summary__price'>
                  <span className='text__medium--2 text__w600'>Suma: </span>
                  <span
                    className='text__medium--2 text__w600 customizePrice'
                    data-size='1'
                  >
                    21,90 zł
                  </span>
                </div>
                <button className='btn' onClick={() => this.addToCart()}>
                  Do koszyka
                </button>
              </div>
            </div>
            <div className='customize-pizza__result-mobile'>
              <div className='customize-pizza__result-mobile__description-box'>
                <h3 className='text__small text__w500 customize-pizza__result-mobile__header'>
                  Aktualne składniki Twojej pizzy
                </h3>
                <span className='text__medium--2 text__w600'>26,90 zł</span>
              </div>
            </div>
          </div>
          <div className='background-customize-pizza'>
            <div
              className='background-customize-pizza__top'
              title='shape top'
            ></div>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}

export default OwnPizza;
