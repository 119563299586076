import React, {Component} from "react";
import './_dropdown.scss';

class Dropdown extends Component {
    componentDidMount() {
        // Change option selected
        const label = document.querySelector('#filter-switch .dropdownSelect__filter-selected');
        const options = Array.from(document.querySelectorAll('#filter-switch .dropdownSelect__select-option'));

        options.forEach((option) => {
            option.addEventListener('click', () => {
                label.textContent = option.textContent
            })
        })

        // Close dropdown onclick outside
        document.addEventListener('click', (e) => {
            const toggle = document.querySelector('.dropdownSelect__switch')
            const element = e.target

            if (element == toggle) return;

            const isDropdownChild = element.closest('.dropdownSelect__filter')

            if (!isDropdownChild) {
                if (toggle !== null) {
                    toggle.checked = false;
                }
            }
        })

        // sessionStorage.setItem('cityID', this.props.data[0].id);

    }

    render() {
        return (
            <div className="dropdownSelect">
                <input type="checkbox" className="dropdownSelect__switch" id="filter-switch" hidden/>
                <label htmlFor="filter-switch" className="dropdownSelect__options-filter">
                    <ul className="dropdownSelect__filter" role="listbox" tabIndex="-1">
                        <li className="dropdownSelect__filter-selected" aria-selected="true">
                            Wrocław
                        </li>
                        {this.props.data.map((data) =>
                            <li>
                                <ul className="dropdownSelect__select">
                                    <li className="dropdownSelect__select-option" role="option" data-id={data.id}>
                                        {data.name}
                                    </li>
                                </ul>
                            </li>
                        )}
                    </ul>
                </label>
            </div>
        )
    }
}

export default Dropdown;