import React, {Component} from "react";
import {Connect} from "../connect/Connect";
import * as endpoint from "../../endpoints/Endpoints";

class Login extends Component {
  componentDidMount() {
    const data = {
      username: "tester",
      password: "zaq1@WSX",
      rememberMe: false
    }
    Connect(`${endpoint.backend}${endpoint.login}`, 'POST', data).then(response => {
      console.log(response)
      localStorage.setItem('token', response.id_token)
    })
  }
  render() {
    return (
        <></>
    )
  }
}
export default Login;