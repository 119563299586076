import React, {Component} from "react";
import './_navbar.scss';
import {Link} from "react-router-dom";
import Modal from "../modal/Modal";
import { withRouter } from 'react-router-dom';
import {Connect} from "../connect/Connect";
import * as endpoint from "../../endpoints/Endpoints";
import logo from '../../../src/icons/logo.svg';
import logoMobile from '../../../src/icons/logo-mobile.svg';

class Navbar extends Component{
    state = {
        premises: '',
        street: '',
        codeMinimum: '',
        cityCode: '',
        streetNumber: '',
        setScrolled: false,
        local: []
    }
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll, true);
        document.addEventListener("mousedown", (e) => this.handleClickOutside(e));
        let premises, street, codeMinimum, cityCode, streetNumber;
        let self = this;
        setTimeout(function(){
            if (sessionStorage.getItem('lokal')) {
                premises = sessionStorage.getItem('lokal')
            }
            if (sessionStorage.getItem('street')) {
                street = sessionStorage.getItem('street')
            }
            if (sessionStorage.getItem('codeMinimum')) {
                codeMinimum = sessionStorage.getItem('codeMinimum')
            }
            if (sessionStorage.getItem('cityCode')) {
                cityCode = sessionStorage.getItem('cityCode')
            }
            // if (sessionStorage.getItem('streetNumber')) {
            //     streetNumber = sessionStorage.getItem('streetNumber')
            // }
            self.setState({
                premises: premises,
                street: street,
                codeMinimum: codeMinimum,
                cityCode: cityCode,
                streetNumber: streetNumber
            })
        }, 1);
        Connect(
            `${endpoint.backend}${endpoint.getPremises}`,
            "GET",
            null
        ).then((response) => {
            this.setState({
                local: response,
            });
        });
    }
    handleClickOutside = (e) => {
        if (e.target.closest('.js-dropdown') === null) {
            const dropdown = document.querySelector('.js-dropdown');
            dropdown.dataset.isOpen = 'false';
            dropdown.classList.remove('active');
        }
    }
    handleScroll = () => {
        const offset = document.querySelector('.blured-background').scrollTop;
        const header = document.querySelector('.header');
        let navbar = document.querySelector('.js-navbar');
        let navbarMenu = document.querySelector('.js-navbar-menu');
        if(offset > 0){
            this.setState({
                setScrolled: true
            })
            navbar.classList.add("navbar--scroll");
            navbarMenu.classList.add("navbar__menu--login--scroll");
            if (document.querySelector('.navbar__left-box .navbar__link:first-child').classList.contains('is-active-white')) {
                document.querySelector('.navbar__left-box .navbar__link:first-child').classList.add('is-active');
            }
        } else {
            this.setState({
                setScrolled: false
            })
            if (navbar !== null) {
                navbar.classList.remove("navbar--scroll")
                navbarMenu.classList.remove("navbar__menu--login--scroll")
            }
            if (document.querySelector('.navbar__left-box .navbar__link:first-child').classList.contains('is-active-white')) {
                document.querySelector('.navbar__left-box .navbar__link:first-child').classList.remove('is-active');
            }
        }
    }
    dropDown = (e) => {
        const dropdown = document.querySelector('.js-dropdown');

        const isOpen = dropdown.dataset.isOpen || 'false'
        dropdown.dataset.isOpen = isOpen === 'false' ? 'true' : 'false'
        if (dropdown.dataset.isOpen === 'true') {
            dropdown.classList.add('active');
        } else {
            dropdown.classList.remove('active');
        }
    }
    changePremises = (e) => {
        e.preventDefault();
        let localCart = [];
        if (JSON.parse(sessionStorage.getItem("localCart"))) {
            localCart = JSON.parse(sessionStorage.getItem("localCart"));
        }
        if (localCart.length > 0) {
            if (e.target.innerText !== 'ul. ' + sessionStorage.getItem('lokal')) {
                document.querySelector('.lokalMenu').setAttribute('data-is-open', false);
                document.querySelector('.lokalMenu').classList.remove('active');
                document.querySelector('.confirm-modal .text__w300').innerHTML = "Po zmianie lokalu Twoje produkty z koszyka zostaną usunięte. <br> Czy na pewno chcesz zmienić lokal?";
                document.querySelector('.confirm-modal').style.display = "block";
            }
        } else {
            sessionStorage.setItem('localCart', JSON.stringify([]));
            sessionStorage.removeItem('lokal');
            sessionStorage.removeItem('lokalHref');
            sessionStorage.removeItem('street');
            sessionStorage.removeItem('streetNumber');
            window.location.href = 'https://pizzabunga.pl/';
        }
    }
    getOrder = (e) => {
        e.preventDefault();
        let summary = document.querySelector('.cartLink').getAttribute('data-summary');
        if (sessionStorage.getItem('ownDelivery') && sessionStorage.getItem('ownDelivery') === 'true' && parseFloat(summary) > 0) {
            window.location.href = "/podsumowanie-zamowienia/";
        } else {
            console.log(parseFloat(summary) > parseInt(sessionStorage.getItem('codeMinimum')))
            if (parseFloat(summary) > parseInt(sessionStorage.getItem('codeMinimum'))) {
                window.location.href = "/podsumowanie-zamowienia/";
            } else {
                document.querySelector('.price-modal .text__w300').innerText = `minimalna kwota do zamówień z dostawą to ${sessionStorage.getItem('codeMinimum')} zł`;
                document.querySelector('.price-modal').style.display = "block";
            }
        }
    }
    openHamburger = () => {
        const sidebar = document.querySelector('.js-sidebar');
        const navbar = document.querySelector('.js-navbar');
        const bluredBackground = document.querySelector('.blured-background');
        const isOpen = sidebar.dataset.show || 'false'
        sidebar.dataset.show = isOpen === 'false' ? 'true' : 'false'
        navbar.style.display = isOpen === 'true' ? 'flex' : 'none'
        bluredBackground.dataset.blured = isOpen === 'true' ? 'false' : 'true'
    }
    render() {
        return (
            <nav className="navbar js-navbar">
                <div className="navbar__background">&nbsp;</div>
                <div className="navbar__layout">
                    <Link to={sessionStorage.getItem('lokalUrl') ? sessionStorage.getItem('lokalUrl'): "/"} className="navbar__logo">
                        <img src={logo} alt="logo" />
                    </Link>
                    <Link to={sessionStorage.getItem('lokalUrl') ? sessionStorage.getItem('lokalUrl'): "/"} className="navbar__logo--mobile">
                        <img className="navbar__logo-img"
                             src={logoMobile}
                             alt="logo" />
                    </Link>
                    <div className={this.state.premises !== undefined ? "dropdown js-dropdown lokalMenu" : "dropdown js-dropdown lokalMenu d-none"} data-is-open="false" onClick={(e) => this.dropDown(e)}>
                        <div className="dropdown__value js-dropdown-trigger">
                            <p className="dropdown__headerSub text__small">Twój lokal:</p>
                            <p className="dropdown__header text__small--2 text__w700">{this.state.premises}</p>
                            <span className="arrdow"><svg width="13" height="9" viewBox="0 0 13 9" fill="none"
                                       xmlns="http://www.w3.org/2000/svg">
    <path d="M1 1L6.5 7L12 1" stroke="black" stroke-width="2" stroke-linecap="round"></path>
</svg>
</span>
                        </div>
                        <div className="dropdown__list js-dropdown-options-wrapper">
                            {this.state.local.map((data) => (
                                data.menuSite !== true &&
                                    <>
                                        {data.id != sessionStorage.getItem('lokalID') ?
                                            <>
                                                <div className="dropdown__list-item"
                                                     data-href={'/' + data.slug + '/'} onClick={(e) => this.changePremises(e)}>{data.address}
                                                </div>
                                                <a href={`tel:${data.phone}`} className="dropdown__list-phone">tel: {data.phone}</a>
                                            </>
                                       : <></>}
                                    </>
                            ))}
                        </div>
                    </div>
                    <div className={this.state.street !== undefined ? "dropdown js-dropdown myAddress" : "dropdown js-dropdown myAddress d-none"} data-is-open="false">
                        <div className="dropdown__value js-dropdown-trigger">
                            <p className="dropdown__headerSub text__small">Twój adres:</p>
                            <p className="dropdown__header text__small--2 text__w700">ul. {this.state.street} {this.state.streetNumber}</p>
                            <span><svg width="13" height="9" viewBox="0 0 13 9" fill="none"
                                       xmlns="http://www.w3.org/2000/svg">
    <path d="M1 1L6.5 7L12 1" stroke="black" stroke-width="2" stroke-linecap="round"></path>
</svg>
</span>
                        </div>
                        <div className="dropdown__list js-dropdown-options-wrapper">
                            <div className="dropdown__list-item" data-href="">Zmień</div>
                        </div>
                    </div>
                    <div className="navbar__menu js-navbar-menu">
                        <div className="navbar__left-box">
                            <Link to={sessionStorage.getItem('lokalUrl') ? sessionStorage.getItem('lokalUrl'): "/"}
                                  className={sessionStorage.getItem('lokal') ? "navbar__link text__small--2 text__w700" : "navbar__link text__small--2 text__w700 d-none"}>Nasze menu</Link>
                            <Link to="/lokale/"
                                  className="navbar__link text__small--2 text__w700">Lokale</Link>
                            <Link to="/o-nas/"
                                  className="navbar__link text__small--2 text__w700">O nas</Link>
                            <Link to="/praca/"
                                  className="navbar__link text__small--2 text__w700">Praca</Link>
                            <Link to="/franczyza/"
                                  className="navbar__link text__small--2 text__w700">Franczyza</Link>
                            <Link to="/kontakt/"
                                  className=" navbar__link text__small--2 text__w700">Kontakt</Link>
                            <Link to="/potwierdzenie-cv/" id="successOffer"
                                  className=" navbar__link text__small--2 text__w700 d-none">Wysłano</Link>
                        </div>
                        {sessionStorage.getItem('lokal') ?
                            <a href="" className="cartLink" data-summary={this.props.summary} onClick={(e) => this.getOrder(e)}>
                            <span className="navbar__icon navbar__icon--basket">
                                <svg width="28" height="26" fill="none" xmlns="http://www.w3.org/2000/svg"><path
                                    d="M27.18 8.898h-2.887l-6.316-7.895a.82.82 0 00-1.282 1.025l5.497 6.87H5.808l5.497-6.87a.82.82 0 10-1.281-1.025L3.707 8.898H.82a.82.82 0 00-.82.82V13c0 .453.367.82.82.82h1.022l3.111 10.89a.82.82 0 00.79.595h16.515a.82.82 0 00.789-.595l3.11-10.89h1.023A.82.82 0 0028 13V9.719a.82.82 0 00-.82-.82zm-5.54 14.766H6.36L3.549 13.82h20.904l-2.813 9.844zm4.72-11.484H1.64v-1.64h24.72v1.64z"
                                    fill="#000"></path></svg>                    <span className="navbar__icon--basket--counter"><div
                                id="wppizza-totals-1"
                                className="wppizza-totals-container wppizza-totals wppizza-totals-no-items"><span
                                className="wppizza-totals-order">{this.props.sum}</span></div></span>
                            </span>
                            </a>
                        : ''}
                        <div className="navbar__right-box ">
                            <a href="#" className="navbar__link--language text__w400 d-none">PL</a>
                            <a href="#" className="navbar__link--language text__w400 d-none">ENG</a>
                            <a href="https://pl-pl.facebook.com/pizzabunga" target="_blank"
                               className="navbar__icon navbar__icon--fb">
                                <svg width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M17.999 9a9 9 0 10-10.406 8.89V11.6H5.308V9h2.285V7.016c0-2.256 1.344-3.502 3.4-3.502.984 0 2.014.176 2.014.176v2.215h-1.135c-1.118 0-1.467.694-1.467 1.405V9h2.496l-.399 2.602h-2.097v6.288C14.708 17.214 18 13.491 18 9z"
                                        fill="#000"></path>
                                </svg>
                            </a>
                            <button btn="" className="btn btn--nav js-navbar-login d-none">Zaloguj się</button>
                        </div>
                    </div>
                    <div hamburger="" className="hamburger js-hamburger" onClick={() => this.openHamburger()}>
                        <div className="hamburger__stick hamburger__stick--top"></div>
                        <div className="hamburger__stick hamburger__stick--mid"></div>
                        <div className="hamburger__stick hamburger__stick--bottom"></div>
                    </div>
                </div>
                <Modal />
                <Link to="/koszyk/" className={this.state.premises !== undefined ? "cartMobile" : "d-none"}>
                    <svg width="55" height="49" viewBox="0 0 55 49" fill="none" className="shopping-card__icon"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M53.3887 16.4433H47.7178L35.311 0.934845C34.7552 0.240148 33.7412 0.127247 33.0462 0.683263C32.3513 1.23917 32.2386 2.25313 32.7946 2.94804L43.5908 16.4433H11.4092L22.2055 2.94804C22.7614 2.25313 22.6487 1.23906 21.9539 0.683263C21.2589 0.127247 20.2449 0.24004 19.6891 0.934845L7.28224 16.4433H1.61133C0.721445 16.4433 0 17.1648 0 18.0547V24.5C0 25.3899 0.721445 26.1113 1.61133 26.1113H3.61861L9.73006 47.5013C9.92761 48.1931 10.5599 48.6699 11.2793 48.6699H43.7207C44.4401 48.6699 45.0724 48.1931 45.2701 47.5013L51.3814 26.1113H53.3887C54.2786 26.1113 55 25.3899 55 24.5V18.0547C55 17.1648 54.2786 16.4433 53.3887 16.4433ZM42.5053 45.4473H12.4947L6.97018 26.1113H48.0299L42.5053 45.4473ZM51.7773 22.8887C46.2575 22.8887 8.45195 22.8887 3.22266 22.8887V19.666H51.7773V22.8887Z"
                            fill="#FFF"></path>
                    </svg>
                    <span>{this.props.sum}</span>
                    <span className="number">{this.props.counter}</span>
                </Link>
            </nav>
        )
    }
}

export default withRouter(Navbar);