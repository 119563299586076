import React, { Component } from "react";
import Navbar from "../components/navbar/Navbar";
import Checkbox from "../components/checkbox/Checkbox";
import { Connect } from "../components/connect/Connect";
import * as endpoint from "../endpoints/Endpoints";
import pizzaprom from "../assets/pizzaprom.jpg";
import NumberFormat from "react-number-format";
import Footer from "../components/footer/Footer";
import trash from "../assets/trash.svg";
import CustomModal from "../components/CustomModal/CustomModal";

class Summary extends Component {
  state = {
    cart: [],
    qNumber: 0,
    invoice: false,
    isPremiseOpened: false,
    isModalOpened: false,
    sendRequest: true,
    deliveryType:
      sessionStorage.getItem("ownDelivery") !== "true"
        ? "DELIVERY"
        : "PERSONAL",
    payment: "ON_DELIVERY",
    discountType: sessionStorage.getItem("discountType")
      ? sessionStorage.getItem("discountType")
      : "",
    discountValue: sessionStorage.getItem("discountValue")
      ? sessionStorage.getItem("discountValue")
      : "",
    discountRealValue: sessionStorage.getItem("discountRealValue")
      ? sessionStorage.getItem("discountRealValue")
      : "",
    discountSum: sessionStorage.getItem("discountSum")
      ? sessionStorage.getItem("discountSum")
      : "",
    discountName: sessionStorage.getItem("discountName")
      ? sessionStorage.getItem("discountName")
      : "",
    discountID: sessionStorage.getItem("discountID")
      ? sessionStorage.getItem("discountID")
      : "",
    discountData: [],
    premiseID: parseInt(
      sessionStorage.getItem("lokalID")
        ? sessionStorage.getItem("lokalID")
        : 1051
    ),
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    console.log( document.querySelector('#orderStreet'))
    console.log( document.querySelector('#orderStreet').value)
    console.log(sessionStorage.getItem("streetFull"))
    document.querySelector('#orderStreet').value = sessionStorage.getItem("streetFull");
    this.updateCart();

    this.premiseOpen();

    let nav = document.querySelectorAll(".navbar__link");
    for (let i = 0; i < nav.length; i++) {
      nav[i].classList.remove("is-active");
    }

    if (
      sessionStorage.getItem("ownDelivery") &&
      sessionStorage.getItem("ownDelivery") === "true"
    ) {
      document.querySelector(".switchOwnDeliver").click();
    }

    // document.querySelector('#orderNumberBuilding').value = sessionStorage.getItem("streetNumber");

    document.querySelector(".sendOrderForms").addEventListener("click", (e) => {
      e.preventDefault();
      let summary = document
        .querySelector(".cartLink")
        .getAttribute("data-summary");
      if (
        sessionStorage.getItem("ownDelivery") &&
        sessionStorage.getItem("ownDelivery") === "false" &&
        parseFloat(summary) < parseInt(sessionStorage.getItem("codeMinimum"))
      ) {
        document.querySelector(
          ".price-modal .text__w300"
        ).innerText = `minimalna kwota do zamówień z dostawą to ${sessionStorage.getItem(
          "codeMinimum"
        )} zł`;
        document.querySelector(".price-modal").style.display = "block";
      } else {
        if (this.state.cart.length > 0) {
          validateForm(document.querySelector(".orderform"));
        } else {
          document.querySelector(".order-empty-modal").style.display = "block";
        }
      }
    });

    document.querySelector(".sendOrderForm").addEventListener("click", (e) => {
      e.preventDefault();
      document.querySelector(".sendOrderForms").click();
    });

    // Set Error Message
    function setError(input, errormsg) {
      const formGroup = input.parentElement;
      const inputAlert = formGroup.querySelector(".input__error");
      formGroup.className = "input js-input error";
      inputAlert.innerText = errormsg;
    }
    // Set Success Message
    function setSuccess(input) {
      const formGroup = input.parentElement;
      const inputAlert = formGroup.querySelector(".input__error");
      if (inputAlert !== null) {
        inputAlert.innerText = "";
      }
      formGroup.className = "input js-input success";
    }
    // Set Error Message
    function setErrorRadio(input, errormsg) {
      const formGroup = input[0].parentElement.closest(".summary-payment-box");
      const inputAlert = formGroup.querySelector(".error");
      formGroup.className = "summary-payment-box error";
      inputAlert.innerText = errormsg;
    }
    // Set Success Message
    function setSuccessRadio(input) {
      const formGroup = input[0].parentElement.closest(".summary-payment-box");
      const inputAlert = formGroup.querySelector(".error");
      if (inputAlert !== null) {
        inputAlert.innerText = "";
      }
      formGroup.className = "summary-payment-box success";
    }
    // Set Error Message
    function setErrorRadioAccept(input, errormsg) {
      const formGroup = input.parentElement.closest(
        ".order-summary__accept-terms"
      );
      const inputAlert = formGroup.querySelector(".error");
      formGroup.className = "order-summary__accept-terms error";
      inputAlert.innerText = errormsg;
    }
    // Set Success Message
    function setSuccessRadioAccept(input) {
      const formGroup = input.parentElement.closest(
        ".order-summary__accept-terms"
      );
      const inputAlert = formGroup.querySelector(".error");
      if (inputAlert !== null) {
        inputAlert.innerText = "";
      }
      formGroup.className = "order-summary__accept-terms success";
    }
    function validEmail(email) {
      const re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }

    function scrollIt(element) {
      element.scrollIntoView();
    }

    // Form Validation Check
    const validateForm = (form) => {
      let complete = true;
      if (form.orderName.value.trim() === "") {
        setError(form.orderName, "Wymagane pole");
        scrollIt(document.querySelector(".order-summary__contact-box"));
        complete = false;
      } else {
        setSuccess(form.orderName);
      }
      if (form.orderEmail.value.trim() === "") {
        setError(form.orderEmail, "Wymagane pole");
        scrollIt(document.querySelector("#orderName"));
        complete = false;
      } else if (!validEmail(form.orderEmail.value.trim())) {
        setError(form.orderEmail, "Nieprawidłowy Email");
        scrollIt(document.querySelector("#orderName"));
        complete = false;
      } else {
        setSuccess(form.orderEmail);
      }
      if (form.orderPhone.value.trim() === "") {
        setError(form.orderPhone, "(Wymagane pole)");
        scrollIt(document.querySelector("#orderEmail"));
        complete = false;
      } else {
        setSuccess(form.orderPhone);
      }
      if (form.cash.value.trim() === "") {
        setErrorRadio(form.cash, "(Wymagane pole)");
        scrollIt(document.querySelector("#summaryPayment"));
        complete = false;
      } else {
        setSuccessRadio(form.cash);
      }
      if (form.accept.checked === false) {
        setErrorRadioAccept(form.accept, "(Wymagane pole)");
        scrollIt(document.querySelector("#acceptAll"));
        complete = false;
      } else {
        setSuccessRadioAccept(form.accept);
      }
      if (form.acceptPolicy.checked === false) {
        setErrorRadioAccept(form.acceptPolicy, "(Wymagane pole)");
        scrollIt(document.querySelector("#acceptAll"));
        complete = false;
      } else {
        setSuccessRadioAccept(form.acceptPolicy);
      }
      if (form.acceptLaw.checked === false) {
        setErrorRadioAccept(form.acceptLaw, "(Wymagane pole)");
        scrollIt(document.querySelector("#acceptAll"));
        complete = false;
      } else {
        setSuccessRadioAccept(form.acceptLaw);
      }
      if (
        form.orderStreet.value.trim() === "" &&
        this.state.deliveryType !== "PERSONAL"
      ) {
        setError(form.orderStreet, "Wymagane pole");
        scrollIt(document.querySelector(".summary-deliver-box"));
        complete = false;
      } else if (
        form.orderStreet.value.trim() === "" &&
        this.state.deliveryType === "PERSONAL"
      ) {
        setSuccess(form.orderStreet);
      } else {
        setSuccess(form.orderStreet);
      }
      // if (
      //   form.orderNumberFlat.value.trim() === "" &&
      //   this.state.deliveryType !== "PERSONAL"
      // ) {
      //   setError(form.orderNumberFlat, "Wymagane pole");
      //   scrollIt(document.querySelector("#orderStreet"));
      //   complete = false;
      // } else if (
      //   form.orderNumberFlat.value.trim() === "" &&
      //   this.state.deliveryType === "PERSONAL"
      // ) {
      //   setSuccess(form.orderNumberFlat);
      // } else {
      //   setSuccess(form.orderNumberFlat);
      // }
      if (complete === true) {
        let name, email, address, phone;
        name = document.querySelector(".orderName").value;
        email = document.querySelector(".orderEmail").value;
        // if (document.querySelector("#orderNumberFlat").value !== "") {
        //   address =
        //     document.querySelector(".orderStreet").value +
            // " " +
            // document.querySelector(".orderNumberBuilding").value +
            // "/" +
            // document.querySelector("#orderNumberFlat").value +
        //     ", " +
        //     document.querySelector(".orderPostCode").value;
        // } else {
          address =
            document.querySelector(".orderStreet").value +
            // " " +
            // document.querySelector(".orderNumberBuilding").value +
            ", " +
            document.querySelector(".orderPostCode").value;
        // }
        phone = document.querySelector(".orderPhone").value;
        let lokalNamedS = sessionStorage.getItem("lokal");
        let comment = document.querySelector(".orderComment").value;
        let summaryProducts = JSON.parse(sessionStorage.getItem("localCart"));
        let summaryContent = "";
        for (let i = 0; i < summaryProducts.length; i++) {
          let contains = false;
          let extend = "";
          for (let j = 0; j < summaryProducts[i].info.length; j++) {
            console.log(summaryProducts[i].info[j]);
            let customize = `${summaryProducts[i].info[j].name} ${summaryProducts[i].info[j].price}, `;
            extend += customize;
            contains = true;
          }
          if (contains === true) {
            let name = summaryProducts[i].name;
            console.log(name);
            console.log(extend);
            let summaryItem = `${name}
    (${extend})
`;
            summaryContent += summaryItem;
          }
        }
        console.log(summaryContent);
        let allComments = `Lokal:
            ${lokalNamedS}

            Komentarz klienta: ${comment}`;
        document.querySelector(".summary-shopping-cart__items #cname").value =
          name;
        document.querySelector(".summary-shopping-cart__items #cemail").value =
          email;
        document.querySelector(
          ".summary-shopping-cart__items #caddress"
        ).value = address;
        document.querySelector(".summary-shopping-cart__items #ctel").value =
          phone;
        document.querySelector(
          ".summary-shopping-cart__items #ccomments"
        ).value = allComments;
        document.querySelector(
          ".summary-shopping-cart__items #ccustom1"
        ).value = lokalNamedS;
        // if (document.querySelector('.paymentMethodSummary').innerText === "Przelewy24") {
        //     document.querySelector('#wppizza_gateway_selected_przelewy24').click();
        //
        // }
        if (
          document.querySelector("#touchless-delivery").checked &&
          !document.querySelector(
            ".summary-payment-box__option:nth-child(2) input"
          ).checked
        ) {
          document.querySelector(".payment-modal").style.display = "block";
        } else {
          //document.querySelector('#wppizza-ordernow').click()
          let localCart = [];
          if (JSON.parse(sessionStorage.getItem("localCart"))) {
            localCart = JSON.parse(sessionStorage.getItem("localCart"));
          }
          console.log(
            document.querySelector(".paymentMethodSummary").innerText
          );
          // if (document.querySelector('.paymentMethodSummary').innerText === "gotówka" || document.querySelector('.paymentMethodSummary').innerText === "karta") {
          let pizzas = [];
          let products = [];
          let specialOfferPurchases = [];
          JSON.parse(sessionStorage.getItem("localCart")).map((item) => {
            if (item.pizza !== false) {
              let tempIngredients = [];
              let tempPizzas = [];
              let tempCake = 0;
              if (item.type === "twopizza") {
                item.infoPromo.map((subitem) => {
                  let cake = 0;
                  item.infoPromo.map((subiitem) => {
                    if (subiitem.type === "cake") {
                      cake = subiitem.id;
                      tempCake = cake;
                    }
                  });
                  if (subitem.type === "base") {
                    let element = {
                      id: subitem.id,
                    };
                    tempPizzas.push(element);
                  } else {
                    let element = {
                      id: subitem.id,
                    };
                    if (subitem.type !== "cake") {
                      tempIngredients.push(element);
                    }
                  }
                });
                let tempDough = {
                  id: tempCake,
                };
                const pizze = [];
                tempPizzas.forEach((pizza) => {
                  pizze.push({
                    quantity: 1,
                    pizza: {
                      id: pizza.id,
                    },
                  });
                });
                const products = [];
                tempIngredients.forEach((item) => {
                  pizze.push({
                    quantity: 1,
                    product: {
                      id: item.id,
                    },
                  });
                });
                let temp = {
                  specialOffer: {
                    id: item.id,
                  },
                  specialOfferItemPurchases: [...pizze, ...products],
                  // pizzas: tempPizzas,
                  // sauces: tempIngredients,
                  dough: tempDough,
                };
                specialOfferPurchases.push(temp);
              } else {
                item.info.map((subitem) => {
                  let action = "ADD";
                  if (subitem.name === "sos pomidorowy") {
                    action = "EXTRA_FREE";
                  }
                  if (subitem.type === "sauce" && subitem.price === "+0 zł") {
                    action = "EXTRA_FREE";
                  }
                  if (subitem.type === "base" && subitem.counter === 0) {
                    action = "REMOVE";
                  }
                  if (subitem.type === "base" && subitem.counter === 1) {
                  } else {
                    let element = {
                      quantity: subitem.counter,
                      action: action,
                      ingriedient: {
                        id: parseInt(subitem.id),
                        name: subitem.name,
                      },
                    };
                    if (parseInt(subitem.id) !== 0) {
                      tempIngredients.push(element);
                    }
                  }
                });
                let temp = {
                  ingriedients: tempIngredients,
                  pizza: {
                    id: item.id,
                  },
                  saleId: 0,
                  size: {
                    id: item.sizeId,
                    price: item.price.replace(" zł", "").replace(",", "."),
                    name: item.size,
                  },
                  quantity: item.counter,
                };
                pizzas.push(temp);
              }
            } else {
              let temp = {
                product: {
                  id: item.id,
                  name: item.name,
                },
                size: {
                  id: item.sizeId,
                  price: item.price.replace(" zł", "").replace(",", "."),
                  name: item.size,
                },
                quantity: item.counter,
              };
              products.push(temp);
            }
            return true;
          });
          let discount = [];
          if (this.state.discountID !== "") {
            discount = [
              {
                end: this.state.discountData.end,
                id: this.state.discountData.id,
                start: this.state.discountData.start,
                type: this.state.discountData.type,
                value: this.state.discountData.value,
              },
            ];
          }
          let invoiceData = null;
          if (this.state.invoice === true) {
            invoiceData = {
              // building: document.querySelector("#vatBuilding").value,
              city: document.querySelector("#vatFlat").value,
              companyName: document.querySelector("#vatName").value,
              // flat: document.querySelector("#vatFlat").value,
              nip: document.querySelector("#vatNip").value,
              street: document.querySelector("#vatStreet").value,
              zipCode: document.querySelector("#vatZipCode").value,
            };
          } else {
            invoiceData = null;
          }
          const data = {
            data: {
              // building: document.querySelector("#orderNumberBuilding").value,
              comment: document.querySelector("#orderComment").value,
              email: document.querySelector("#orderEmail").value,
              flat: document.querySelector("#orderNumberFlat").value,
              name: document.querySelector("#orderName").value,
              phone: document.querySelector("#orderPhone").value,
              street: document.querySelector("#orderStreet").value,
              zipCode: sessionStorage.getItem("postCode"),
            },
            premise: {
              id: this.state.premiseID,
            },
            deliveryType: this.state.deliveryType,
            discounts: discount,
            invoice: this.state.invoice,
            invoiceData: invoiceData,
            payment: this.state.payment,
            pizzas,
            specialOfferPurchases,
            price: 0,
            productPurchases: products,
            sales: [],
            secondConsent: true,
            statueConsent: true,
            status: "NEW",
            thirdConsent: true,
            userId: null,
            deliveryFee: !isNaN(
              parseFloat(sessionStorage.getItem("codeDelivery"))
            )
              ? parseFloat(sessionStorage.getItem("codeDelivery"))
              : 0,
            address: {
              id: parseInt(sessionStorage.getItem('cityID'))
            }
          };
          if (this.state.isPremiseOpened === false) {
            this.setState({
              isModalOpened: true,
            });
          } else {
            if (this.state.sendRequest === true) {
              this.setState({
                sendRequest: false,
              });
              Connect(
                  `${endpoint.backend}${endpoint.createPizzaPurchase}`,
                  "POST",
                  data
              ).then((response) => {
                window.location.href = response.url;
              });
            }
          }
        }
      }

      return true;
    };
  }

  async getPremise(premise) {
    return await Connect(
      `${endpoint.backend}${endpoint.getPremises}/${premise}`,
      "GET",
      null
    ).then((res) => res);
  }

  async getServerTime() {
    return await Connect(
      `${endpoint.backend}${endpoint.getServerTime}`,
      "GET",
      null
    ).then((res) => (Number.isInteger(res) ? new Date(res) : null));
  }

  async checkIsPremiseOpen(premise) {
    const serverTime = await this.getServerTime();
    const currentTime = {
      weekDay: serverTime.getDay(),
      hour: serverTime.getHours(),
      minutes: serverTime.getMinutes(),
    };
    const typeOfDay =
      currentTime.weekDay === 0 || currentTime.weekDay > 5
        ? "Weekend"
        : "Workday";
    this.setState({ typeOfDay: typeOfDay });
    const openingHours = {
      openHour: Number(premise[`openHour${typeOfDay}`].split(":")[0]),
      openMinutes: Number(premise[`openHour${typeOfDay}`].split(":")[1]),
      closeHour: Number(premise[`closeHour${typeOfDay}`].split(":")[0]),
      closeMinutes: Number(premise[`closeHour${typeOfDay}`].split(":")[1]),
    };

    if (premise.open) {
      if (
        (currentTime.hour > openingHours.openHour &&
          currentTime.hour < openingHours.closeHour) ||
        (currentTime.hour === openingHours.openHour &&
          currentTime.minutes >= openingHours.openMinutes) ||
        (currentTime.hour === openingHours.closeHour &&
          currentTime.minutes <= openingHours.closeMinutes)
      ) {
        // opening hours for example - 10:30 - 18:30
        // first condition - hour is in range 11-17
        // second condition - hour is 10 and minutes are 30-59
        // third condition - hour is 18 and minutes are 0-30
        return true;
      }
    }
    return false;
  }

  async premiseOpen() {
    const premiseInfo = await this.getPremise(
      sessionStorage.getItem("lokalID")
    );
    if (typeof premiseInfo.name !== "undefined") {
      const isPremiseOpened = await this.checkIsPremiseOpen(premiseInfo);
      this.setState({
        isPremiseOpened: isPremiseOpened,
      });
    }
  }

  acceptAll = () => {
    if (document.querySelector("#accept-all").checked === true) {
      document.querySelector("#accept-terms").checked = true;
      document.querySelector("#accept-policy").checked = true;
      document.querySelector("#accept-law").checked = true;
    } else {
      document.querySelector("#accept-terms").checked = false;
      document.querySelector("#accept-policy").checked = false;
      document.querySelector("#accept-law").checked = false;
    }
  };

  sumCart = () => {
    let sum = 0;
    let counter = 0;
    this.state.cart.map((item) => {
      let price = item.price.replace(",", ".");
      price = price.replace(" zł", "");
      sum += parseFloat(price);
      counter += 1;
    });
    if (!isNaN(parseFloat(sessionStorage.getItem("codeDelivery")))) {
      if (sessionStorage.getItem("ownDelivery") !== "true") {
        sum += parseFloat(sessionStorage.getItem("codeDelivery"));
      }
    }
    if (!isNaN(parseFloat(sessionStorage.getItem("discountRealValue")))) {
      sum -= parseFloat(sessionStorage.getItem("discountRealValue"));
    }
    sum = sum.toFixed(2);
    sum = sum.toString().replace(".", ",") + " zł";
    this.props.changeSum(sum, counter);
  };
  updateCart = () => {
    let localCart = [];
    if (JSON.parse(sessionStorage.getItem("localCart"))) {
      localCart = JSON.parse(sessionStorage.getItem("localCart"));
    }
    this.setState({
      cart: localCart,
    });
    let self = this;
    setTimeout(function () {
      self.sumCart();
    }, 1);
  };
  removeFromCart = (e, queNumber) => {
    e.preventDefault();
    this.setState({
      qNumber: queNumber,
    });
    document.querySelector(".confirm-modal-delete").style.display = "block";
    document
      .querySelector(".confirm-modal-delete .updateDelete")
      .addEventListener("click", (e) => {
        e.preventDefault();
        if (
          document.querySelector(".confirm-modal-delete").style.display ===
          "block"
        ) {
          document.querySelector(".confirm-modal-delete").style.display =
            "none";
          let localCart = [];
          if (JSON.parse(sessionStorage.getItem("localCart"))) {
            localCart = JSON.parse(sessionStorage.getItem("localCart"));
          }
          localCart.splice(this.state.qNumber, 1);
          sessionStorage.setItem("localCart", JSON.stringify(localCart));
          this.updateCart();
        }
      });
  };
  updateCounterCard = (e, queNumber) => {
    e.preventDefault();
    let localCart = [];
    if (JSON.parse(sessionStorage.getItem("localCart"))) {
      localCart = JSON.parse(sessionStorage.getItem("localCart"));
    }
    let currentValue = e.target
      .closest(".wppizza-item-quantity")
      .querySelector(".ui-spinner-input").value;
    let condition = "plus";
    if (e.target.classList.contains("ui-spinner-down")) {
      condition = "minus";
    }
    if (e.target.closest("a").classList.contains("ui-spinner-down")) {
      condition = "minus";
    }
    let newValue;
    if (condition === "plus") {
      newValue = parseInt(currentValue) + 1;
    } else {
      if (currentValue > 1) {
        newValue = parseInt(currentValue) - 1;
      }
    }
    localCart.map((item, index) => {
      if (index === queNumber) {
        let oldPrice = item.price
          .substring(0, item.price.lastIndexOf(" zł"))
          .replace(",", ".");
        oldPrice = parseFloat(oldPrice) / parseInt(item.counter);
        item.counter = parseInt(newValue);
        let tempPrice = parseFloat(oldPrice) * parseInt(item.counter);
        let newPrice = tempPrice.toFixed(2);
        item.price = newPrice.toString().replace(".", ",") + " zł";
      }
      return true;
    });
    sessionStorage.setItem("localCart", JSON.stringify(localCart));
    this.updateCart();
  };
  couponCard = (e) => {
    e.preventDefault();
    document.querySelector("#wppizza_cad_coupons_wrap").style.display = "block";
  };
  getCouponCard = () => {
    let name = document.querySelector("#discountCode").value;
    Connect(
      `${endpoint.backend}${endpoint.getDiscount}/${name}`,
      "GET",
      null
    ).then((response) => {
      if (response.code !== undefined) {
        sessionStorage.setItem("discountData", response);
        sessionStorage.setItem("discountType", response.type);
        sessionStorage.setItem("discountValue", response.value);
        sessionStorage.setItem("discountName", response.name);
        sessionStorage.setItem("discountID", response.id);
        let discountRealValue = parseFloat(
          this.props.sum.replace(" zł", "").replace(",", ".")
        );
        let discountSum = parseFloat(
          this.props.sum.replace(" zł", "").replace(",", ".")
        );
        if (response.type === "PERCENT") {
          discountRealValue =
            (parseFloat(response.value) / 100) * discountRealValue;
        } else {
          discountRealValue = parseFloat(response.value);
        }
        sessionStorage.setItem(
          "discountRealValue",
          discountRealValue.toFixed(2)
        );
        discountSum = discountSum - discountRealValue.toFixed(2);
        sessionStorage.setItem("discountSum", discountSum);
        this.setState({
          discountType: response.type,
          discountValue: response.value,
          discountName: response.name,
          discountID: response.id,
          discountData: response,
          discountRealValue: discountRealValue.toFixed(2),
          discountSum: discountSum,
        });
        document
          .querySelector(".wppizza-order-summary")
          .classList.add("d-none");
      } else {
        document
          .querySelector("#wppizza_cad_coupons-results")
          .classList.add("d-block");
      }
      let self = this;
      setTimeout(function () {
        self.sumCart();
      }, 1);
    });
  };
  switchDeliver = (e) => {
    if (e.target.classList.contains("summary-deliver-box__navbar-link")) {
      let summary = document
        .querySelector(".cartLink")
        .getAttribute("data-summary");
      if (
        sessionStorage.getItem("ownDelivery") &&
        sessionStorage.getItem("ownDelivery") === "true" &&
        parseFloat(summary) < parseInt(sessionStorage.getItem("codeMinimum"))
      ) {
        document.querySelector(
          ".price-modal .text__w300"
        ).innerText = `minimalna kwota do zamówień z dostawą to ${sessionStorage.getItem(
          "codeMinimum"
        )} zł`;
        document.querySelector(".price-modal").style.display = "block";
      } else {
        document
          .querySelector(".switchOwnDeliver")
          .classList.remove("summary-deliver-box__navbar-link--active");
        document
          .querySelector(".switchOwnDeliver")
          .classList.add("summary-deliver-box__navbar-link");
        e.target.classList.remove("summary-deliver-box__navbar-link");
        e.target.classList.add("summary-deliver-box__navbar-link--active");
        document.querySelector("#formOrderSummary").classList.remove("d-none");
        document.querySelector("#orderDeliverLabel").innerText =
          "dostawa bezkontaktowa z obowiązkiem przedpłaty";
        //document.querySelector('.deliverOrder').innerText = "12 zł";
        //document.querySelector('.deliverOrder').classList.remove('active');
        document.querySelector(".ownDeliveryOption").classList.remove("d-none");
        if (sessionStorage.getItem("ownDelivery") === "true") {
          sessionStorage.removeItem("welcomeSummary");
          sessionStorage.setItem("ownDelivery", "false");
          //location.reload();
        }
      }
      this.setState({
        deliveryType: "DELIVERY",
      });
      if (sessionStorage.getItem("codeDelivery") !== null) {
        let tempDeliver = sessionStorage.getItem("codeDelivery");
        if (
          !isNaN(parseFloat(tempDeliver)) &&
          sessionStorage.getItem("ownDelivery") !== "true"
        ) {
          if (document.querySelector(".deliverPrice") !== null) {
            document.querySelector(".deliverPrice").innerText =
                "+" + tempDeliver + " zł";
          }
        } else {
          if (document.querySelector(".deliverPrice") !== null) {
            document.querySelector(".deliverPrice").innerText = "+0 zł";
          }
        }
      } else {
        document.querySelector(".deliverPrice").innerText = "+0 zł";
      }
      let self = this;
      setTimeout(function () {
        self.sumCart();
      }, 1);
    }
  };
  switchOwnDeliver = (e) => {
    if (e.target.classList.contains("summary-deliver-box__navbar-link")) {
      document
        .querySelector(".switchDeliver")
        .classList.remove("summary-deliver-box__navbar-link--active");
      document
        .querySelector(".switchDeliver")
        .classList.add("summary-deliver-box__navbar-link");
      e.target.classList.remove("summary-deliver-box__navbar-link");
      e.target.classList.add("summary-deliver-box__navbar-link--active");
      document.querySelector("#formOrderSummary").classList.add("d-none");
      let lokal = sessionStorage.getItem("lokal");
      document.querySelector(
        "#orderDeliverLabel"
      ).innerHTML = `Najbliższy lokal, w którym odbierzesz swoje zamówienie to <span>${lokal}</span>`;
      //document.querySelector('.deliverOrder').innerText = "0 zł";
      // document.querySelector('.deliverOrder').classList.add('active');
      document.querySelector(".ownDeliveryOption").classList.add("d-none");
      if (sessionStorage.getItem("ownDelivery") === "false") {
        sessionStorage.removeItem("welcomeSummary");
        sessionStorage.setItem("ownDelivery", "true");
        //location.reload();
      }
      this.setState({
        deliveryType: "PERSONAL",
      });
    }
    if (sessionStorage.getItem("codeDelivery") !== null) {
      let tempDeliver = sessionStorage.getItem("codeDelivery");
      if (
        !isNaN(parseFloat(tempDeliver)) &&
        sessionStorage.getItem("ownDelivery") !== "true"
      ) {
        document.querySelector(".deliverPrice").innerText =
          "+" + tempDeliver + " zł";
      } else {
        if (document.querySelector(".deliverPrice") !== null) {
          document.querySelector(".deliverPrice").innerText = "+0 zł";
        }
      }
    } else {
      document.querySelector(".deliverPrice").innerText = "+0 zł";
    }
    let self = this;
    setTimeout(function () {
      self.sumCart();
    }, 1);
  };
  vatOption = (e) => {
    let checked = e.target
      .closest(".check-box")
      .querySelector(".check-box__input").checked;
    let invoice = false;
    if (checked === false) {
      document.querySelector(".vatForm").classList.remove("d-none");
      invoice = true;
    } else {
      document.querySelector(".vatForm").classList.add("d-none");
      invoice = false;
    }
    this.setState({
      invoice: invoice,
    });
  };
  changePayment = (value) => {
    this.setState({
      payment: value,
    });
  };
  render() {
    return (
      <>
        <Navbar
          sum={this.props.sum}
          counter={this.props.counter}
          summary={this.props.summary}
        />
        <section className="order-summary">
          {this.state.isModalOpened && (
            <CustomModal
              heading="Komunikat"
              text={`Lokal ${sessionStorage.getItem(
                "lokal"
              )} jest zamknięty. Prosimy spróbować ponownie później.`}
              handleCloseModal={() => {
                this.setState({ isModalOpened: !this.state.isModalOpened });
              }}
            />
          )}
          <div className="order-summary__left-side">
            <div className="summary-shopping-cart">
              <h3 className="text__medium text__w500">Koszyk</h3>
              <div className="summary-shopping-cart__items">
                <div
                  id="wppizza-order-wrap-orderpage"
                  className="wppizza-order-wrap"
                >
                  <form
                    id="wppizza-send-order"
                    method="post"
                    accept-charset="UTF-8"
                    noValidate="novalidate"
                  >
                    <fieldset
                      id="wppizza-order-details"
                      className="wppizza-fieldset wppizza-order-details"
                    >
                      <legend>Zamówienie</legend>
                      <table className="wppizza-table wppizza-order-itemised">
                        <thead className="wppizza-table-header-group">
                          <tr>
                            <th className="wppizza-item-update-th">Il.</th>
                            <th className="wppizza-item-article-th">Wybrano</th>
                            <th className="wppizza-item-price-th">Cena</th>
                            <th className="wppizza-item-total-th">Suma</th>
                            <th className="wppizza-item-delete-th"></th>
                          </tr>
                        </thead>
                        <tbody className="wppizza-table-row-group">
                          {this.state.cart.map((data, index) => (
                            <tr
                              id="wppizza-order-item-1.53.452.2.2"
                              className="wppizza-item-row wppizza-item-row-first"
                            >
                              <img
                                className="imageSummary"
                                src={
                                  data.image !== undefined
                                    ? data.image
                                    : pizzaprom
                                }
                                alt="pizza"
                              />
                              <td
                                className={
                                  data.type === "twopizza"
                                    ? "wppizza-item-quantity wppizza-item-update d-hidden"
                                    : "wppizza-item-quantity wppizza-item-update"
                                }
                              >
                                <span className="ui-spinner ui-corner-all ui-widget ui-widget-content">
                                  <input
                                    type="text"
                                    size="1"
                                    id="wppizza-qkey-1.53.452.2.2"
                                    name="wppizza-cart-mod-1.53.452.2.2"
                                    className="wppizza-item-qupdate ui-spinner-input"
                                    value={data.counter}
                                    aria-valuemin="0"
                                    aria-valuenow="1"
                                    autoComplete="off"
                                    role="spinbutton"
                                  />
                                  <a
                                    tabIndex="-1"
                                    aria-hidden="true"
                                    className="ui-button ui-widget ui-spinner-button ui-spinner-up ui-corner-tr ui-button-icon-only"
                                    onClick={(e) =>
                                      this.updateCounterCard(e, index)
                                    }
                                    role="button"
                                  >
                                    <span className="ui-button-icon ui-icon ui-icon-triangle-1-n"></span>
                                    <span className="ui-button-icon-space">
                                      {" "}
                                    </span>
                                  </a>
                                  {data.counter > 1 ? (
                                    <a
                                      tabIndex="-1"
                                      aria-hidden="true"
                                      className="ui-button ui-widget ui-spinner-button ui-spinner-down ui-corner-br ui-button-icon-only"
                                      onClick={(e) =>
                                        this.updateCounterCard(e, index)
                                      }
                                      role="button"
                                    >
                                      <span className="ui-button-icon ui-icon ui-icon-triangle-1-s"></span>
                                      <span className="ui-button-icon-space">
                                        {" "}
                                      </span>
                                    </a>
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </td>
                              <td className="wppizza-item-article">
                                <span className="wppizza-item-title">
                                  <span>
                                    {data.counter > 1 ? `${data.counter}x` : ""}{" "}
                                    {data.name}
                                  </span>
                                  {data.info.map((data, index) => (
                                    <span>
                                      {data.counter === 0
                                        ? `BEZ ${data.name}`
                                        : ""}
                                      {data.type === "base" && data.counter > 1
                                        ? `${data.counter} x ${data.name}`
                                        : ""}
                                      {data.type === "cake"
                                        ? `ciasto ${data.name}`
                                        : ""}
                                      {data.type === "extend"
                                        ? `${data.counter} x EXTRA ${data.name}`
                                        : ""}
                                    </span>
                                  ))}
                                  {data.infoPromo &&
                                    data.infoPromo.map((data, index) => (
                                      <span>
                                        {data.type === "base"
                                          ? `pizza ${data.name}`
                                          : ""}
                                        {data.type === "extend"
                                          ? `${data.name}`
                                          : ""}
                                        {data.type === "cake"
                                          ? `ciasto ${data.name}`
                                          : ""}
                                      </span>
                                    ))}
                                </span>
                                <span
                                  className="wppizza-item-size"
                                  data-id={data.sizeId}
                                >
                                  {" "}
                                  {data.size}
                                </span>
                              </td>
                              <td className="wppizza-item-price">
                                {data.price}
                              </td>
                              <td className="wppizza-item-total">
                                {data.price}
                              </td>
                              <td className="wppizza-item-delete">
                                <img
                                  src={trash}
                                  onClick={(e) => this.removeFromCart(e, index)}
                                />
                              </td>
                            </tr>
                          ))}
                          {this.state.cart.length === 0 ? (
                            <span>Musisz dodać produkt do koszyka!</span>
                          ) : (
                            ""
                          )}
                        </tbody>
                      </table>
                      <table className="wppizza-table wppizza-order-summary">
                        <tbody className="wppizza-table-row-group">
                          <tr className="wppizza-total-items">
                            <td>Twoje zamówienie</td>
                            <td>{this.props.sum}</td>
                          </tr>
                          <tr className="wppizza-delivery">
                            <td>Darmowa dostawa</td>
                            <td></td>
                          </tr>
                          <tr className="wppizza_cad">
                            <td colSpan="2">
                              <div className="wppizza-wppizza_cad_coupons">
                                <label htmlFor="wppizza_cad_coupons">
                                  Masz kod rabatowy?{" "}
                                  <a
                                    href="javascript:void(0);"
                                    id="wppizza_cad_toggle"
                                    onClick={(e) => this.couponCard(e)}
                                  >
                                    Kliknij tutaj
                                  </a>
                                  <div id="wppizza_cad_coupons_wrap">
                                    <div
                                      className="input js-input"
                                      data-error="false"
                                    >
                                      <input
                                        className="input__input orderDiscount"
                                        type="text"
                                        id="discountCode"
                                        placeholder="Wpisz kod rabatowy"
                                      />
                                      <label
                                        className="input__label"
                                        htmlFor="discountCode"
                                      >
                                        Kod rabatowy
                                      </label>
                                      <span className="input__error"></span>
                                      <input
                                        id="wppizza_cad_coupons-btn"
                                        type="button"
                                        className="btn btn-secondary"
                                        value="Potwierdź"
                                        onClick={() => this.getCouponCard()}
                                      />
                                      <div
                                        id="wppizza_cad_coupons-results"
                                        className="wppizza-login-error"
                                      >
                                        Niepoprawny kod rabatowy
                                      </div>
                                    </div>
                                  </div>
                                </label>
                              </div>
                            </td>
                          </tr>
                          <tr className="wppizza-total">
                            <td>Do zapłaty</td>
                            <td>{this.props.sum}</td>
                          </tr>
                        </tbody>
                      </table>
                      <input
                        id="wppizza-cart-json"
                        type="hidden"
                        value='{"currency":{"iso":"PLN","symbol":"zł","decimals":2},"ordervars":{"payment_gateway":"CCOD","wp_user_id":"1","payment_method":"cod","pickup_delivery":"N"},"summary":{"total_price_items":{"total-items":128.7},"delivery_charges":{"delivery":0},"tax_total":{"tax-total":0},"total":{"total":128.7}},"cart":{"1":{"452":{"2":{"sizes":2,"qty":1,"cat":53,"prc":48.9,"prcttl":48.9}},"447":{"1":{"sizes":2,"qty":2,"cat":53,"prc":39.9,"prcttl":79.8}}}}}'
                      />
                      <div className="wppizza-delivery-note">
                        Otrzymasz email z czasem dostawy
                      </div>
                      <div
                        id="wppizza-orders-pickup-choice-1"
                        className="wppizza-orders-pickup-choice"
                      >
                        <label>
                          <input
                            type="checkbox"
                            className="wppizza-order-pickup"
                            name="wppizza-order-pickup"
                            value="1"
                          />
                          Odbiór osobisty
                        </label>
                      </div>
                    </fieldset>
                    <fieldset
                      id="wppizza-personal-details"
                      className="wppizza-fieldset wppizza-personal-details"
                    >
                      <legend>info</legend>
                      <div className="wppizza-cname">
                        <label htmlFor="cname">Imię:</label>
                        <input
                          id="cname"
                          name="cname"
                          type="text"
                          value=""
                          placeholder=""
                        />
                      </div>
                      <div className="wppizza-cemail">
                        <label
                          htmlFor="cemail"
                          className="wppizza-label-required"
                        >
                          Email :
                        </label>
                        <input
                          id="cemail"
                          name="cemail"
                          type="text"
                          value=""
                          placeholder=""
                          required="required"
                        />
                      </div>
                      <div className="wppizza-caddress">
                        <label
                          htmlFor="caddress"
                          className="wppizza-label-required"
                        >
                          Adres :
                        </label>
                        <textarea
                          id="caddress"
                          name="caddress"
                          placeholder=""
                          required="required"
                        ></textarea>
                      </div>
                      <div className="wppizza-ctel">
                        <label
                          htmlFor="ctel"
                          className="wppizza-label-required"
                        >
                          Telefon :
                        </label>
                        <input
                          id="ctel"
                          name="ctel"
                          type="text"
                          value=""
                          placeholder=""
                          required="required"
                        />
                      </div>
                      <div className="wppizza-ccomments">
                        <label
                          htmlFor="ccomments"
                          className="wppizza-label-required"
                        >
                          Komentarz :
                        </label>
                        <textarea
                          id="ccomments"
                          name="ccomments"
                          placeholder=""
                          required="required"
                        ></textarea>
                      </div>
                      <div className="wppizza-ccustom1">
                        <label
                          htmlFor="ccustom1"
                          className="wppizza-label-required"
                        >
                          Lokal:
                        </label>
                        <input
                          id="ccustom1"
                          name="ccustom1"
                          type="text"
                          value=""
                          placeholder=""
                          required="required"
                        />
                      </div>
                      <div className="wppizza-ccustom5">
                        <label htmlFor="ccustom5">
                          <input
                            id="ccustom5"
                            name="ccustom5"
                            type="checkbox"
                            value="1"
                          />{" "}
                          W przypadku DOSTAWY BEZKONTAKTOWEJ wybierz metodę
                          płatności online - PRZELEWY 24. Dostawca zadzwoni
                          dzwonkiem do drzwi i pozostawi zamówienie przy
                          wejściu.
                        </label>
                      </div>
                      <div className="wppizza-wppizza-dbp-area">
                        <label
                          htmlFor="wppizza-dbp-area"
                          className="wppizza-label-required"
                        >
                          WYBIERZ OSIEDLE DOSTAWY:
                        </label>
                        <select
                          id="wppizza-dbp-area"
                          name="wppizza-dbp-area"
                          required="required"
                        >
                          <option value="">--wybierz--</option>
                          <option value="odbior osobisty fat">
                            odbior osobisty fat
                          </option>
                          <option value="odbior osobisty nowydwor">
                            odbior osobisty nowydwor
                          </option>
                          <option value="odbior osobisty srodmiescie">
                            odbior osobisty srodmiescie
                          </option>
                          <option value="odbior osobisty tarnogaj">
                            odbior osobisty tarnogaj
                          </option>
                        </select>
                      </div>
                      <div className="wppizza_profile_update">
                        <label htmlFor="wppizza_profile_update">
                          <input
                            id="wppizza_profile_update"
                            name="wppizza_profile_update"
                            type="checkbox"
                            value="1"
                          />
                          zaktualizuj dane użytkownika ze szczegółami powyżej
                        </label>
                      </div>
                    </fieldset>
                    <fieldset
                      id="wppizza-payment-methods"
                      className="wppizza-fieldset wppizza-payment-methods"
                    >
                      <legend>Metoda płatności:</legend>
                      <div
                        id="wppizza-gateway-ccod"
                        className="wppizza-gateway-button button"
                      >
                        <label>
                          <input
                            type="radio"
                            id="wppizza_gateway_selected_ccod"
                            name="wppizza_gateway_selected"
                            value="CCOD"
                            checked="checked"
                          />
                          <span>Credit Card on Delivery</span>
                        </label>
                      </div>
                      <div
                        id="wppizza-gateway-cod"
                        className="wppizza-gateway-button button"
                      >
                        <label>
                          <input
                            type="radio"
                            id="wppizza_gateway_selected_cod"
                            name="wppizza_gateway_selected"
                            value="COD"
                          />
                          <span>Cash on Delivery</span>
                        </label>
                      </div>
                      <div
                        id="wppizza-gateway-payulatam"
                        className="wppizza-gateway-button button"
                      >
                        <label>
                          <input
                            type="radio"
                            id="wppizza_gateway_selected_payulatam"
                            name="wppizza_gateway_selected"
                            value="PAYULATAM"
                          />
                          <img
                            src="https://pizza-bunga.ultimate.systems/wp-content/plugins/wppizza-gateway-payu-latam/images/logo.png"
                            className="wppizza-gateway-img"
                            id="wppizza-gateway-img-PAYULATAM"
                          />
                          <span>PayU Latam</span>
                        </label>
                      </div>
                      <div
                        id="wppizza-gateway-przelewy24"
                        className="wppizza-gateway-button button"
                      >
                        <label>
                          <input
                            type="radio"
                            id="wppizza_gateway_selected_przelewy24"
                            name="wppizza_gateway_selected"
                            value="PRZELEWY24"
                          />
                          <img
                            src="https://pizza-bunga.ultimate.systems/wp-content/plugins/wppizza-gateway-przelewy24/images/logo.png"
                            className="wppizza-gateway-img"
                            id="wppizza-gateway-img-PRZELEWY24"
                          />
                          <span>Przelewy24</span>
                        </label>
                      </div>
                    </fieldset>
                    <div
                      role="alert"
                      id="wppizza-submit-error"
                      className="wppizza-validation-error"
                    ></div>
                    <input
                      id="wppizza-ordernow"
                      className="submit wppizza-ordernow"
                      type="submit"
                      value="WYŚLIJ ZAMÓWIENIE"
                    />
                    <input
                      type="hidden"
                      id="wppizza_nonce_checkout"
                      name="wppizza_nonce_checkout"
                      value="5f2b048887"
                    />
                    <input
                      type="hidden"
                      name="_wp_http_referer"
                      value="/podsumowanie-zamowienia/?nocache=1"
                    />
                  </form>
                </div>
              </div>
              <span className="summary-shopping-cart__break-line"></span>
              <div className="summary-shopping-cart__items"></div>
            </div>
            <form className="orderform" action="#">
              <div className="order-summary__contact-box">
                <h3 className="text__medium text__w500">Kontakt</h3>
                <div id="orderSummary" className="order-summary__input-group">
                  <div className="input js-input" data-error="false">
                    <input
                      className="input__input orderName"
                      type="text"
                      id="orderName"
                      placeholder="Imię*"
                    />
                    <label className="input__label" htmlFor="orderName">
                      Imię
                    </label>
                    <span className="input__error"></span>
                  </div>
                  <div className="input js-input" data-error="false">
                    <input
                      className="input__input orderEmail"
                      type="text"
                      id="orderEmail"
                      placeholder="E-mail*"
                    />
                    <label className="input__label" htmlFor="orderEmail">
                      E-mail
                    </label>
                    <span className="input__error"></span>
                  </div>
                  <div className="input js-input" data-error="false">
                    <NumberFormat
                      className="input__input orderPhone"
                      id="orderPhone"
                      pattern="\d*"
                      placeholder="Telefon*"
                      format="###-###-###"
                      mask="_"
                    />
                    <label className="input__label" htmlFor="orderPhone">
                      Telefon
                    </label>
                    <span className="input__error"></span>
                  </div>
                </div>
              </div>
              <div className="summary-deliver-box">
                <nav navbar="" className="summary-deliver-box__navbar">
                  <a
                    className="text__medium text__w500 switchDeliver summary-deliver-box__navbar-link--active"
                    onClick={(e) => this.switchDeliver(e)}
                  >
                    Dostawa
                  </a>
                  <a
                    className="text__medium text__w500 switchOwnDeliver summary-deliver-box__navbar-link"
                    onClick={(e) => this.switchOwnDeliver(e)}
                  >
                    Odbiór własny
                  </a>
                </nav>
                <div checkbox="" className="summary-deliver-box__option">
                  <input
                    className="check-box__input"
                    type="checkbox"
                    id="delivery"
                    name="delivery"
                  />
                  <label className="check-box" htmlFor="delivery"></label>
                  <label
                    id="orderDeliverLabel"
                    className="text__medium text__w300 d-none"
                    htmlFor="delivery"
                  >
                    dostawa bezkontaktowa z obowiązkiem przedpłaty
                  </label>
                </div>
                <div
                  id="formOrderSummary"
                  className="form-grid form-grid--order-summary"
                >
                  <div className="input js-input d-none" data-error="false">
                    <input
                      className="input__input orderCity"
                      id="orderCity"
                      type="text"
                      placeholder="Miejscowość"
                    />
                    <label className="input__label" htmlFor="orderCity">
                      Miejscowość
                    </label>
                    <span className="input__error"></span>
                  </div>
                  <div className="input js-input d-none" data-error="false">
                    <input
                      className="input__input orderPostCode"
                      id="orderPostCode"
                      type="text"
                      placeholder="Kod pocztowy"
                    />
                    <label className="input__label" htmlFor="orderPostCode">
                      Kod pocztowy
                    </label>
                    <span className="input__error"></span>
                  </div>
                  <div
                    col-full=""
                    className="input js-input"
                    data-error="false"
                  >
                    <input
                      className="input__input orderStreet pac-target-input"
                      id="orderStreet"
                      type="text"
                      placeholder="Ulica"
                      autoComplete="off"
                    />
                    <label className="input__label" htmlFor="orderStreet">
                      Ulica
                    </label>
                    <span className="input__error"></span>
                  </div>
                  {/*<div className="input js-input" data-error="false">*/}
                  {/*  <input*/}
                  {/*    className="input__input orderNumberBuilding"*/}
                  {/*    id="orderNumberBuilding"*/}
                  {/*    type="text"*/}
                  {/*    placeholder="Nr budynku"*/}
                  {/*    pattern="\d*"*/}
                  {/*    // value={sessionStorage.getItem("streetNumber")}*/}
                  {/*  />*/}
                  {/*  <label*/}
                  {/*    className="input__label"*/}
                  {/*    htmlFor="orderNumberBuilding"*/}
                  {/*  >*/}
                  {/*    Nr budynku*/}
                  {/*  </label>*/}
                  {/*  <span className="input__error"></span>*/}
                  {/*</div>*/}
                  <div className="input js-input" data-error="false" col-full="">
                    <input
                      className="input__input orderNumberFlat"
                      id="orderNumberFlat"
                      type="text"
                      placeholder="Nr mieszkania"
                    />
                    <label className="input__label" htmlFor="orderNumberFlat">
                      Nr mieszkania
                    </label>
                    <span className="input__error"></span>
                  </div>
                  <div
                    col-full=""
                    className="input js-input"
                    data-error="false"
                  >
                    <textarea
                      className="input__input orderComment"
                      id="orderComment"
                      type="text"
                      rows="4"
                      placeholder="Komentarz"
                    ></textarea>
                    <label className="input__label" htmlFor="orderComment">
                      Komentarz
                    </label>
                    <span className="input__error"></span>
                  </div>
                </div>
                <span
                  info=""
                  className="summary-deliver-box__info text__small text__w300"
                >
                  Dokonanie zmiany adresu dostawy wiąże się z dostępnością
                  niektórych produktów w wybranym lokalu.
                </span>
                <div checkbox="" className="summary-deliver-box__option">
                  <div className="check-box">
                    <input
                      className="check-box__input"
                      type="checkbox"
                      name=""
                      id="vat"
                    />
                    <label
                      className="check-box__label vatOption"
                      htmlFor="vat"
                      onClick={(e) => this.vatOption(e)}
                    >
                      <Checkbox />
                    </label>
                    <label
                      className="check-box__label-text vatOption"
                      htmlFor="vat"
                      onClick={(e) => this.vatOption(e)}
                    >
                      Faktura vat
                    </label>
                  </div>
                </div>
                <div className="form-grid form-grid--order-summary vatForm d-none">
                  <div
                    col-full=""
                    className="input js-input"
                    data-error="false"
                  >
                    <input
                      id="vatName"
                      className="input__input"
                      type="text"
                      placeholder="Nazwa firmy"
                    />
                    <label className="input__label" htmlFor="vatName">
                      Nazwa firmy
                    </label>
                    <span className="input__error"></span>
                  </div>
                  <div
                    col-full=""
                    className="input js-input"
                    data-error="false"
                  >
                    <input
                      id="vatNip"
                      className="input__input"
                      type="text"
                      placeholder="NIP"
                    />
                    <label className="input__label" htmlFor="vatNip">
                      NIP
                    </label>
                    <span className="input__error"></span>
                  </div>
                  <div
                    col-full=""
                    className="input js-input"
                    data-error="false"
                  >
                    <input
                      id="vatStreet"
                      className="input__input"
                      type="text"
                      placeholder="Ulica"
                    />
                    <label className="input__label" htmlFor="vatStreet">
                      Ulica
                    </label>
                    <span className="input__error"></span>
                  </div>
                  <div className="input js-input" data-error="false">
                    <input
                      id="vatBuilding"
                      className="input__input"
                      type="text"
                      placeholder="Nr budynku"
                      pattern="\d*"
                    />
                    <label className="input__label" htmlFor="vatBuilding">
                      Nr budynku
                    </label>
                    <span className="input__error"></span>
                  </div>
                  <div className="input js-input" data-error="false">
                    <input
                      id="vatFlat"
                      className="input__input"
                      type="text"
                      placeholder="Nr mieszkania"
                    />
                    <label className="input__label" htmlFor="vatFlat">
                      Nr mieszkania
                    </label>
                    <span className="input__error"></span>
                  </div>
                  <div className="input js-input" data-error="false">
                    <input
                      id="vatCity"
                      className="input__input"
                      type="text"
                      placeholder="Miejscowość"
                    />
                    <label className="input__label" htmlFor="vatCity">
                      Miejscowość
                    </label>
                    <span className="input__error"></span>
                  </div>
                  <div className="input js-input" data-error="false">
                    <input
                      id="vatZipCode"
                      className="input__input"
                      type="text"
                      placeholder="Kod pocztowy"
                    />
                    <label className="input__label" htmlFor="vatZipCode">
                      Kod pocztowy
                    </label>
                    <span className="input__error"></span>
                  </div>
                </div>
                <div className="ownDeliveryOption">
                  <span
                    additional=""
                    className="summary-deliver-box__add-info text__medium text__w500"
                  >
                    Dodatkowe informacje
                  </span>
                  <div checkbox="" className="summary-deliver-box__option">
                    <div className="check-box">
                      <input
                        className="check-box__input"
                        type="checkbox"
                        name=""
                        id="touchless-delivery"
                      />
                      <label
                        className="check-box__label"
                        htmlFor="touchless-delivery"
                      >
                        <Checkbox />
                      </label>
                      <label
                        className="check-box__label-text"
                        htmlFor="touchless-delivery"
                      >
                        dostawa bezkontaktowa z obowiązkiem przedpłaty
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div id="summaryPayment" className="summary-payment-box">
                <h3 className="text__medium text__w500">
                  Metoda płatności* <span className="error"></span>
                </h3>
                <div className="summary-payment-box__options">
                  <div className="summary-payment-box__option">
                    <div className="check-box">
                      <input
                        className="check-box__input"
                        type="radio"
                        name="cash"
                        id="cash"
                        onChange={() => this.changePayment("ON_DELIVERY")}
                      />
                      <label className="check-box__label" htmlFor="cash">
                        <Checkbox />
                      </label>
                      <label className="check-box__label-text" htmlFor="cash">
                        gotówka
                      </label>
                    </div>
                  </div>
                  <div className="summary-payment-box__option">
                    <div className="check-box">
                      <input
                        className="check-box__input"
                        type="radio"
                        name="cash"
                        id="card"
                        onChange={() => this.changePayment("PRZELEWY")}
                      />
                      <label className="check-box__label" htmlFor="card">
                        <Checkbox />
                      </label>
                      <label className="check-box__label-text" htmlFor="card">
                        Przelewy24
                      </label>
                    </div>
                  </div>
                  <div className="summary-payment-box__option">
                    <div className="check-box">
                      <input
                        className="check-box__input"
                        type="radio"
                        name="cash"
                        id="icard"
                        onChange={() => this.changePayment("ON_DELIVERY")}
                      />
                      <label className="check-box__label" htmlFor="icard">
                        <Checkbox />
                      </label>
                      <label className="check-box__label-text" htmlFor="icard">
                        karta
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div id="acceptAll" className="order-summary__accept-terms">
                <div className="check-box">
                  <input
                    className="check-box__input"
                    type="checkbox"
                    name="acceptAll"
                    id="accept-all"
                    onClick={() => this.acceptAll()}
                  />
                  <label className="check-box__label" htmlFor="accept-all">
                    <Checkbox />
                  </label>
                  <label className="check-box__label-text" htmlFor="accept-all">
                    Akceptuję wszystkie zgody
                  </label>
                </div>
                <div id="acceptTerms" className="order-summary__accept-terms">
                  <div className="text__medium text__w500">
                    <span className="error"></span>
                  </div>
                  <div className="check-box">
                    <input
                      className="check-box__input"
                      type="checkbox"
                      name="accept"
                      id="accept-terms"
                    />
                    <label className="check-box__label" htmlFor="accept-terms">
                      <Checkbox />
                    </label>
                    <label
                      className="check-box__label-text"
                      htmlFor="accept-terms"
                    >
                      *Zapoznałem/łam się z{" "}
                      <a
                        href={sessionStorage.getItem('lokalID') === '7770101' ? "https://pizzabunga.pl/Regulamin%20Poelska.pdf" : "https://pizzabunga.pl/Kraft_Polska_Regulamin_19.04.2021.docx.pdf"}
                        target="_blank"
                        rel="noreferrer"
                      >
                        regulaminem
                      </a>
                    </label>
                  </div>
                </div>
                <div id="acceptPolicy" className="order-summary__accept-terms">
                  <div className="text__medium text__w500">
                    <span className="error"></span>
                  </div>
                  <div className="check-box">
                    <input
                      className="check-box__input"
                      type="checkbox"
                      name="acceptPolicy"
                      id="accept-policy"
                    />
                    <label className="check-box__label" htmlFor="accept-policy">
                      <Checkbox />
                    </label>
                    <label
                      className="check-box__label-text"
                      htmlFor="accept-policy"
                    >
                      *Administratorem Twoich danych osobowych jest {sessionStorage.getItem('lokalID') === '7770101' ? 'M&M GASTRO Michał Bartczyszyn Chopina 24/2, kod pocztowy 48-340, NIP: 7532434659,' : 'Kraft Polska spółka z ograniczoną odpowiedzialnością z siedzibą we Wrocławiu przy ul. Rogowskiej 7, kod pocztowy 54 – 400, NIP: 8942996311, o kapitale zakładowym w wysokości 100&nbsp;000 zł której akta rejestrowe są prowadzone przez Sąd Rejonowy dla Wrocławia – Fabrycznej we Wrocławiu, VI Wydział Gospodarczy pod numerem KRS: 0000353723.'} Więcej informacji
                      na temat przetwarzania danych osobowych znajdziesz w
                      naszej{" "}
                      <a
                        href={sessionStorage.getItem('lokalID') === '7770101' ? "https://pizzabunga.pl/polityka-prywatnosci/?poleska=true" : "https://pizzabunga.pl/polityka-prywatnosci/"}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Polityce Prywatności
                      </a>{" "}
                      .
                    </label>
                  </div>
                </div>
                <div id="acceptLaw" className="order-summary__accept-terms">
                  <div className="text__medium text__w500">
                    <span className="error"></span>
                  </div>
                  <div className="check-box">
                    <input
                      className="check-box__input"
                      type="checkbox"
                      name="acceptLaw"
                      id="accept-law"
                    />
                    <label className="check-box__label" htmlFor="accept-law">
                      <Checkbox />
                    </label>
                    <label
                      className="check-box__label-text"
                      htmlFor="accept-law"
                    >
                      *Oświadczam, iż jestem świadomy, że tracę prawo do
                      odstąpienia od umowy na podstawie art. 38 pkt 4 ustawy z
                      dnia 30 maja 2014 roku o prawach konsumenta (Dz. U. 2014
                      poz. 827)
                    </label>
                  </div>
                </div>
              </div>
            </form>
            <a
              href="/potwierdzenie-zamowienia/"
              btn=""
              className="btn sendOrderForm"
            >
              Złóż zamówienie
            </a>
          </div>
          <div className="order-summary__right-side">
            <div className="summary-result-box sticky">
              <h3 header="" className="text__medium text__w500">
                Podsumowanie
              </h3>
              <div className="summary-result-box__items">
                <div
                  id="wppizza-order-wrap-orderpage"
                  className="wppizza-order-wrap"
                >
                  <form
                    id="wppizza-send-order"
                    method="post"
                    accept-charset="UTF-8"
                  >
                    <fieldset
                      id="wppizza-order-details"
                      className="wppizza-fieldset wppizza-order-details"
                    >
                      <legend>Zamówienie</legend>
                      <table className="wppizza-table wppizza-order-itemised">
                        <thead className="wppizza-table-header-group">
                          <tr>
                            <th className="wppizza-item-update-th">Il.</th>
                            <th className="wppizza-item-article-th">Wybrano</th>
                            <th className="wppizza-item-price-th">Cena</th>
                            <th className="wppizza-item-total-th">Suma</th>
                            <th className="wppizza-item-delete-th"></th>
                          </tr>
                        </thead>
                        <tbody className="wppizza-table-row-group">
                          {this.state.cart.map((data, index) => (
                            <tr
                              id="wppizza-order-item-1.53.452.2.2"
                              className="wppizza-item-row wppizza-item-row-first"
                            >
                              <td className="wppizza-item-update">
                                <span className="ui-spinner ui-corner-all ui-widget ui-widget-content">
                                  <input
                                    type="text"
                                    size="1"
                                    id="wppizza-qkey-1.53.452.2.2"
                                    name="wppizza-cart-mod-1.53.452.2.2"
                                    className="wppizza-item-qupdate ui-spinner-input"
                                    value={data.counter}
                                    aria-valuemin="0"
                                    aria-valuenow="1"
                                    autoComplete="off"
                                    role="spinbutton"
                                  />
                                  <a
                                    tabIndex="-1"
                                    aria-hidden="true"
                                    className="ui-button ui-widget ui-spinner-button ui-spinner-up ui-corner-tr ui-button-icon-only"
                                    role="button"
                                  >
                                    <span className="ui-button-icon ui-icon ui-icon-triangle-1-n"></span>
                                    <span className="ui-button-icon-space">
                                      {" "}
                                    </span>
                                  </a>
                                  <a
                                    tabIndex="-1"
                                    aria-hidden="true"
                                    className="ui-button ui-widget ui-spinner-button ui-spinner-down ui-corner-br ui-button-icon-only"
                                    role="button"
                                  >
                                    <span className="ui-button-icon ui-icon ui-icon-triangle-1-s"></span>
                                    <span className="ui-button-icon-space">
                                      {" "}
                                    </span>
                                  </a>
                                </span>
                              </td>
                              <td className="wppizza-item-article">
                                <span className="wppizza-item-title">
                                  {data.counter > 1 ? `${data.counter} x` : ""}{" "}
                                  {data.name}
                                </span>
                                <span
                                  className="wppizza-item-size"
                                  data-id={data.id}
                                >
                                  {" "}
                                  {data.size}
                                </span>
                              </td>
                              <td className="wppizza-item-price">
                                {data.price}
                              </td>
                              <td className="wppizza-item-total">
                                {data.price}
                              </td>
                              <td className="wppizza-item-delete">
                                <input
                                  type="button"
                                  id="wppizza-cart-1.53.452.2.2"
                                  className="wppizza-delete-from-cart"
                                  title="Wyczyść koszyk"
                                  value="x"
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <table className="wppizza-table wppizza-order-summary">
                        <tbody className="wppizza-table-row-group">
                          <tr className="wppizza-total-items">
                            <td>Twoje zamówienie</td>
                            <td>{this.props.sum}</td>
                          </tr>
                          {this.state.discountType == "PERCENT" ||
                          this.state.discountType == "VALUE" ? (
                            <tr className="wppizza-delivery">
                              <td>Zniżka</td>
                              <td>
                                -{" "}
                                {this.state.discountRealValue.replace(".", ",")}{" "}
                                zł
                              </td>
                            </tr>
                          ) : (
                            ""
                          )}
                          {!isNaN(
                            parseFloat(sessionStorage.getItem("codeDelivery"))
                          ) &&
                          sessionStorage.getItem("codeDelivery") !== "0" ? (
                            <tr className="wppizza-delivery">
                              <td>Koszt dostawy</td>
                              <td className="deliverPrice">
                                {sessionStorage.getItem("codeDelivery")} zł
                              </td>
                            </tr>
                          ) : (
                            <tr className="wppizza-delivery">
                              <td>Darmowa dostawa</td>
                              <td></td>
                            </tr>
                          )}
                          {/*<tr className="wppizza_cad">*/}
                          {/*    <td colSpan="2">*/}
                          {/*        <div className="wppizza-wppizza_cad_coupons"><label*/}
                          {/*            htmlFor="wppizza_cad_coupons">Masz kod rabotowy? <a*/}
                          {/*            href="javascript:void(0);" id="wppizza_cad_toggle" onClick={(e) => this.couponCard(e)}>Kliknij*/}
                          {/*            tutaj</a>*/}
                          {/*            <div id="wppizza_cad_coupons_wrap"><input*/}
                          {/*                id="wppizza_cad_coupons" name="wppizza_cad_coupons"*/}
                          {/*                type="text" value=""*/}
                          {/*                placeholder="Wpisz kod rabatowy" /><input*/}
                          {/*                id="wppizza_cad_coupons-btn" type="button"*/}
                          {/*                className="btn btn-secondary" value="Potwierdź" />*/}
                          {/*                <div id="wppizza_cad_coupons-results"*/}
                          {/*                     className="wppizza-login-error"></div></div>*/}
                          {/*        </label></div>*/}
                          {/*    </td>*/}
                          {/*</tr>*/}
                          <tr className="wppizza-total">
                            <td>Do zapłaty</td>
                            <td>
                              {this.state.discountType == "PERCENT" ||
                              this.state.discountType == "VALUE"
                                ? parseFloat(this.state.discountSum)
                                    .toFixed(2)
                                    .replace(".", ",") + " zł"
                                : this.props.sum}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <input
                        id="wppizza-cart-json"
                        type="hidden"
                        value='{"currency":{"iso":"PLN","symbol":"zł","decimals":2},"ordervars":{"payment_gateway":"CCOD","wp_user_id":"1","payment_method":"cod","pickup_delivery":"N"},"summary":{"total_price_items":{"total-items":128.7},"delivery_charges":{"delivery":0},"tax_total":{"tax-total":0},"total":{"total":128.7}},"cart":{"1":{"452":{"2":{"sizes":2,"qty":1,"cat":53,"prc":48.9,"prcttl":48.9}},"447":{"1":{"sizes":2,"qty":2,"cat":53,"prc":39.9,"prcttl":79.8}}}}}'
                      />
                      <div className="wppizza-delivery-note">
                        Otrzymasz email z czasem dostawy
                      </div>
                      <div
                        id="wppizza-orders-pickup-choice-2"
                        className="wppizza-orders-pickup-choice"
                      >
                        <label>
                          <input
                            type="checkbox"
                            className="wppizza-order-pickup"
                            name="wppizza-order-pickup"
                            value="1"
                          />
                          Odbiór osobisty
                        </label>
                      </div>
                    </fieldset>
                    <fieldset
                      id="wppizza-personal-details"
                      className="wppizza-fieldset wppizza-personal-details"
                    >
                      <legend>info</legend>
                      <div className="wppizza-cname">
                        <label htmlFor="cname">Imię:</label>
                        <input
                          id="cname"
                          name="cname"
                          type="text"
                          value=""
                          placeholder=""
                        />
                      </div>
                      <div className="wppizza-cemail">
                        <label
                          htmlFor="cemail"
                          className="wppizza-label-required"
                        >
                          Email :
                        </label>
                        <input
                          id="cemail"
                          name="cemail"
                          type="text"
                          value=""
                          placeholder=""
                          required="required"
                        />
                      </div>
                      <div className="wppizza-caddress">
                        <label
                          htmlFor="caddress"
                          className="wppizza-label-required"
                        >
                          Adres :
                        </label>
                        <textarea
                          id="caddress"
                          name="caddress"
                          placeholder=""
                          required="required"
                        ></textarea>
                      </div>
                      <div className="wppizza-ctel">
                        <label
                          htmlFor="ctel"
                          className="wppizza-label-required"
                        >
                          Telefon :
                        </label>
                        <input
                          id="ctel"
                          name="ctel"
                          type="text"
                          value=""
                          placeholder=""
                          required="required"
                        />
                      </div>
                      <div className="wppizza-ccomments">
                        <label
                          htmlFor="ccomments"
                          className="wppizza-label-required"
                        >
                          Komentarz :
                        </label>
                        <textarea
                          id="ccomments"
                          name="ccomments"
                          placeholder=""
                          required="required"
                        ></textarea>
                      </div>
                      <div className="wppizza-ccustom1">
                        <label
                          htmlFor="ccustom1"
                          className="wppizza-label-required"
                        >
                          Lokal:
                        </label>
                        <input
                          id="ccustom1"
                          name="ccustom1"
                          type="text"
                          value=""
                          placeholder=""
                          required="required"
                        />
                      </div>
                      <div className="wppizza-ccustom5">
                        <label htmlFor="ccustom5">
                          <input
                            id="ccustom5"
                            name="ccustom5"
                            type="checkbox"
                            value="1"
                          />{" "}
                          W przypadku DOSTAWY BEZKONTAKTOWEJ wybierz metodę
                          płatności online - PRZELEWY 24. Dostawca zadzwoni
                          dzwonkiem do drzwi i pozostawi zamówienie przy
                          wejściu.
                        </label>
                      </div>
                      <div className="wppizza-wppizza-dbp-area">
                        <label
                          htmlFor="wppizza-dbp-area"
                          className="wppizza-label-required"
                        >
                          WYBIERZ OSIEDLE DOSTAWY:
                        </label>
                        <select
                          id="wppizza-dbp-area"
                          name="wppizza-dbp-area"
                          required="required"
                        >
                          <option value="">--wybierz--</option>
                          <option value="odbior osobisty fat">
                            odbior osobisty fat
                          </option>
                          <option value="odbior osobisty nowydwor">
                            odbior osobisty nowydwor
                          </option>
                          <option value="odbior osobisty srodmiescie">
                            odbior osobisty srodmiescie
                          </option>
                          <option value="odbior osobisty tarnogaj">
                            odbior osobisty tarnogaj
                          </option>
                        </select>
                      </div>
                      <div className="wppizza_profile_update">
                        <label htmlFor="wppizza_profile_update">
                          <input
                            id="wppizza_profile_update"
                            name="wppizza_profile_update"
                            type="checkbox"
                            value="1"
                          />
                          zaktualizuj dane użytkownika ze szczegółami powyżej
                        </label>
                      </div>
                    </fieldset>
                    <fieldset
                      id="wppizza-payment-methods"
                      className="wppizza-fieldset wppizza-payment-methods"
                    >
                      <legend>Metoda płatności:</legend>
                      <div
                        id="wppizza-gateway-ccod"
                        className="wppizza-gateway-button button"
                      >
                        <label>
                          <input
                            type="radio"
                            id="wppizza_gateway_selected_ccod"
                            name="wppizza_gateway_selected"
                            value="CCOD"
                            checked="checked"
                          />
                          <span>Credit Card on Delivery</span>
                        </label>
                      </div>
                      <div
                        id="wppizza-gateway-cod"
                        className="wppizza-gateway-button button"
                      >
                        <label>
                          <input
                            type="radio"
                            id="wppizza_gateway_selected_cod"
                            name="wppizza_gateway_selected"
                            value="COD"
                          />
                          <span>Cash on Delivery</span>
                        </label>
                      </div>
                      <div
                        id="wppizza-gateway-payulatam"
                        className="wppizza-gateway-button button"
                      >
                        <label>
                          <input
                            type="radio"
                            id="wppizza_gateway_selected_payulatam"
                            name="wppizza_gateway_selected"
                            value="PAYULATAM"
                          />
                          <img
                            src="https://pizza-bunga.ultimate.systems/wp-content/plugins/wppizza-gateway-payu-latam/images/logo.png"
                            className="wppizza-gateway-img"
                            id="wppizza-gateway-img-PAYULATAM"
                          />
                          <span>PayU Latam</span>
                        </label>
                      </div>
                      <div
                        id="wppizza-gateway-przelewy24"
                        className="wppizza-gateway-button button"
                      >
                        <label>
                          <input
                            type="radio"
                            id="wppizza_gateway_selected_przelewy24"
                            name="wppizza_gateway_selected"
                            value="PRZELEWY24"
                          />
                          <img
                            src="https://pizza-bunga.ultimate.systems/wp-content/plugins/wppizza-gateway-przelewy24/images/logo.png"
                            className="wppizza-gateway-img"
                            id="wppizza-gateway-img-PRZELEWY24"
                          />
                          <span>Przelewy24</span>
                        </label>
                      </div>
                    </fieldset>
                    <div
                      role="alert"
                      id="wppizza-submit-error"
                      className="wppizza-validation-error"
                    ></div>
                    <input
                      id="wppizza-ordernow"
                      className="submit wppizza-ordernow"
                      type="submit"
                      value="WYŚLIJ ZAMÓWIENIE"
                    />
                    <input
                      type="hidden"
                      id="wppizza_nonce_checkout"
                      name="wppizza_nonce_checkout"
                      value="5f2b048887"
                    />
                    <input
                      type="hidden"
                      name="_wp_http_referer"
                      value="/podsumowanie-zamowienia/?nocache=1"
                    />
                  </form>
                </div>
              </div>
              <div className="summary-result-box__item mrg-top-40 mrg-bottom-30 d-none">
                <div className="summary-result-box__item-left">
                  <div className="summary-result-box__item-left-info">
                    <span className="text__medium text__w500 mrg-bottom-8">
                      Dostawa
                    </span>
                    <span className="text__small text__w300"></span>
                  </div>
                </div>
                <div className="summary-result-box__item-right">
                  <span className="text__medium text__w500 deliverOrder">
                    +0 zł
                  </span>
                </div>
              </div>
              <div className="summary-result-box__item d-none">
                <div className="summary-result-box__item-left">
                  <div className="summary-result-box__item-left-info">
                    <span className="text__medium text__w500 mrg-bottom-8">
                      Metoda płatności
                    </span>
                    <span className="text__small text__w300 paymentMethodSummary">
                      gotówka
                    </span>
                  </div>
                </div>
              </div>
              <div className="summary-result-box__discount-box mrg-top-40 mrg-bottom-40 d-none">
                <div
                  className="input js-input"
                  data-type="post-code"
                  data-error="false"
                >
                  <input
                    className="input__input"
                    type="text"
                    placeholder="kod rabatowy"
                  />
                  <label className="input__label" htmlFor="">
                    kod rabatowy
                  </label>
                  <span className="input__error">Błędny kod</span>
                  <button className="btn summary-result-box__apply-discount-btn">
                    Potwierdź
                  </button>
                </div>
              </div>
              <div className="summary-result-box__break-line d-none"></div>
              <div className="summary-result-box__item d-none">
                <span className="text__medium text__w500">Do zapłaty</span>
                <span className="text__medium text__w500 summaryPrice"></span>
              </div>
              <div btn="" className="btn sendOrderForms">
                Złóż zamówienie
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}

export default Summary;
