const CustomModal = ({ heading, text, handleCloseModal }) => {
    return (
        <div className="modal js-modal open-modal">
            <div className="modal__content">
                <div className="modal__info">
                    <h1 className="text__medium--2 text__w600 mrg-bottom-30">{heading}</h1>
                    <h2 className="text__medium text__w300">{text}</h2>
                </div>
                <button type="button" onClick={handleCloseModal} className="btn  btn--normal js-modal-btn">Ok</button>
            </div>
        </div>
    )
}


export default CustomModal;