import React, {Component} from "react";

class Checkbox extends Component {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="16pt" height="16pt" viewBox="0 0 16 16" version="1.1">
                <g id="surface1">
                    <path stroke="none" fillRule="nonzero" fill="#FE3838" fillOpacity="1" d="M 1.335938 0.0507812 C 0.710938 0.21875 0.203125 0.730469 0.046875 1.355469 C 0.0078125 1.515625 0 2.355469 0.0078125 8.175781 L 0.015625 14.8125 L 0.09375 15.003906 C 0.300781 15.523438 0.691406 15.910156 1.203125 16.097656 L 1.40625 16.175781 L 14.785156 16.175781 L 14.988281 16.097656 C 15.507812 15.90625 15.90625 15.507812 16.097656 14.988281 L 16.175781 14.785156 L 16.175781 1.40625 L 16.097656 1.203125 C 15.910156 0.691406 15.523438 0.300781 15.003906 0.09375 L 14.8125 0.015625 L 8.160156 0.0117188 C 2.46875 0.00390625 1.476562 0.0117188 1.335938 0.0507812 Z M 13.417969 3.164062 L 13.949219 4.050781 L 13.070312 4.925781 C 10.976562 7.011719 9.734375 8.675781 8.742188 10.730469 C 8.453125 11.332031 8.269531 11.765625 7.765625 13.058594 C 7.578125 13.539062 7.410156 13.945312 7.398438 13.953125 C 7.386719 13.96875 7.121094 13.671875 6.8125 13.292969 C 5.617188 11.828125 5.117188 11.308594 3.890625 10.234375 C 3.554688 9.941406 3.082031 9.523438 2.847656 9.316406 C 2.613281 9.105469 2.414062 8.933594 2.410156 8.929688 C 2.390625 8.921875 3.554688 6.925781 3.570312 6.925781 C 3.597656 6.925781 4.558594 7.769531 5.855469 8.941406 C 6.285156 9.332031 6.648438 9.644531 6.664062 9.644531 C 6.679688 9.644531 6.730469 9.5625 6.777344 9.460938 C 6.921875 9.175781 7.382812 8.398438 7.695312 7.9375 C 8.414062 6.871094 9.101562 6.054688 10.546875 4.542969 C 11.109375 3.949219 12.828125 2.277344 12.871094 2.277344 C 12.878906 2.277344 13.125 2.675781 13.417969 3.164062 Z M 13.417969 3.164062 "/>
                </g>
            </svg>

        )
    }
}

export default Checkbox;