import React, {Component} from "react";
import './_sidebar.scss';
import {Link} from "react-router-dom";

class Sidebar extends Component {
    close = () => {
        const sidebar = document.querySelector('.js-sidebar');
        const navbar = document.querySelector('.js-navbar');
        const bluredBackground = document.querySelector('.blured-background');
        const isOpen = sidebar.dataset.show || 'false'
        sidebar.dataset.show = isOpen === 'false' ? 'true' : 'false'
        navbar.style.display = isOpen === 'true' ? 'flex' : 'none'
        bluredBackground.dataset.blured = isOpen === 'true' ? 'false' : 'true'
    }

    render() {
        return (
            <nav className="sidebar js-sidebar" data-show="false">
                <div className="sidebar__main js-sidebar-content" data-login="false">
                    <div menu="" className="sidebar__menu">
                        <Link to={sessionStorage.getItem('lokalUrl') ? sessionStorage.getItem('lokalUrl'): "/"}
                              className={sessionStorage.getItem('lokal') ? "text__medium--2 text__w600  text__white" : "text__medium--2 text__w600  text__white d-none"} onClick={() => this.close()}>Nasze menu</Link>
                        <Link to="/lokale/" className="text__medium--2 text__w600  text__white" onClick={() => this.close()}>Lokale</Link>
                        <Link to="/o-nas/" className="text__medium--2 text__w600 text__white" onClick={() => this.close()}>O nas</Link>
                        <Link to="/praca/" className="text__medium--2 text__w600 text__white" onClick={() => this.close()}>Praca</Link>
                        <Link to="/franczyza/" className="text__medium--2 text__w600 text__white" onClick={() => this.close()}>Franczyza</Link>
                        <Link to="/kontakt/" className="text__medium--2 text__w600 text__white" onClick={() => this.close()}>Kontakt</Link>
                    </div>

                    <div>
                        <div className="sidebar__break"></div>
                        <a href="https://pl-pl.facebook.com/pizzabunga">
                            <div className="sidebar__icon-box">
                            <span className="">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M23.9981 11.9991C23.9981 5.37216 18.626 0 11.9991 0C5.37216 0 0 5.37216 0 11.9991C0 17.9882 4.38789 22.9522 10.1242 23.8524V15.4676H7.07758V11.9991H10.1242V9.35553C10.1242 6.34826 11.9156 4.68714 14.6564 4.68714C15.9692 4.68714 17.3424 4.92149 17.3424 4.92149V7.87439H15.8294C14.3388 7.87439 13.8739 8.79933 13.8739 9.74824V11.9991H17.2018L16.6698 15.4676H13.8739V23.8524C19.6103 22.9522 23.9981 17.9882 23.9981 11.9991Z"
                                        fill="white"></path>
                                </svg>
                            </span>
                                <button className="btn btn--dark js-sidebar-login d-none">Zaloguj się</button>
                            </div>
                        </a>
                        <div className="sidebar__break"></div>
                    </div>

                    <div footer="" className="sidebar__menu">
                        <Link to={sessionStorage.getItem('lokalID') === '7770101' ? "/polityka-prywatnosci/?poleska=true" : "/polityka-prywatnosci/"}
                              className="text__small text__w500 text__white" onClick={() => this.close()}>Polityka prywatności</Link>
                        <a href={sessionStorage.getItem('lokalID') === '7770101' ? "https://pizzabunga.pl/Regulamin%20Poelska.pdf" : "https://pizzabunga.pl/Kraft_Polska_Regulamin_19.04.2021.docx.pdf"}
                           className="text__small text__w500 text__white">Regulamin zakupów</a>
                        <a href="/"
                           className="text__small text__w500 text__white d-none">Zgody marketingowe</a>
                    </div>

                    <div className="sidebar__break"></div>
                    <div className={sessionStorage.getItem('lokal') ? "sidebar__shopping-box" : "d-none"}>
                        <div box="">
                            <div>
                                <h3 className="text__small text__w500 text__white">TWÓJ KOSZYK</h3>
                                <span className="text__small text__w500 text__white mobileSumCart"></span>
                            </div>
                            <Link to="/koszyk/" className="btn btn--dark sidebarCartLink" onClick={() => this.close()}>Zobacz</Link>
                        </div>
                    </div>
                </div>
                <div hamburger="" className="hamburger js-hamburger hamburger--sidebar" onClick={() => this.close()}>
                    <div className="hamburger__stick hamburger__stick--sidebar hamburger__stick--sidebar-top"></div>
                    <div className="hamburger__stick hamburger__stick--sidebar hamburger__stick--sidebar-bottom"></div>
                </div>
            </nav>
        )
    }
}

export default Sidebar;