import React from "react";
import './_load-container.scss';
import './_itemsLoader.scss';
import './_icon.scss';

function Loader() {
    return (
        <div className="load-container d-none">
            <div className="spinner">
                <div className="rect1"></div>
                <div className="rect2"></div>
                <div className="rect3"></div>
                <div className="rect4"></div>
                <div className="rect5"></div>
            </div>
        </div>
    )
}

export default Loader;