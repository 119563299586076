import React, { Component } from "react";

class Modal extends Component {
  closeConfirmModal = () => {
    document.querySelector(".confirm-modal").style.display = "none";
    document.querySelector(".js-dropdown").dataset.isOpen = "false";
  };
  closeConfirmDeleteModal = () => {
    document.querySelector(".confirm-modal-delete").style.display = "none";
    document.querySelector(".js-dropdown").dataset.isOpen = "false";
  };
  closeEmptyOrderModal = () => {
    document.querySelector(".order-empty-modal").style.display = "none";
    document.querySelector(".js-dropdown").dataset.isOpen = "false";
  };
  confirmModal = () => {
    let localCart = [];
    sessionStorage.setItem("localCart", JSON.stringify(localCart));
    sessionStorage.removeItem("lokal");
    sessionStorage.removeItem("lokalHref");
    sessionStorage.removeItem("street");
    sessionStorage.removeItem("streetNumber");
    window.location.href = "https://pizzabunga.pl/";
  };
  closePriceModal = () => {
    document.querySelector(".price-modal").style.display = "none";
  };
  closePaymentModal = () => {
    document.querySelector(".payment-modal").style.display = "none";
  };
  closePizzaModal = () => {
    document.querySelector(".pizza-modal").style.display = "none";
  };
  closeEmptyPizzaModal = () => {
    document.querySelector(".pizza-empty-modal").style.display = "none";
  };
  closePremiseModal = () => {
    document.querySelector(".lokal-modal").style.display = "none";
    if (sessionStorage.getItem('homeCategory')) {
      document.querySelector(`a[data-id="${sessionStorage.getItem('homeCategory')}"]`).click();
      setTimeout(function(){
        document.querySelector(`article[id="${sessionStorage.getItem('homeID')}"] .menu-item__size:nth-child(${sessionStorage.getItem('size') === '3' ? 5 : sessionStorage.getItem('size') === '2' ? 3 : 1})`) && document.querySelector(`article[id="${sessionStorage.getItem('homeID')}"] .menu-item__size:nth-child(${sessionStorage.getItem('size') === '3' ? 5 : sessionStorage.getItem('size') === '2' ? 3 : 1})`).click();
      }, 500);
      setTimeout(function(){
        document.querySelector(`article[id="${sessionStorage.getItem('homeID')}"] .menu-item__button`) && document.querySelector(`article[id="${sessionStorage.getItem('homeID')}"] .menu-item__button`).click();
        sessionStorage.removeItem('homeCategory');
        sessionStorage.removeItem('homeID');
      }, 700);
    }
  };
  closeVisibleSpecial = () => {
    document.querySelector(".visible-special-modal").style.display = "none";
  }
  render() {
    return (
      <>
        <div className="modal js-modal price-modal">
          <div className="modal__content">
            <div
              className="close-icon js-modal-close"
              onClick={() => this.closePriceModal()}
            >
              <div className="close-icon__stick-1"></div>
              <div className="close-icon__stick-2"></div>
            </div>
            <div className="modal__info">
              <h1 className="text__medium--2 text__w600 mrg-bottom-30">
                Komunikat
              </h1>
              <h2 className="text__medium text__w300">
                minimalna kwota do zamówień z dostawą to 25 zł
              </h2>
            </div>
            <button
              btn=""
              menu=""
              className="btn  btn--normal js-modal-btn"
              onClick={() => this.closePriceModal()}
            >
              Ok
            </button>
          </div>
        </div>
        <div className="modal js-modal confirm-modal">
          <div className="modal__content">
            <div className="modal__info">
              <h1 className="text__medium--2 text__w600 mrg-bottom-30">
                Komunikat
              </h1>
              <h2 className="text__medium text__w300">
                Po zmianie lokalu Twoje produkty z koszyka zostaną usunięte.{" "}
                <br /> Czy na pewno chcesz zmienić lokal?
              </h2>
            </div>
            <div className="modal__buttons">
              <button
                btn=""
                menu=""
                className="btn  btn--normal cancel"
                onClick={() => this.closeConfirmModal()}
              >
                Anuluj
              </button>
              <button
                btn=""
                menu=""
                className="btn  btn--normal js-modal-btn confirm"
                onClick={() => this.confirmModal()}
              >
                Potwierdź
              </button>
            </div>
          </div>
        </div>
        <div className="modal js-modal confirm-modal-delete">
          <div className="modal__content">
            <div className="modal__info">
              <h1 className="text__medium--2 text__w600 mrg-bottom-30">
                Komunikat
              </h1>
              <h2 className="text__medium text__w300">
                Czy na pewno chcesz usunąć produkt?
              </h2>
            </div>
            <div className="modal__buttons">
              <button
                btn=""
                menu=""
                className="btn  btn--normal cancel"
                onClick={() => this.closeConfirmDeleteModal()}
              >
                Anuluj
              </button>
              <button
                btn=""
                menu=""
                className="btn  btn--normal js-modal-btn confirm updateDelete"
              >
                Potwierdź
              </button>
            </div>
          </div>
        </div>
        <div className="modal js-modal order-empty-modal">
          <div className="modal__content">
            <div className="modal__info">
              <h1 className="text__medium--2 text__w600 mrg-bottom-30">
                Komunikat
              </h1>
              <h2 className="text__medium text__w300">
                Nie możesz złożyć pustego zamówienia!
              </h2>
            </div>
            <div className="modal__buttons">
              <button
                btn=""
                menu=""
                className="btn  btn--normal js-modal-btn"
                onClick={() => this.closeEmptyOrderModal()}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
        <div className="modal js-modal temp-modal">
          <div className="modal__content">
            <div className="close-icon js-modal-close">
              <div className="close-icon__stick-1"></div>
              <div className="close-icon__stick-2"></div>
            </div>
            <div className="modal__info">
              <h1 className="text__medium--2 text__w600 mrg-bottom-30">
                Komunikat
              </h1>
              <h2 className="text__medium text__w300">
                Tymczasowo składanie zamówień dla tego lokalu zostało wyłączone.
                Zapraszamy do innego punktu
              </h2>
            </div>
            <button btn="" menu="" className="btn  btn--normal js-modal-btn">
              Ok
            </button>
          </div>
        </div>
        <div className="modal js-modal payment-modal">
          <div className="modal__content">
            <div className="modal__info">
              <h1 className="text__medium--2 text__w600 mrg-bottom-30">
                Komunikat
              </h1>
              <h2 className="text__medium text__w300">
                Przy dostawie bezkontaktowej nie można wybrać innej metody
                płatności jak Przelewy24
              </h2>
            </div>
            <button
              btn=""
              menu=""
              className="btn  btn--normal js-modal-btn"
              onClick={() => this.closePaymentModal()}
            >
              Ok
            </button>
          </div>
        </div>
        <div className="modal js-modal lokal-modal">
          <div className="modal__content">
            <div className="modal__info">
              <h1 className="text__medium--2 text__w600 mrg-bottom-30">
                Komunikat
              </h1>
              <h2 className="text__medium text__w300"></h2>
            </div>
            <button
              btn=""
              menu=""
              className="btn  btn--normal js-modal-btn"
              onClick={() => this.closePremiseModal()}
            >
              Zamawiam!
            </button>
          </div>
        </div>
        <div className="modal js-modal visible-special-modal">
          <div className="modal__content">
            <div className="modal__info">
              <h1 className="text__medium--2 text__w600 mrg-bottom-30">
                Wybrany produkt jest aktualnie niedostępny!
              </h1>
              <br />
              <h1 className="text__medium--2 text__w600 mrg-bottom-30">
                Promocja obowiązuje:
              </h1>
              <br />
              <h2 className="text__medium text__w300"></h2>
            </div>
            <button
                btn=""
                menu=""
                className="btn  btn--normal js-modal-btn"
                onClick={() => this.closeVisibleSpecial()}
            >
              Zamknij komunikat
            </button>
          </div>
        </div>
        <div className="modal js-modal pizza-modal">
          <div className="modal__content">
            <div
              className="close-icon js-modal-close"
              onClick={() => this.closePizzaModal()}
            >
              <div className="close-icon__stick-1"></div>
              <div className="close-icon__stick-2"></div>
            </div>
            <div className="modal__info">
              <h1 className="text__medium--2 text__w600 mrg-bottom-30">
                Komunikat
              </h1>
              <h2 className="text__medium text__w300">
                Przepraszamy, nie można dodać więcej pozycji
              </h2>
            </div>
            <button
              btn=""
              menu=""
              className="btn  btn--normal js-modal-btn"
              onClick={() => this.closePizzaModal()}
            >
              Ok
            </button>
          </div>
        </div>
        <div className="modal js-modal pizza-empty-modal">
          <div className="modal__content">
            <div
              className="close-icon js-modal-close"
              onClick={() => this.closeEmptyPizzaModal()}
            >
              <div className="close-icon__stick-1"></div>
              <div className="close-icon__stick-2"></div>
            </div>
            <div className="modal__info">
              <h1 className="text__medium--2 text__w600 mrg-bottom-30">
                Komunikat
              </h1>
              <h2 className="text__medium text__w300">
                Twoja oferta jest jeszcze niedokończona!
              </h2>
            </div>
            <button
              btn=""
              menu=""
              className="btn  btn--normal js-modal-btn"
              onClick={() => this.closeEmptyPizzaModal()}
            >
              Ok
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default Modal;
