import React, { Component } from "react";
import { Connect } from "../components/connect/Connect";
import * as endpoint from "../endpoints/Endpoints";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";

class SpecialOffer extends Component {
  state = {
    data: [],
    ingriedients: [],
    dough: [],
    sauce: [],
    selectPizza: [],
    selectSauce: [],
    selectPasta: [],
    selectDrinks: [],
    selectDough: null,
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    let nav = document.querySelectorAll(".navbar__link");
    for (let i = 0; i < nav.length; i++) {
      nav[i].classList.remove("is-active");
    }
    sessionStorage.removeItem("customPizza");
    Connect(
      `${endpoint.backend}${endpoint.getAllSpecialOffers}/${id}`,
      "GET",
      null
    ).then((response) => {
      console.log(response, "here");
      this.setState({
        data: response,
        selectDough: response?.specialOfferIngriedient?.ingriedients?.sort(
          function (a, b) {
            return a.id - b.id;
          }
        )[0] || { id: 301151, name: "grube", type: "DOUGH" },
      });
      let items = document.querySelectorAll(".typeOfPizza span div:last-child");
      for (let i = 0; i < items.length; i++) {
        let text = items[i].innerHTML;
        items[i].innerHTML = text.replace(",", "");
      }
    });
    Connect(
      `${endpoint.backend}${endpoint.getAllIngriedients}`,
      "GET",
      null
    ).then((response) => {
      let tempDough = [];
      let tempSauce = [];
      response.map((item) => {
        if (item.type === "DOUGH") {
          tempDough.push(item);
        }
        if (
          item.type === "SAUCE" &&
          item.id !== 601025 &&
          item.id !== 601026 &&
          item.id !== 601023 &&
          item.id !== 601024
        ) {
          tempSauce.push(item);
        }
      });
      this.setState({
        ingriedients: response,
        dough: tempDough,
        sauce: tempSauce,
      });
    });
  }

  addToCart = () => {
    let localCarts = [];
    if (JSON.parse(sessionStorage.getItem("localCart"))) {
      localCarts = JSON.parse(sessionStorage.getItem("localCart"));
    }
    let post = this.state.data && this.state.data.id;
    let name = this.state.data && this.state.data.name;
    let price = document.querySelector(".customizePrice").innerText;

    let localInfo = [];
    if (this.state.data.specialOfferPizza.active) {
      let cakeID = this.state.selectDough.id;
      let cakeName = this.state.selectDough.name;
      let cakePrice = this.state.selectDough.id === 301153 ? 2 : 0;
      let localInfoCake = {
        id: cakeID,
        type: "cake",
        name: cakeName,
        price: cakePrice,
      };
      localInfo.push(localInfoCake);
    }

    let localInfoBase = document.querySelectorAll(
      ".customizePizzaFavourite div"
    );

    for (let i = 0; i < localInfoBase.length; i++) {
      let id = localInfoBase[i]
        .querySelector("span:first-child")
        .getAttribute("data-id");
      let name = localInfoBase[i]
        .querySelector("span:first-child")
        .getAttribute("data-name");
      let localInfoBaseD = { id: id, type: "base", name: name };
      localInfo.push(localInfoBaseD);
    }

    let localInfoExtend = document.querySelectorAll(
      ".customizeSauceFavourite div"
    );
    for (let i = 0; i < localInfoExtend.length; i++) {
      let id = localInfoExtend[i]
        .querySelector("span:first-child")
        .getAttribute("data-id");
      let name = localInfoExtend[i]
        .querySelector("span:first-child")
        .getAttribute("data-name");
      let localInfoExtendD = { id: id, type: "extend", name: name };
      localInfo.push(localInfoExtendD);
    }
    let localProduct = {
      id: post,
      name: name,
      price: price,
      counter: 1,
      info: [],
      infoPromo: localInfo,
      type: "twopizza",
    };
    localCarts.push(localProduct);

    const minPizzas = this.state.data.specialOfferPizza.choiceNum;
    let minProduct = 0;
    this.state.data.specialOfferProducts.forEach((element) => {
      if (element.active) minProduct = minProduct + element.choiceNum;
    });
    const selectedProductAll =
      this.state.selectPizza.length +
      this.state.selectSauce.length +
      this.state.selectPasta.length +
      this.state.selectDrinks.length;

    if (selectedProductAll < minPizzas + minProduct) {
      document.querySelector(".pizza-empty-modal").style.display = "block";
    } else {
      sessionStorage.setItem("localCart", JSON.stringify(localCarts));
      document.querySelector(".navbar__logo").click();
    }
  };

  handleDough = (data) => {
    this.setState({ selectDough: data });
  };

  handleOffer = (type = "add", obj, stateVariableName) => {
    if (type === "add") {
      const stateVariable = this.state[stateVariableName];
      stateVariable.push(obj);
      this.setState({
        [stateVariableName]: stateVariable,
      });
    } else if (type === "remove") {
      const stateVariable = this.state[stateVariableName];
      const id = (el) => el.id === obj.id;
      const index = this.state[stateVariableName].findIndex(id);
      stateVariable.splice(index, 1);
      this.setState({
        [stateVariableName]: stateVariable,
      });
    }
  };

  counterMinus = (e, handler) => {
    let val = e.target
      .closest(".counter")
      .querySelector(".counter__item-count").innerText;
    if (parseInt(val) > 0) {
      let newVal = parseInt(val) - 1;
      e.target
        .closest(".counter")
        .querySelector(".counter__item-count").innerText = newVal;
      handler && handler();
    }
  };

  counterPlus = (e, max, selected, handler) => {
    if (selected >= max) return;
    console.log("dodaje");
    let val = e.target
      .closest(".counter")
      .querySelector(".counter__item-count").innerText;

    let newVal = parseInt(val) + 1;
    e.target
      .closest(".counter")
      .querySelector(".counter__item-count").innerText = newVal;
    handler && handler();
  };

  render() {
    return (
      <>
        <Navbar
          sum={this.props.sum}
          counter={this.props.counter}
          summary={this.props.summary}
        />
        {console.log(this.state.data)}
        <section className="customize-pizza">
          <div className="customize-pizza__left-side">
            <h1 className="customize-pizza__header text__large text__w700">
              {this.state.data && this.state.data.name}
            </h1>
            <p className="customize-pizza__header-desc">
              {this.state.data && this.state.data.description}
            </p>
            {this.state.data.specialOfferPizza?.active &&
              this.state.data.specialOfferPizza?.specialOfferPizzaSize && (
                <form
                  className="menu-item__size-menu"
                  action="#"
                  style={{ maxWidth: "180px" }}
                >
                  <label
                    id={
                      this.state.data &&
                      this.state.data.sizes &&
                      this.state.data.sizes[0].id
                    }
                    className="menu-item__size menu-item__size--active"
                    data-size="1"
                    htmlFor="size1"
                  >
                    {this.state.data.specialOfferPizza?.specialOfferPizzaSize}
                  </label>

                  <input
                    className="menu-item__size-input"
                    check=""
                    id="size3"
                    type="checkbox"
                  />
                </form>
              )}

            {this.state.data.specialOfferIngriedient?.active ? (
              <div className="customize-pizza__customize-box">
                <h3
                  subheader=""
                  className="customize-pizza__box-header text__medium text__w500"
                >
                  Wybierz rodzaj ciasta:
                </h3>

                <p className="customize-pizza__box-desc">
                  {this.state.data.specialOfferIngriedient.description}
                </p>

                <div className="species-menu">
                  {this.state.data.specialOfferIngriedient &&
                    this.state.data.specialOfferIngriedient.ingriedients
                      .sort(function (a, b) {
                        return a.id - b.id;
                      })
                      .map((data, index) => (
                        <div
                          className={
                            this.state.selectDough === data
                              ? "species-menu__item typeOfCake species-menu__item--active"
                              : "species-menu__item typeOfCake"
                          }
                          data-id={data.id}
                          data-price={(data.id === 301153 ? 1 : 0) * 2}
                          data-name={data.name.replace(
                            "cienkie razowe (+1 zł)",
                            "2 x cienkie razowe (+1 zł)"
                          )}
                          onClick={(e) => this.handleDough(data)}
                        >
                          {data.name.replace(
                            "cienkie razowe (+1 zł)",
                            "2 x cienkie razowe (+1 zł)"
                          )}
                        </div>
                      ))}
                </div>
              </div>
            ) : (
              <div className="customize-pizza__customize-box">
                <h3
                  subheader=""
                  className="customize-pizza__box-header text__medium text__w500"
                >
                  Rodzaj ciasta:
                </h3>

                <div className="species-menu">
                  <div
                    className="species-menu__item typeOfCake species-menu__item--active"
                    data-name="grube"
                  >
                    Grube
                  </div>
                </div>
              </div>
            )}
            {this.state.data.specialOfferPizza?.active && (
              <div className="customize-pizza__customize-box twopizza">
                <h3
                  subheader=""
                  className="customize-pizza__box-header text__medium text__w500"
                >
                  Wybierz {this.state.data.specialOfferPizza.choiceNum} ulubione
                  smaki:
                </h3>

                <p className="customize-pizza__box-desc">
                  {this.state.data.specialOfferPizza.description}
                </p>

                <div className="species-menu">
                  {this.state.data &&
                    this.state.data.specialOfferPizza.pizzas &&
                    this.state.data.specialOfferPizza.pizzas.map((data) => (
                      <div className="species-menu-box">
                        <div
                          className={`species-menu__item typeOfSauce typeOfPizza ${
                            this.state.selectPizza.includes(data) &&
                            "species-menu__item--active"
                          }`}
                          data-id={data.id}
                          data-name={data.name}
                        >
                          {data.name}
                          <span>
                            (
                            {data.ingriedients &&
                              data.ingriedients.map((item) =>
                                item.type === "SAUCE" ? (
                                  <div>{item.name + ","}</div>
                                ) : (
                                  ""
                                )
                              )}
                            {data.ingriedients &&
                              data.ingriedients.map((item) =>
                                item.type === "CHEESE" ? (
                                  <div>{item.name + ","}</div>
                                ) : (
                                  ""
                                )
                              )}
                            {data.ingriedients &&
                              data.ingriedients.map((item) =>
                                item.type !== "SAUCE" &&
                                item.type !== "CHEESE" ? (
                                  <div>{item.name + ","}</div>
                                ) : (
                                  ""
                                )
                              )}
                            )
                          </span>
                        </div>
                        <div className="counter currentIngredient">
                          <div
                            className="counter__minus"
                            onClick={(e) =>
                              this.counterMinus(e, () =>
                                this.handleOffer("remove", data, "selectPizza")
                              )
                            }
                          >
                            -
                          </div>
                          <div className="counter__item-count">0</div>
                          <span
                            className="counter__plus"
                            onClick={(e) =>
                              this.counterPlus(
                                e,
                                this.state.data.specialOfferPizza.choiceNum,
                                this.state.selectPizza.length,
                                () =>
                                  this.handleOffer("add", data, "selectPizza")
                              )
                            }
                          >
                            +
                          </span>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            )}
            {this.state.data.specialOfferProducts?.find(
              (element) => element.category.id === 601152
            )?.active && (
              <div className="customize-pizza__customize-box twoSauce ">
                <h3
                  subheader=""
                  className="customize-pizza__box-header text__medium text__w500"
                >
                  Wybierz{" "}
                  {
                    this.state.data.specialOfferProducts?.find(
                      (element) => element.category.id === 601152
                    ).choiceNum
                  }{" "}
                  ulubione sosy:
                </h3>
                <p className="customize-pizza__box-desc">
                  {
                    this.state.data.specialOfferProducts?.find(
                      (element) => element.category.id === 601152
                    ).description
                  }
                </p>
                <div className="species-menu">
                  {this.state.data.specialOfferProducts?.find(
                    (element) => element.category.id === 601152
                  ) &&
                    this.state.data.specialOfferProducts
                      ?.find((element) => element.category.id === 601152)
                      .products.map((data) => (
                        <div className="species-menu-box">
                          <div
                            className={`species-menu__item typeOfSauce typeOfPizza ${
                              this.state.selectSauce.includes(data) &&
                              "species-menu__item--active"
                            }`}
                            data-id={data.id}
                            data-name={data.name}
                          >
                            {data.name.replace("sos ", "")}
                          </div>
                          <div className="counter currentIngredient">
                            <div
                              className="counter__minus"
                              onClick={(e) =>
                                this.counterMinus(e, () =>
                                  this.handleOffer(
                                    "remove",
                                    data,
                                    "selectSauce"
                                  )
                                )
                              }
                            >
                              -
                            </div>
                            <div className="counter__item-count">0</div>
                            <span
                              className="counter__plus"
                              onClick={(e) =>
                                this.counterPlus(
                                  e,
                                  this.state.data.specialOfferProducts?.find(
                                    (element) => element.category.id === 601152
                                  ).choiceNum,
                                  this.state.selectSauce.length,
                                  () =>
                                    this.handleOffer("add", data, "selectSauce")
                                )
                              }
                            >
                              +
                            </span>
                          </div>
                        </div>
                      ))}
                </div>
              </div>
            )}
            {this.state.data.specialOfferProducts?.find(
              (element) => element.category.id === 1502
            )?.active && (
              <div className="customize-pizza__customize-box twoSauce ">
                <h3
                  subheader=""
                  className="customize-pizza__box-header text__medium text__w500"
                >
                  Wybierz{" "}
                  {
                    this.state.data.specialOfferProducts?.find(
                      (element) => element.category.id === 1502
                    ).choiceNum
                  }{" "}
                  ulubione makarony:
                </h3>
                <p className="customize-pizza__box-desc">
                  {
                    this.state.data.specialOfferProducts?.find(
                      (element) => element.category.id === 1502
                    ).description
                  }
                </p>
                <div className="species-menu">
                  {this.state.data.specialOfferProducts?.find(
                    (element) => element.category.id === 1502
                  ) &&
                    this.state.data.specialOfferProducts
                      ?.find((element) => element.category.id === 1502)
                      .products.map((data) => (
                        <div className="species-menu-box">
                          <div
                            className={`species-menu__item typeOfSauce  ${
                              this.state.selectPasta.includes(data) &&
                              "species-menu__item--active"
                            }`}
                            data-id={data.id}
                            data-name={data.name}
                          >
                            {data.name.replace("sos ", "")}
                          </div>
                          <div className="counter currentIngredient">
                            <div
                              className="counter__minus"
                              onClick={(e) =>
                                this.counterMinus(e, () =>
                                  this.handleOffer(
                                    "remove",
                                    data,
                                    "selectPasta"
                                  )
                                )
                              }
                            >
                              -
                            </div>
                            <div className="counter__item-count">0</div>
                            <span
                              className="counter__plus"
                              onClick={(e) =>
                                this.counterPlus(
                                  e,
                                  this.state.data.specialOfferProducts?.find(
                                    (element) => element.category.id === 1502
                                  ).choiceNum,
                                  this.state.selectPasta.length,
                                  () =>
                                    this.handleOffer("add", data, "selectPasta")
                                )
                              }
                            >
                              +
                            </span>
                          </div>
                        </div>
                      ))}
                </div>
              </div>
            )}
            {this.state.data.specialOfferProducts?.find(
              (element) => element.category.id === 1252
            )?.active && (
              <div className="customize-pizza__customize-box twoSauce ">
                <h3
                  subheader=""
                  className="customize-pizza__box-header text__medium text__w500"
                >
                  Wybierz{" "}
                  {
                    this.state.data.specialOfferProducts?.find(
                      (element) => element.category.id === 1252
                    ).choiceNum
                  }{" "}
                  ulubione napoje:
                </h3>
                <p className="customize-pizza__box-desc">
                  {
                    this.state.data.specialOfferProducts?.find(
                      (element) => element.category.id === 1252
                    ).description
                  }
                </p>
                <div className="species-menu">
                  {this.state.data.specialOfferProducts?.find(
                    (element) => element.category.id === 1252
                  ) &&
                    this.state.data.specialOfferProducts
                      ?.find((element) => element.category.id === 1252)
                      .products.map((data) => (
                        <div className="species-menu-box">
                          <div
                            className={`species-menu__item typeOfSauce  ${
                              this.state.selectDrinks.includes(data) &&
                              "species-menu__item--active"
                            }`}
                            data-id={data.id}
                            data-name={data.name}
                          >
                            {data.name.replace("sos ", "")}
                            <span>
                              {
                                this.state.data.specialOfferProducts?.find(
                                  (element) => element.category.id === 1252
                                ).specialOfferProductSize
                              }
                              l
                            </span>
                          </div>
                          <div className="counter currentIngredient">
                            <div
                              className="counter__minus"
                              onClick={(e) =>
                                this.counterMinus(e, () =>
                                  this.handleOffer(
                                    "remove",
                                    data,
                                    "selectDrinks"
                                  )
                                )
                              }
                            >
                              -
                            </div>
                            <div className="counter__item-count">0</div>
                            <span
                              className="counter__plus"
                              onClick={(e) =>
                                this.counterPlus(
                                  e,
                                  this.state.data.specialOfferProducts?.find(
                                    (element) => element.category.id === 1252
                                  ).choiceNum,
                                  this.state.selectDrinks.length,
                                  () =>
                                    this.handleOffer(
                                      "add",
                                      data,
                                      "selectDrinks"
                                    )
                                )
                              }
                            >
                              +
                            </span>
                          </div>
                        </div>
                      ))}
                </div>
              </div>
            )}
          </div>
          <div className="customize-pizza__right-side">
            <div className="customize-result sticky">
              <h3 className="text__medium--2 text__w500">Podsumowanie</h3>
              {this.state.data.specialOfferPizza?.active && (
                <div className="customize-result__result-item">
                  <span className="customize-result__result-type text__medium text__w500">
                    Rodzaj ciasta:
                  </span>
                  <span className="customize-result__result text__medium text__w400 customizeBox customizeCake">
                    <div>
                      <span data-id={this.state.selectDough?.id}>
                        {this.state.selectDough?.id === 301153
                          ? `2 x ${this.state.selectDough.name}`
                          : this.state.selectDough?.name}
                      </span>
                      <span>
                        {this.state.selectDough?.id === 301153
                          ? "+2 zł"
                          : "+0 zł"}
                      </span>
                    </div>
                  </span>
                </div>
              )}
              {this.state.data.specialOfferPizza?.active && (
                <div className="customize-result__result-item ">
                  <span className="customize-result__result-type text__medium text__w500">
                    Ulubione smaki:
                  </span>
                  <span className="customize-result__result text__medium text__w400 customizeBox customizePizzaFavourite">
                    {this.state.selectPizza &&
                      this.state.selectPizza.map((data) => (
                        <div>
                          <span data-id={data.id} data-name={data.name}>
                            {data.name}
                          </span>
                        </div>
                      ))}
                  </span>
                </div>
              )}
              {this.state.data.specialOfferProducts?.find(
                (element) => element.category.id === 601152
              )?.active && (
                <div className="customize-result__result-item ">
                  <span className="customize-result__result-type text__medium text__w500">
                    Ulubione sosy:
                  </span>
                  <span className="customize-result__result text__medium text__w400 customizeBox customizeSauceFavourite">
                    {this.state.selectSauce &&
                      this.state.selectSauce.map((data) => (
                        <div>
                          <span data-id={data.id} data-name={data.name}>
                            {data.name.replace("sos ", "")}
                          </span>
                        </div>
                      ))}
                  </span>
                </div>
              )}
              {this.state.data.specialOfferProducts?.find(
                (element) => element.category.id === 1502
              )?.active && (
                <div className="customize-result__result-item ">
                  <span className="customize-result__result-type text__medium text__w500">
                    Ulubione makarony:
                  </span>
                  <span className="customize-result__result text__medium text__w400 customizeBox customizeSauceFavourite">
                    {this.state.selectPasta &&
                      this.state.selectPasta.map((data) => (
                        <div>
                          <span data-id={data.id} data-name={data.name}>
                            {data.name.replace("sos ", "")}
                          </span>
                        </div>
                      ))}
                  </span>
                </div>
              )}
              {this.state.data.specialOfferProducts?.find(
                (element) => element.category.id === 1252
              )?.active && (
                <div className="customize-result__result-item ">
                  <span className="customize-result__result-type text__medium text__w500">
                    Ulubione napoje:
                  </span>
                  <span className="customize-result__result text__medium text__w400 customizeBox customizeSauceFavourite">
                    {this.state.selectDrinks &&
                      this.state.selectDrinks.map((data) => (
                        <div>
                          <span data-id={data.id} data-name={data.name}>
                            {data.name.replace("sos ", "")}
                          </span>
                        </div>
                      ))}
                  </span>
                </div>
              )}

              <div className="customize-pizza__summary">
                <div className="customize-pizza__summary__price">
                  <span className="text__medium--2 text__w600">Suma: </span>
                  <span
                    className="text__medium--2 text__w600 customizePrice"
                    data-id="1"
                    data-price={this.state.data && this.state.data.price}
                  >
                    {`${
                      this.state.data &&
                      this.state.data.price &&
                      this.state.selectDough?.id === 301153
                        ? `${
                            Number(this.state.data.price.toFixed(2)) + 2
                          }`.replace(".", ",")
                        : `${this.state.data.price}`.replace(".", ",")
                    }`}{" "}
                    zł
                  </span>
                </div>
                <button className="btn" onClick={() => this.addToCart()}>
                  Do koszyka
                </button>
              </div>
            </div>
          </div>
          <div className="background-customize-pizza">
            <div
              className="background-customize-pizza__top"
              title="shape top"
            ></div>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}

export default SpecialOffer;
