import React, {Component} from "react";
import Dropdown from "../dropdown/Dropdown";

class Welcome extends Component {
    render() {
        return (
            <div className="welcome-page__form-container">
                <div className="desktop">
                    <h2 className="welcome-page__subheader text__medium text__w600">Wpisz swój adres aby wyszukać
                        najbliższy lokal</h2>
                </div>
                <form action="" className="js-form">
                    <div className="input form__input--full-width js-input cityInput" data-type="street"
                         data-error="false">
                        <Dropdown data={this.props.cities} popup={this.props.popup} />
                    </div>
                    <div className="input form__input--full-width js-input streetInput" data-type="street"
                         data-error="false">
                        <input id="placesMap" className="input__input pac-target-input" type="text"
                               placeholder="Ulica i numer (np. Krucza 28)" autoComplete="off" />
                        <label className="input__label" htmlFor="placesMap">Ulica i numer</label>
                        <span className="input__error" />
                    </div>
                    <div className="input form__input--full-width js-input streetsInput d-none"
                         data-type="streets" data-error="false">
                        <input id="placeMapsPopup" className="input__input pac-target-input" type="text"
                               placeholder="Ulica" autoComplete="off" />
                        <label className="input__label" htmlFor="placeMapsPopup">Ulica</label>
                        <span className="input__error"></span>
                    </div>
                    <div col-full="" btn="" btn-index="" className="buttonInput">
                        <button type="button" className="btn btn--normal" onClick={this.props.handleClick}>Zamów</button>
                    </div>
                </form>
            </div>
        )
    }
}

export default Welcome;