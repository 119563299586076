import React, {Component} from "react";
import Navbar from "../components/navbar/Navbar";
import {Link, withRouter} from "react-router-dom";
import Geocode from "react-geocode";
import scriptContent from "../..";
import Footer from "../components/footer/Footer";
import image from '../assets/intro.png';
import { Connect } from "../components/connect/Connect";
import * as endpoint from '../endpoints/Endpoints';
import CustomModal from "../components/CustomModal/CustomModal";
import Welcome from "../components/welcome/Welcome";
import Popup from "../components/popup/Popup";
import { Helmet } from "react-helmet"; // Import react-helmet
let self;

Geocode.setApiKey("AIzaSyDaoUGIs0R6hByfuTLH43BZyaeu_K8MsS0");
Geocode.enableDebug();

class Home extends Component {
    state = {
        lat: null,
        lng: null,
        postCode: null,
        status: null,
        address: '',
        isModalOpened: false,
        isPremiseOpened: false,
        typeOfDay: 'Workday',
        deliveryCost: 0,
        minimalPrice: 0,
        premise: {},
        activePremises: [false, false, false, false],
        polygon: [],
        cities: [],
        premises: [],
        categories: [],
        activeCategory: 0,
        popup: false,
        special: null,
        event: null,
        visible: null,
        edit: null,
        id: null,
        homePremise: 0,
        empty: false,
        resultPremise: null,
        size: 1
    }
    componentDidMount() {
        sessionStorage.setItem('firstLoad', 'true');
        this.loadScript();

        Connect(
            `${endpoint.backend}${endpoint.getPremises}`,
            "GET",
            null
        ).then((response) => {
            response.map((data) => {
                if (data.menuSite === true) {
                    this.setState({
                        homePremise: data.id
                    })
                    Connect(
                        `${endpoint.backend}${endpoint.getAllCategories}`,
                        "GET",
                        null
                    ).then((response) => {
                        this.setState({
                            categories: response,
                        });
                        sessionStorage.setItem('homeCategory', this.state.categories[0].id);
                        Connect(
                            `${endpoint.backend}${
                                endpoint.getAllPizzasAndProducts
                            }/${data.id}/${this.state.categories[0].id}`,
                            "GET",
                            null
                        ).then((response) => {
                            const d = new Date();
                            response.map((item) => {
                                if (item.specialOffer) {
                                    let day = d.getDay();
                                    let hour = d.getHours();
                                    let minute = d.getMinutes();
                                    let visible = false;
                                    item.specialOffer.specialOfferTimes.map((itemik) => {
                                        if ((itemik.dayName === 'Niedziela') && (day === 0)) {
                                            let dayStartHour = itemik.startHour.substring(0, 2);
                                            let dayStartMinute = itemik.startHour.substring(3, 5);
                                            let dayEndHour = itemik.endHour.substring(0, 2);
                                            let dayEndMinute = itemik.endHour.substring(3, 5);
                                            if ((parseInt(dayStartHour) <= hour) && parseInt(dayEndHour) >= hour) {
                                                visible = true;
                                                if ((parseInt(dayStartHour) === hour) && (parseInt(dayStartMinute) > minute)) {
                                                    visible = false;
                                                }
                                                if ((parseInt(dayEndHour) === hour) && (parseInt(dayEndMinute) < minute)) {
                                                    visible = false;
                                                }
                                            }
                                        }
                                        if ((itemik.dayName === 'Poniedziałek') && (day === 1)) {
                                            let dayStartHour = itemik.startHour.substring(0, 2);
                                            let dayStartMinute = itemik.startHour.substring(3, 5);
                                            let dayEndHour = itemik.endHour.substring(0, 2);
                                            let dayEndMinute = itemik.endHour.substring(3, 5);
                                            if ((parseInt(dayStartHour) <= hour) && parseInt(dayEndHour) >= hour) {
                                                visible = true;
                                                if ((parseInt(dayStartHour) === hour) && (parseInt(dayStartMinute) > minute)) {
                                                    visible = false;
                                                }
                                                if ((parseInt(dayEndHour) === hour) && (parseInt(dayEndMinute) < minute)) {
                                                    visible = false;
                                                }
                                            }
                                        }
                                        if ((itemik.dayName === 'Wtorek') && (day === 2)) {
                                            let dayStartHour = itemik.startHour.substring(0, 2);
                                            let dayStartMinute = itemik.startHour.substring(3, 5);
                                            let dayEndHour = itemik.endHour.substring(0, 2);
                                            let dayEndMinute = itemik.endHour.substring(3, 5);
                                            if ((parseInt(dayStartHour) <= hour) && parseInt(dayEndHour) >= hour) {
                                                visible = true;
                                                if ((parseInt(dayStartHour) === hour) && (parseInt(dayStartMinute) > minute)) {
                                                    visible = false;
                                                }
                                                if ((parseInt(dayEndHour) === hour) && (parseInt(dayEndMinute) < minute)) {
                                                    visible = false;
                                                }
                                            }
                                        }
                                        if ((itemik.dayName === 'Środa') && (day === 3)) {
                                            let dayStartHour = itemik.startHour.substring(0, 2);
                                            let dayStartMinute = itemik.startHour.substring(3, 5);
                                            let dayEndHour = itemik.endHour.substring(0, 2);
                                            let dayEndMinute = itemik.endHour.substring(3, 5);
                                            if ((parseInt(dayStartHour) <= hour) && parseInt(dayEndHour) >= hour) {
                                                visible = true;
                                                if ((parseInt(dayStartHour) === hour) && (parseInt(dayStartMinute) > minute)) {
                                                    visible = false;
                                                }
                                                if ((parseInt(dayEndHour) === hour) && (parseInt(dayEndMinute) < minute)) {
                                                    visible = false;
                                                }
                                            }
                                        }
                                        if ((itemik.dayName === 'Czwartek') && (day === 4)) {
                                            let dayStartHour = itemik.startHour.substring(0, 2);
                                            let dayStartMinute = itemik.startHour.substring(3, 5);
                                            let dayEndHour = itemik.endHour.substring(0, 2);
                                            let dayEndMinute = itemik.endHour.substring(3, 5);
                                            if ((parseInt(dayStartHour) <= hour) && parseInt(dayEndHour) >= hour) {
                                                visible = true;
                                                if ((parseInt(dayStartHour) === hour) && (parseInt(dayStartMinute) > minute)) {
                                                    visible = false;
                                                }
                                                if ((parseInt(dayEndHour) === hour) && (parseInt(dayEndMinute) < minute)) {
                                                    visible = false;
                                                }
                                            }
                                        }
                                        if ((itemik.dayName === 'Piątek') && (day === 5)) {
                                            let dayStartHour = itemik.startHour.substring(0, 2);
                                            let dayStartMinute = itemik.startHour.substring(3, 5);
                                            let dayEndHour = itemik.endHour.substring(0, 2);
                                            let dayEndMinute = itemik.endHour.substring(3, 5);
                                            if ((parseInt(dayStartHour) <= hour) && parseInt(dayEndHour) >= hour) {
                                                visible = true;
                                                if ((parseInt(dayStartHour) === hour) && (parseInt(dayStartMinute) > minute)) {
                                                    visible = false;
                                                }
                                                if ((parseInt(dayEndHour) === hour) && (parseInt(dayEndMinute) < minute)) {
                                                    visible = false;
                                                }
                                            }
                                        }
                                        if ((itemik.dayName === 'Sobota') && (day === 6)) {
                                            let dayStartHour = itemik.startHour.substring(0, 2);
                                            let dayStartMinute = itemik.startHour.substring(3, 5);
                                            let dayEndHour = itemik.endHour.substring(0, 2);
                                            let dayEndMinute = itemik.endHour.substring(3, 5);
                                            if ((parseInt(dayStartHour) <= hour) && parseInt(dayEndHour) >= hour) {
                                                visible = true;
                                                if ((parseInt(dayStartHour) === hour) && (parseInt(dayStartMinute) > minute)) {
                                                    visible = false;
                                                }
                                                if ((parseInt(dayEndHour) === hour) && (parseInt(dayEndMinute) < minute)) {
                                                    visible = false;
                                                }
                                            }
                                        }
                                        return true;
                                    })
                                    item.visible = visible;
                                }
                                return true;
                            })
                            this.setState({
                                premises: response,
                            });
                        });
                    })
                }
            })
        });

        sessionStorage.removeItem('codeDelivery');
        sessionStorage.removeItem('postCode');
        sessionStorage.removeItem('lokalUrl');
        sessionStorage.removeItem('lokalID');
        sessionStorage.removeItem('ownDelivery');
        sessionStorage.removeItem('streetFull');
        sessionStorage.removeItem('codeMinimum');
        sessionStorage.removeItem('lokal');
        sessionStorage.removeItem('street');
        sessionStorage.removeItem('localCart');
        // sessionStorage.removeItem('streetNumber');
        localStorage.removeItem('location');

        Connect(
            `${endpoint.backend}${endpoint.getPolygon}`,
            "GET",
            null
        ).then((response) => {
            this.setState({
                polygon: response,
            });
        });
        Connect(
            `${endpoint.backend}${endpoint.cities}`,
            "GET",
            null
        ).then((response) => {
            this.setState({
                cities: response
            });
        });

        document.querySelector('.main-container').classList.add('intro');
        let nav = document.querySelectorAll('.navbar__link');
        for (let i = 0; i < nav.length; i++) {
            nav[i].classList.remove('is-active');
        }
        this.letters();

        this.activePremise(1051);
        this.activePremise(1551);
        this.activePremise(1552);
        this.activePremise(1553);
    }

    open = (e) => {
        e.preventDefault();
    }

    loadScript = () => {
        const script = document.createElement("script");
        script.innerText = scriptContent;
        script.async = true;
        self = this;

        document.body.appendChild(script);
    }

    componentWillUnmount() {
        document.querySelector('.main-container').classList.remove('intro');
    }

    letters = () => {
        let string = document.querySelectorAll('h2');

        for (let i = 0; i < string.length; i++) {
            let oldString = string[i].innerHTML
            let newString = oldString.replace(/(\s)([\S])[\s]+/g, "$1$2&nbsp;");
            newString = newString.replace(/(\s)([^<][\S]{1})[\s]+/g,"$1$2&nbsp;");
            newString = newString.replace(/(\s)([\S])([\s])([\S])([\s])+/g,"$1$2$4");
            newString = newString.replace(/(\s)([^<][\S]{2})[\s]+/g,"$1$2&nbsp;");
            string[i].innerHTML = newString;
        }
    }
    changeSize = (e) => {
        e.target.closest(".menu-item__size-menu").classList.remove("open");
        if (!e.target.classList.contains("menu-item__size--active")) {
            e.target
                .closest(".menu-item__size-menu")
                .querySelector(".menu-item__size--active")
                .classList.remove("menu-item__size--active");
            e.target.classList.add("menu-item__size--active");
            let newCurrentPriceNumber = e.target.getAttribute("data-size");
            this.setState({
                size: newCurrentPriceNumber
            })
            let getNewCurrentPrice = e.target
                .closest(".wppizza-article")
                .querySelector(
                    `.wppizza-article-prices li:nth-child(${newCurrentPriceNumber}) span`
                ).innerText;
            let tempNewCurrentPrice = getNewCurrentPrice.replace(" zł", "");
            let newCurrentPrice =
                parseFloat(tempNewCurrentPrice).toFixed(2).replace(".", ",") + " zł";
            let newIDSize = e.target
                .closest(".wppizza-article")
                .querySelector(
                    `.wppizza-article-prices li:nth-child(${newCurrentPriceNumber})`
                )
                .getAttribute("id");
            e.target
                .closest(".wppizza-article")
                .querySelector(".menu-item__price").innerText = newCurrentPrice;
            e.target
                .closest(".wppizza-article")
                .querySelector(".menu-item__price")
                .setAttribute("data-size", newCurrentPriceNumber);
            e.target
                .closest(".wppizza-article")
                .querySelector(".menu-item__price")
                .setAttribute("data-id", newIDSize);
        }
    };
    setDefaultPrice = () => {
        sessionStorage.setItem("codeMinimum", "30");
        sessionStorage.setItem("codeDelivery", "0");
    }
    updateMinimalPrice(newPrice, deliveryPrice) {
        sessionStorage.setItem('codeMinimum', newPrice);
        sessionStorage.setItem('codeDelivery', deliveryPrice);
    }
    async getPremise(premise) {
        return await Connect(`${endpoint.backend}${endpoint.getPremises}/${premise}`, 'GET', null).then(res => res);
    }
    async getPremiseFromPostCode(postCode) {
        await Connect(`${endpoint.backend}${endpoint.cities}`, "GET", null).then(res => res);
        const premiseId = await Connect(`${endpoint.backend}${endpoint.getPostCodeData}?postCode=${postCode}`, "GET", null).then(res => res);
        const address = await Connect(`${endpoint.backend}${endpoint.getAddressData}?postCode=${postCode}`, "GET", null).then(res => res);
        sessionStorage.setItem('postCode', postCode);
        sessionStorage.setItem('streetFull', document.querySelector('#placesMap').value)
        if (address && typeof address.minimalPrice === 'number')
            this.updateMinimalPrice(address.minimalPrice, address.deliveryPrice);
        return await Connect(`${endpoint.backend}${endpoint.getPremises}/${premiseId}`, 'GET', null).then(res => res);
    }
    async getPostalCode(lat, lng) {
        return await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyDaoUGIs0R6hByfuTLH43BZyaeu_K8MsS0`)
            .then(res => res.ok && res.status === 200 ? res.json() : new Error(res.status))
            .then(res => res.results[0].address_components.filter(el => el.types.includes('postal_code'))[0].long_name);    
    }
    async getServerTime() {
        return await Connect(`${endpoint.backend}${endpoint.getServerTime}`, 'GET', null).then(res => Number.isInteger(res) ? new Date(res) : null);
    }
    async checkIsPremiseOpen(premise) {
        const serverTime = await this.getServerTime();
        const currentTime = {
            weekDay: serverTime.getDay(),
            hour:    serverTime.getHours(),
            minutes: serverTime.getMinutes()
        };
        const typeOfDay = (currentTime.weekDay === 0 || currentTime.weekDay > 5) ? 'Weekend' : 'Workday';
        this.setState({ typeOfDay: typeOfDay });
        const openingHours = {
            openHour:     Number(premise[`openHour${typeOfDay}`].split(':')[0]),
            openMinutes:  Number(premise[`openHour${typeOfDay}`].split(':')[1]),
            closeHour:    Number(premise[`closeHour${typeOfDay}`].split(':')[0]),
            closeMinutes: Number(premise[`closeHour${typeOfDay}`].split(':')[1]),
        }

        if (premise.open) {
            if (((currentTime.hour >   openingHours.openHour)  && (currentTime.hour     < openingHours.closeHour)) ||
                ((currentTime.hour === openingHours.openHour)  && (currentTime.minutes >= openingHours.openMinutes)) ||
                ((currentTime.hour === openingHours.closeHour) && (currentTime.minutes <= openingHours.closeMinutes))) {
                // opening hours for example - 10:30 - 18:30
                // first condition - hour is in range 11-17
                // second condition - hour is 10 and minutes are 30-59
                // third condition - hour is 18 and minutes are 0-30
                return true;
            }
        }
        return false;
    }
    redirectToSelectedPremise() {
        const letters     = ['ą', 'ć', 'ę', 'ł', 'ń', 'ó', 'ś', 'ź', 'ż'];
        const replacement = ['a', 'c', 'e', 'l', 'n', 'o', 's', 'z', 'z'];
        let result = this.state.premise.name.toLocaleLowerCase();
        this.setState({
            resultPremise: result
        })
        sessionStorage.setItem('homeID', this.state.id);
        sessionStorage.setItem('size', this.state.size);
        let premiseID = this.state.premise.id;
        let goPremise = false;
        Connect(
            `${endpoint.backend}${
                endpoint.getAllPizzasAndProducts
            }/${premiseID}/${sessionStorage.getItem('homeCategory')}`,
            "GET",
            null
        ).then((response) => {
            response.map((data) => {
                if ((this.state.special !== false ? data.specialOffer && data.specialOffer.id : data.pizza !== null ? data.pizza.id : data.product.id) === this.state.id) {
                    goPremise = true;
                }
            })
            if (goPremise === true) {
                for (let i = 0; i < letters.length; ++i)
                    result = result.replaceAll(letters[i], replacement[i]);
                this.handlePopup(false);
                window.location.href = `/${result}`;
            } else {
                this.setState({
                    empty: true
                })
            }
        })
    }

    handleEmpty = (value) => {
        this.setState({
            empty: value
        })
    }

    handleIgnore = () => {
        const letters     = ['ą', 'ć', 'ę', 'ł', 'ń', 'ó', 'ś', 'ź', 'ż'];
        const replacement = ['a', 'c', 'e', 'l', 'n', 'o', 's', 'z', 'z'];
        for (let i = 0; i < letters.length; ++i)
            this.state.resultPremise = this.state.resultPremise.replaceAll(letters[i], replacement[i]);
        this.handlePopup(false);
        window.location.href = `/${this.state.resultPremise}`;
    }

    inside = (point, vs) => {
        let x = point[0], y = point[1];

        let inside = false;
        for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
            let xi = vs[i][0], yi = vs[i][1];
            let xj = vs[j][0], yj = vs[j][1];

            let intersect = ((yi > y) != (yj > y))
                && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
            if (intersect) inside = !inside;
        }

        return inside;
    }

    handleClick = () => {
        if ((document.querySelector('#placesMap').value === '') || ((parseInt(JSON.parse(localStorage.getItem("location")).address_components[0].long_name.length) > 4) && JSON.parse(localStorage.getItem("location")).address_components[0].long_name !== 'Rydygiera')) {
            document.querySelector('.streetInput').setAttribute('data-error', 'true');
            document.querySelector('.streetInput .input__error').innerText = "Musisz podać ulicę i numer z podpowiedzi";
        } else {
            const {lat, lng} = JSON.parse(localStorage.getItem("location")).geometry.location;
            let dataPremise = 'empty';
            this.state.polygon.map((data) => {
                let arrays = data.polygon.replace('POLYGON ((', '').replace('))', '').replaceAll(', ', ', ').split(', ')
                let array = [];
                arrays.map(function(item) {
                    item.replace(' ', ', ').split(', ').map(function(subitem) {
                        return parseFloat(subitem);
                    })
                    array.push(item.replace(' ', ', ').split(', ').map(function(subitem) {
                        return parseFloat(subitem);
                    }));
                    return item;
                })
                let review = this.inside([lng, lat], array)
                if (review === true) {
                    sessionStorage.setItem('cityID', data.id)
                    dataPremise = data.premises;
                    this.updateMinimalPrice(data.minimalPrice, data.deliveryPrice)
                }
            })
            if (JSON.parse(localStorage.getItem("location")).address_components[0].long_name === 'Rydygiera') {
                sessionStorage.setItem('street', 'Rydygiera 12');
            }
            this.handleClicked(dataPremise)
        }
    }

    async handleClicked(premise) {
        if (premise !== 'empty') {
            const isPremiseOpened = await self.checkIsPremiseOpen(premise);
            sessionStorage.setItem('streetFull', document.querySelector('#placesMap').value)

            // let number = document.querySelector('#placesNumberMap').value;
            // sessionStorage.setItem('streetNumber', number);

            setTimeout(function(){
                self.setState({
                    isModalOpened: !isPremiseOpened,
                    premise: premise

                }, () => {
                    if (isPremiseOpened) self.redirectToSelectedPremise()
                });
            }, 1);

        } else {
            self.setState({premiseNotFound: true});
        }
    }

    async openPremise(e, premise) {
        e.preventDefault();
        this.setDefaultPrice();
        const premiseInfo = await this.getPremise(premise);
        if (typeof premiseInfo.name !== 'undefined') {
            const isPremiseOpened = await self.checkIsPremiseOpen(premiseInfo);
            self.setState({
                isModalOpened: !isPremiseOpened,
                premise: premiseInfo

            }, () => {
                if (isPremiseOpened) self.redirectToSelectedPremise()
            });
        }
    }

    async activePremise(premise) {
        const premiseInfo = await this.getPremise(premise);
        let status = false;
        if (typeof premiseInfo.name !== 'undefined') {
            const isPremiseOpened = await self.checkIsPremiseOpen(premiseInfo);
            let activePremises = this.state.activePremises;
            if (premise === 1051) {
                activePremises[0] = isPremiseOpened;
            } else if (premise === 1551) {
                activePremises[1] = isPremiseOpened;
            } else if (premise === 1552) {
                activePremises[2] = isPremiseOpened;
            } else if (premise === 1553) {
                activePremises[3] = isPremiseOpened;
            }
            this.setState({
                activePremises: activePremises
            })
        }
    }

    scrollIt = (element) => {
        window.scrollTo({
            behavior: "smooth",
            left: 0,
            top: window.pageYOffset + element.getBoundingClientRect().top - 100,
        });
    };

    changeCategory = (e, name) => {
        e.preventDefault();
        this.setState({
            activeCategory: name,
        });
        sessionStorage.setItem('homeCategory', name);
        if (e.target.getAttribute("title") !== "active") {
            let categories = document.querySelectorAll(".cat-item a");
            for (let i = 0; i < categories.length; i++) {
                categories[i].removeAttribute("title");
            }
            e.target.setAttribute("title", "active");
        }
        // this.scrollIt(document.querySelector("#pizzamenu"));
        Connect(
            `${endpoint.backend}${
                endpoint.getAllPizzasAndProducts
            }/${this.state.homePremise}/${name}`,
            "GET",
            null
        ).then((response) => {
            const d = new Date();
            response.map((item) => {
                if (item.specialOffer) {
                    let day = d.getDay();
                    let hour = d.getHours();
                    let minute = d.getMinutes();
                    let visible = false;
                    let tempSpecialTimes = [];
                    item.specialOffer.specialOfferTimes.map((itemik) => {
                        if (itemik.dayName === 'Poniedziałek') {
                            tempSpecialTimes.push(itemik);
                        }
                        return true;
                    })
                    item.specialOffer.specialOfferTimes.map((itemik) => {
                        if (itemik.dayName === 'Wtorek') {
                            tempSpecialTimes.push(itemik);
                        }
                        return true;
                    })
                    item.specialOffer.specialOfferTimes.map((itemik) => {
                        if (itemik.dayName === 'Środa') {
                            tempSpecialTimes.push(itemik);
                        }
                        return true;
                    })
                    item.specialOffer.specialOfferTimes.map((itemik) => {
                        if (itemik.dayName === 'Czwartek') {
                            tempSpecialTimes.push(itemik);
                        }
                        return true;
                    })
                    item.specialOffer.specialOfferTimes.map((itemik) => {
                        if (itemik.dayName === 'Piątek') {
                            tempSpecialTimes.push(itemik);
                        }
                        return true;
                    })
                    item.specialOffer.specialOfferTimes.map((itemik) => {
                        if (itemik.dayName === 'Sobota') {
                            tempSpecialTimes.push(itemik);
                        }
                        return true;
                    })
                    item.specialOffer.specialOfferTimes.map((itemik) => {
                        if (itemik.dayName === 'Niedziela') {
                            tempSpecialTimes.push(itemik);
                        }
                        return true;
                    })
                    item.specialOffer.specialOfferTimes = tempSpecialTimes;
                    item.specialOffer.specialOfferTimes.map((itemik) => {
                        if ((itemik.dayName === 'Niedziela') && (day === 0)) {
                            let dayStartHour = itemik.startHour.substring(0, 2);
                            let dayStartMinute = itemik.startHour.substring(3, 5);
                            let dayEndHour = itemik.endHour.substring(0, 2);
                            let dayEndMinute = itemik.endHour.substring(3, 5);
                            if ((parseInt(dayStartHour) <= hour) && parseInt(dayEndHour) >= hour) {
                                visible = true;
                                if ((parseInt(dayStartHour) === hour) && (parseInt(dayStartMinute) > minute)) {
                                    visible = false;
                                }
                                if ((parseInt(dayEndHour) === hour) && (parseInt(dayEndMinute) < minute)) {
                                    visible = false;
                                }
                            }
                        }
                        if ((itemik.dayName === 'Poniedziałek') && (day === 1)) {
                            let dayStartHour = itemik.startHour.substring(0, 2);
                            let dayStartMinute = itemik.startHour.substring(3, 5);
                            let dayEndHour = itemik.endHour.substring(0, 2);
                            let dayEndMinute = itemik.endHour.substring(3, 5);
                            if ((parseInt(dayStartHour) <= hour) && parseInt(dayEndHour) >= hour) {
                                visible = true;
                                if ((parseInt(dayStartHour) === hour) && (parseInt(dayStartMinute) > minute)) {
                                    visible = false;
                                }
                                if ((parseInt(dayEndHour) === hour) && (parseInt(dayEndMinute) < minute)) {
                                    visible = false;
                                }
                            }
                        }
                        if ((itemik.dayName === 'Wtorek') && (day === 2)) {
                            let dayStartHour = itemik.startHour.substring(0, 2);
                            let dayStartMinute = itemik.startHour.substring(3, 5);
                            let dayEndHour = itemik.endHour.substring(0, 2);
                            let dayEndMinute = itemik.endHour.substring(3, 5);
                            if ((parseInt(dayStartHour) <= hour) && parseInt(dayEndHour) >= hour) {
                                visible = true;
                                if ((parseInt(dayStartHour) === hour) && (parseInt(dayStartMinute) > minute)) {
                                    visible = false;
                                }
                                if ((parseInt(dayEndHour) === hour) && (parseInt(dayEndMinute) < minute)) {
                                    visible = false;
                                }
                            }
                        }
                        if ((itemik.dayName === 'Środa') && (day === 3)) {
                            let dayStartHour = itemik.startHour.substring(0, 2);
                            let dayStartMinute = itemik.startHour.substring(3, 5);
                            let dayEndHour = itemik.endHour.substring(0, 2);
                            let dayEndMinute = itemik.endHour.substring(3, 5);
                            if ((parseInt(dayStartHour) <= hour) && parseInt(dayEndHour) >= hour) {
                                visible = true;
                                if ((parseInt(dayStartHour) === hour) && (parseInt(dayStartMinute) > minute)) {
                                    visible = false;
                                }
                                if ((parseInt(dayEndHour) === hour) && (parseInt(dayEndMinute) < minute)) {
                                    visible = false;
                                }
                            }
                        }
                        if ((itemik.dayName === 'Czwartek') && (day === 4)) {
                            let dayStartHour = itemik.startHour.substring(0, 2);
                            let dayStartMinute = itemik.startHour.substring(3, 5);
                            let dayEndHour = itemik.endHour.substring(0, 2);
                            let dayEndMinute = itemik.endHour.substring(3, 5);
                            if ((parseInt(dayStartHour) <= hour) && parseInt(dayEndHour) >= hour) {
                                visible = true;
                                if ((parseInt(dayStartHour) === hour) && (parseInt(dayStartMinute) > minute)) {
                                    visible = false;
                                }
                                if ((parseInt(dayEndHour) === hour) && (parseInt(dayEndMinute) < minute)) {
                                    visible = false;
                                }
                            }
                        }
                        if ((itemik.dayName === 'Piątek') && (day === 5)) {
                            let dayStartHour = itemik.startHour.substring(0, 2);
                            let dayStartMinute = itemik.startHour.substring(3, 5);
                            let dayEndHour = itemik.endHour.substring(0, 2);
                            let dayEndMinute = itemik.endHour.substring(3, 5);
                            if ((parseInt(dayStartHour) <= hour) && parseInt(dayEndHour) >= hour) {
                                visible = true;
                                if ((parseInt(dayStartHour) === hour) && (parseInt(dayStartMinute) > minute)) {
                                    visible = false;
                                }
                                if ((parseInt(dayEndHour) === hour) && (parseInt(dayEndMinute) < minute)) {
                                    visible = false;
                                }
                            }
                        }
                        if ((itemik.dayName === 'Sobota') && (day === 6)) {
                            let dayStartHour = itemik.startHour.substring(0, 2);
                            let dayStartMinute = itemik.startHour.substring(3, 5);
                            let dayEndHour = itemik.endHour.substring(0, 2);
                            let dayEndMinute = itemik.endHour.substring(3, 5);
                            if ((parseInt(dayStartHour) <= hour) && parseInt(dayEndHour) >= hour) {
                                visible = true;
                                if ((parseInt(dayStartHour) === hour) && (parseInt(dayStartMinute) > minute)) {
                                    visible = false;
                                }
                                if ((parseInt(dayEndHour) === hour) && (parseInt(dayEndMinute) < minute)) {
                                    visible = false;
                                }
                            }
                        }
                        return true;
                    })
                    item.visible = visible;
                }
                return true;
            })
            this.setState({
                premises: response,
            });
        });
    };

    homeAddToCart = (e, id) => {
        e.preventDefault();
        this.setState({
            popup: true,
            special: false,
            id
        })
        let self = this;
        setTimeout(function () {
            self.loadScript();
        }, 500);
    }

    homeAddToCartSpecial = (e, id) => {
        e.preventDefault();
        this.setState({
            popup: true,
            special: true,
            id
        })
        let self = this;
        setTimeout(function () {
            self.loadScript();
        }, 500);
    }

    handlePopup = (value) => {
        this.setState({
            popup: value
        })
    }

    render() {
        return (
            <>
                {/*<Login />*/}
                <Helmet>
                    <title>Pizza Bunga</title>
                    <meta name="description" content="Gorąca pizza z puszystym ciastem, szybka i darmowa dostawa we Wrocławiu! Zamów pyszne pizze z dowozem do domu i ciesz się wyjątkowym smakiem już dziś!" />
                    <meta property="og:title" content="Pizza Bunga" />
                    <meta property="og:description" content="Gorąca pizza z puszystym ciastem, szybka i darmowa dostawa we Wrocławiu! Zamów pyszne pizze z dowozem do domu i ciesz się wyjątkowym smakiem już dziś!" />
                    <meta property="og:image" content="https://pizzabunga.pl/static/media/logo.cf443991.svg" />
                    <meta property="og:url" content="https://www.pizzabunga.pl/" />
                </Helmet>
                <div className="welcome-page">

                    {this.state.isModalOpened &&
                        <CustomModal
                            heading="Komunikat"
                            text={`Lokal ${this.state.premise.name.replace('-', ' ')} jest zamknięty. Prosimy spróbować ponownie później.`}
                            handleCloseModal={() => { this.setState({ isModalOpened: !this.state.isModalOpened, popup: false })}}
                        />
                    }

                    {this.state.premiseNotFound &&
                        <CustomModal
                            heading="Komunikat"
                            text="Nie znaleziono lokalu przypisanego do podanej lokalizacji."
                            handleCloseModal={() => { this.setState({ premiseNotFound: !this.state.premiseNotFound })}}
                        />
                    }

                    <Navbar sum={this.props.sum} counter={this.props.counter} summary={this.props.summary} />
                    <section className="welcome-page__section">
                        <div className="desktop">
                            <h1 className="welcome-page__header text--large--2 text__w600">Witaj w Pizza Bunga</h1>
                        </div>
                        <div className="mobile">
                            <img src={image} />
                            <p>Zamów online</p>
                        </div>
                        {this.state.popup !== true && <Welcome cities={this.state.cities} handleClick={this.handleClick} popup={false} />}
                        <form id="wppizza-dbp-form" className="wppizza-dbp-form">
                            <fieldset className="wppizza-dbp-areas">
                                <legend className="wppizza-order-label-required">WYBIERZ OSIEDLE DOSTAWY:</legend>
                                <div className="wppizza-dbp-area-submit"><input type="button" className="submit"
                                                                                id="wppizza-dbp-area-tb-set" value="ok" />
                                </div>
                            </fieldset>
                        </form>
                    </section>
                </div>
                <section className="welcome-page__premises">
                    <div className="menu-section__header">
                        <h1 className="text text__w700 text__large--2">Nasze menu</h1>
                        <ul id="wppizza-categories-1" className="wppizza-categories">
                            {this.state.categories.map((data, index) => (
                                <li className="cat-item cat-item-56">
                                    <a
                                        onClick={(e) => this.changeCategory(e, data.id)}
                                        href="#"
                                        title={index === 0 ? "active" : ""}
                                        data-id={data.id}
                                    >
                                        {data.name}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="menu-section__products main home">
                        {this.state.premises &&
                            this.state.premises.map((data) => (
                                <article
                                    id={data.pizza
                                        ? data.pizza.id
                                        : data.product
                                            ? data.product.id
                                            : data.specialOffer.id}
                                    className={data.specialOffer && data.specialOffer.active === false ? "d-none" : "wppizza-article wppizza-article-default  wppizza-article-first wppizza-article-speciale wppizza-article-speciale-53 wppizza_menu-speciale wppizza_menu-term-53 wppizza_menu-mieso wppizza_menu-term-48 wppizza_menu-lokal-tarnogaj wppizza_menu-term-57 wppizza_menu-lokal-srodmiescie wppizza_menu-term-58 wppizza_menu-lokal-gadow wppizza_menu-term-59 wppizza_menu-lokal-grabiszynska wppizza_menu-term-60 entry-content post-454 wppizza type-wppizza status-publish has-post-thumbnail hentry"}
                                >
                                    <div className="visibleDate d-none">
                                        {data.specialOffer && data.specialOffer.specialOfferTimes.map((data) => (
                                            <>
                                                <p style={{display: "flex", lineHeight: '25px'}}>
                                                    <span style={{width: 150}}>{data.dayName}:</span>
                                                    <span>{data.startHour} - {data.endHour}</span>
                                                </p>
                                            </>
                                        ))}
                                    </div>
                                    <div
                                        id="wppizza-article-img-454"
                                        className="wppizza-article-image"
                                    >
                                        {data.pizza && data.pizza.image ? (
                                            <img
                                                width="382"
                                                height="290"
                                                src={data.pizza && data.pizza.image}
                                                className={
                                                    data.pizza &&
                                                    data.pizza.categories &&
                                                    data.pizza.categories[0].id === 1252
                                                        ? "wppizza-article-image-thumb wp-post-image drink"
                                                        : "wppizza-article-image-thumb wp-post-image"
                                                }
                                                alt=""
                                                title={data.pizza.name}
                                                srcSet={data.pizza && data.pizza.image}
                                                sizes="(max-width: 382px) 100vw, 382px"
                                            />
                                        ) : data.product && data.product.image ? (
                                            <img
                                                width="382"
                                                height="290"
                                                src={data.product && data.product.image}
                                                className={
                                                    data.product &&
                                                    data.product.categories &&
                                                    data.product.categories[0] &&
                                                    data.product.categories[0].id === 1252
                                                        ? "wppizza-article-image-thumb wp-post-image drink"
                                                        : "wppizza-article-image-thumb wp-post-image"
                                                }
                                                alt=""
                                                title={data.product.name}
                                                srcSet={data.product && data.product.image}
                                                sizes="(max-width: 382px) 100vw, 382px"
                                            />
                                        ) : (
                                            data.specialOffer && (
                                                <img
                                                    width="382"
                                                    height="290"
                                                    src={data.specialOffer && data.specialOffer.image}
                                                    className={
                                                        data.specialOffer &&
                                                        data.specialOffer.categories &&
                                                        data.specialOffer.categories[0].id === 1252
                                                            ? "wppizza-article-image-thumb wp-post-image drink"
                                                            : "wppizza-article-image-thumb wp-post-image"
                                                    }
                                                    alt=""
                                                    title={data.specialOffer.name}
                                                    srcSet={data.specialOffer && data.specialOffer.image}
                                                    sizes="(max-width: 382px) 100vw, 382px"
                                                />
                                            )
                                        )}
                                    </div>
                                    <div>
                                        <h2 className="wppizza-article-h2">
                      <span className="wppizza-article-title">
                        {data.pizza
                            ? data.pizza.name
                            : data.product
                                ? data.product.name
                                : data.specialOffer.name}
                      </span>
                                        </h2>
                                        <div className="wppizza-article-sizes wppizza-article-prices-2">
                                            <ul>
                                                <li className="wppizza-article-prices">
                                                    {data.pizza && data.pizza.sizes ? (
                                                        <ul>
                                                            <li
                                                                id={
                                                                    data.pizza.sizes[0] && data.pizza.sizes[0].id
                                                                }
                                                                className="wppizza-article-price wppizza-article-price-2-0 wppizza-price-454-0 wppizza-add-to-cart"
                                                                title="do koszyka"
                                                            >
                                <span className="">
                                  {data.pizza.sizes[0] &&
                                      data.pizza.sizes[0].price}{" "}
                                    zł
                                </span>
                                                                <div className="wppizza-article-size">
                                                                    {data.pizza.sizes[0] &&
                                                                        data.pizza.sizes[0].name}
                                                                </div>
                                                            </li>
                                                            <li
                                                                id={
                                                                    data.pizza.sizes[1] && data.pizza.sizes[1].id
                                                                }
                                                                className="wppizza-article-price wppizza-article-price-2-1 wppizza-price-454-1 wppizza-add-to-cart"
                                                                title="do koszyka"
                                                            >
                                <span className="">
                                  {data.pizza.sizes[1] &&
                                      data.pizza.sizes[1].price}{" "}
                                    zł
                                </span>
                                                                <div className="wppizza-article-size">
                                                                    {data.pizza.sizes[1] &&
                                                                        data.pizza.sizes[1].name}
                                                                </div>
                                                            </li>
                                                            <li
                                                                id={
                                                                    data.pizza.sizes[2] && data.pizza.sizes[2].id
                                                                }
                                                                className="wppizza-article-price wppizza-article-price-2-2 wppizza-price-454-2 wppizza-add-to-cart"
                                                                title="do koszyka"
                                                            >
                                <span className="">
                                  {data.pizza.sizes[2] &&
                                      data.pizza.sizes[2].price}{" "}
                                    zł
                                </span>
                                                                <div className="wppizza-article-size">
                                                                    {data.pizza.sizes[2] &&
                                                                        data.pizza.sizes[2].name}
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    ) : data.product ? (
                                                        <ul>
                                                            <li
                                                                id={
                                                                    data.product.sizes[0] &&
                                                                    data.product.sizes[0].id
                                                                }
                                                                className="wppizza-article-price wppizza-article-price-2-0 wppizza-price-454-0 wppizza-add-to-cart"
                                                                title="do koszyka"
                                                            >
                                <span className="">
                                  {data.product.sizes[0] &&
                                      data.product.sizes[0].price}{" "}
                                    zł
                                </span>
                                                                <div className="wppizza-article-size">
                                                                    {data.product.sizes[0] &&
                                                                        data.product.sizes[0].name}
                                                                </div>
                                                            </li>
                                                            <li
                                                                id={
                                                                    data.product.sizes[1] &&
                                                                    data.product.sizes[1].id
                                                                }
                                                                className="wppizza-article-price wppizza-article-price-2-1 wppizza-price-454-1 wppizza-add-to-cart"
                                                                title="do koszyka"
                                                            >
                                <span className="">
                                  {data.product.sizes[1] &&
                                      data.product.sizes[1].price}{" "}
                                    zł
                                </span>
                                                                <div className="wppizza-article-size">
                                                                    {data.product.sizes[1] &&
                                                                        data.product.sizes[1].name}
                                                                </div>
                                                            </li>
                                                            <li
                                                                id={
                                                                    data.product.sizes[2] &&
                                                                    data.product.sizes[2].id
                                                                }
                                                                className="wppizza-article-price wppizza-article-price-2-2 wppizza-price-454-2 wppizza-add-to-cart"
                                                                title="do koszyka"
                                                            >
                                <span className="">
                                  {data.product.sizes[2] &&
                                      data.product.sizes[2].price}{" "}
                                    zł
                                </span>
                                                                <div className="wppizza-article-size">
                                                                    {data.product.sizes[2] &&
                                                                        data.product.sizes[2].name}
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    ) : (
                                                        <ul>
                                                            <li
                                                                id={data.specialOffer && data.specialOffer.id}
                                                                className="wppizza-article-price wppizza-article-price-2-0 wppizza-price-454-0 wppizza-add-to-cart"
                                                                title="do koszyka"
                                                            >
                                                                <span className="">
                                  {data.specialOffer && data.specialOffer.price}{" "}
                                                                    zł
                                </span>
                                                                <div className="wppizza-article-size">
                                                                    {data.specialOffer && data.specialOffer.name}
                                                                </div>
                                                            </li>
                                                            <li
                                                                id={data.specialOffer && data.specialOffer.id}
                                                                className="wppizza-article-price wppizza-article-price-2-1 wppizza-price-454-1 wppizza-add-to-cart"
                                                                title="do koszyka"
                                                            >
                                <span className="">
                                  {data.specialOffer && data.specialOffer.price}{" "}
                                    zł
                                </span>
                                                                <div className="wppizza-article-size">
                                                                    {data.specialOffer && data.specialOffer.name}
                                                                </div>
                                                            </li>
                                                            <li
                                                                id={data.specialOffer && data.specialOffer.id}
                                                                className="wppizza-article-price wppizza-article-price-2-2 wppizza-price-454-2 wppizza-add-to-cart"
                                                                title="do koszyka"
                                                            >
                                <span className="">
                                  {data.specialOffer && data.specialOffer.price}{" "}
                                    zł
                                </span>
                                                                <div className="wppizza-article-size">
                                                                    {data.specialOffer && data.specialOffer.name}
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    )}
                                                </li>
                                            </ul>
                                        </div>
                                        <p className="wppizza-article-content">
                                            {data.pizza &&
                                                data.pizza.ingriedients &&
                                                data.pizza.ingriedients.map((item) =>
                                                    item.type === "SAUCE" ? (
                                                        <div>{item.name + ","}</div>
                                                    ) : (
                                                        ""
                                                    )
                                                )
                                            }
                                            {data.pizza &&
                                                data.pizza.ingriedients &&
                                                data.pizza.ingriedients.map((item) =>
                                                    item.type === "CHEESE" ? (
                                                        <div>{item.name + ","}</div>
                                                    ) : (
                                                        ""
                                                    )
                                                )
                                            }
                                            {data.pizza &&
                                                data.pizza.ingriedients &&
                                                data.pizza.ingriedients.map((item, i, row) =>
                                                    item.type !== "SAUCE" && item.type !== "CHEESE" ? (
                                                        i + 1 === row.length ? (
                                                            <div>{item.name}</div>
                                                        ) : (
                                                            i + 2 === row.length ? (
                                                                <div>{item.name + ","}</div>
                                                            ) : (
                                                                i + 3 === row.length ? (
                                                                    <div>{item.name}</div>
                                                                ) : (
                                                                    <div>{item.name + ","}</div>
                                                                )
                                                            )
                                                        )
                                                    ) : (
                                                        ""
                                                    )
                                                )
                                            }
                                            {data.product
                                                ? data.product.description
                                                : data.specialOffer && data.specialOffer.description}
                                        </p>
                                        {data.specialOffer && data.specialOffer.id !== undefined ? (
                                            <Link
                                                to={`/specjalna-oferta/${data.specialOffer.id}`}
                                                onClick={(e) =>
                                                    this.homeAddToCartSpecial(
                                                        e,
                                                        data.specialOffer.id
                                                    )
                                                }
                                                className="menu-item__button"
                                            >
                                                <div className="menu-item__sticks-box">
                                                    <span className="menu-item__vertical-stick"></span>
                                                    <span className="menu-item__horizontal-stick"></span>
                                                </div>
                                            </Link>
                                        ) : (
                                            <Link
                                                to={`/wlasna-pizza/${data.pizza && data.pizza.id}`}
                                                onClick={(e) =>
                                                    this.homeAddToCart(
                                                        e,
                                                        data.pizza !== null ? data.pizza.id : data.product.id
                                                    )
                                                }
                                                className="menu-item__button"
                                            >
                                                <div className="menu-item__sticks-box">
                                                    <span className="menu-item__vertical-stick"></span>
                                                    <span className="menu-item__horizontal-stick"></span>
                                                </div>
                                            </Link>
                                        )}
                                        {data.pizza && data.pizza.sizes ? (
                                            <form className="menu-item__size-menu" action="#">
                                                <label
                                                    className="menu-item__size menu-item__size--active"
                                                    data-size="1"
                                                    htmlFor="size1"
                                                    onClick={(e) => this.changeSize(e)}
                                                    data-ingredients="false"
                                                >
                                                    {data.pizza.sizes[0] && data.pizza.sizes[0].name}
                                                </label>
                                                <input
                                                    className="menu-item__size-input"
                                                    check=""
                                                    id="size1"
                                                    type="checkbox"
                                                />
                                                {data.pizza.sizes[1] ? (
                                                    <label
                                                        className="menu-item__size "
                                                        data-size="2"
                                                        htmlFor="size2"
                                                        onClick={(e) => this.changeSize(e)}
                                                        data-ingredients="false"
                                                    >
                                                        {data.pizza.sizes[1] && data.pizza.sizes[1].name}
                                                    </label>
                                                ) : (
                                                    ""
                                                )}
                                                <input
                                                    className="menu-item__size-input"
                                                    check=""
                                                    id="size2"
                                                    type="checkbox"
                                                />
                                                {data.pizza.sizes[2] ? (
                                                    <label
                                                        className="menu-item__size "
                                                        data-size="3"
                                                        htmlFor="size3"
                                                        onClick={(e) => this.changeSize(e)}
                                                        data-ingredients="false"
                                                    >
                                                        {data.pizza.sizes[2] && data.pizza.sizes[2].name}
                                                    </label>
                                                ) : (
                                                    ""
                                                )}
                                                <input
                                                    className="menu-item__size-input"
                                                    check=""
                                                    id="size3"
                                                    type="checkbox"
                                                />
                                            </form>
                                        ) : null}
                                        {data.product && data.product.sizes ? (
                                            <form className="menu-item__size-menu" action="#">
                                                <label
                                                    className="menu-item__size menu-item__size--active"
                                                    data-size="1"
                                                    htmlFor="size1"
                                                    onClick={(e) => this.changeSize(e)}
                                                    data-ingredients="false"
                                                >
                                                    {data.product.sizes[0] && data.product.sizes[0].name}
                                                </label>
                                                <input
                                                    className="menu-item__size-input"
                                                    check=""
                                                    id="size1"
                                                    type="checkbox"
                                                />
                                                {data.product.sizes[1] ? (
                                                    <label
                                                        className="menu-item__size "
                                                        data-size="2"
                                                        htmlFor="size2"
                                                        onClick={(e) => this.changeSize(e)}
                                                        data-ingredients="false"
                                                    >
                                                        {data.product.sizes[1] &&
                                                            data.product.sizes[1].name}
                                                    </label>
                                                ) : (
                                                    ""
                                                )}
                                                <input
                                                    className="menu-item__size-input"
                                                    check=""
                                                    id="size2"
                                                    type="checkbox"
                                                />
                                                {data.product.sizes[2] ? (
                                                    <label
                                                        className="menu-item__size "
                                                        data-size="3"
                                                        htmlFor="size3"
                                                        onClick={(e) => this.changeSize(e)}
                                                        data-ingredients="false"
                                                    >
                                                        {data.product.sizes[2] &&
                                                            data.product.sizes[2].name}
                                                    </label>
                                                ) : (
                                                    ""
                                                )}
                                                <input
                                                    className="menu-item__size-input"
                                                    check=""
                                                    id="size3"
                                                    type="checkbox"
                                                />
                                            </form>
                                        ) : null}
                                        {data.pizza && data.pizza.sizes ? (
                                            <span
                                                price=""
                                                className="menu-item__price text__w600"
                                                data-size="1"
                                            >
                        {data.pizza.sizes[0] &&
                            data.pizza.sizes[0].price
                                .toFixed(2)
                                .replace(".", ",")}{" "}
                                                zł
                      </span>
                                        ) : data.product ? (
                                            <span
                                                price=""
                                                className="menu-item__price text__w600"
                                                data-size="1"
                                            >
                        {data.product.sizes[0] &&
                            data.product.sizes[0].price
                                .toFixed(2)
                                .replace(".", ",")}{" "}
                                                zł
                      </span>
                                        ) : (
                                            <span
                                                price=""
                                                className="menu-item__price text__w600"
                                                data-size="1"
                                            >
                        <span className="price-offer-before">
                          {data.specialOffer && data.specialOffer.priceBefore &&
                              data.specialOffer.priceBefore
                                  .toFixed(2)
                                  .replace(".", ",")}{" "}
                            zł
                        </span>
                                                {data.specialOffer && data.specialOffer.price &&
                                                    data.specialOffer.price
                                                        .toFixed(2)
                                                        .replace(".", ",")}{" "}
                                                zł
                      </span>
                                        )}
                                    </div>
                                </article>
                            ))}
                        {this.state.premises.length === 0 ? (
                            <div className="emptyProduct active">
                                Brak dostępnych produktów w danej kategorii
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                    <div className="welcome-page__premises-intro d-none">
                        <h2>lub wybierz lokal z listy</h2>
                        <div className="arrow__content">
                            <div className="arrowDown" />
                        </div>
                    </div>
                    <div className="welcome-page__premises-content">
                        <div className="premises__content d-none">
                            <div className="premise">
                                <Link to="/fat/" onClick={(e) => this.openPremise(e,1051)}>
                                    <h3>Grabiszyn</h3>
                                </Link>
                                <a href="https://www.google.com/maps?q=ul.+In%C5%BCynierska+27a+Wroc%C5%82aw&um=1&ie=UTF-8&sa=X&ved=2ahUKEwi4tMDvi6r2AhUPvosKHVL2BckQ_AUoAXoECAEQAw" target="_blank" rel="noopener noreferrer">ul. Inżynierska 27a</a>
                                <a href="tel:608342942">Tel. 608 342 942</a>
                                <div className="accentBox">
                                    <Link to="/fat/" onClick={(e) => this.openPremise(e,1051)} className="accent">{this.state.activePremises[0] === true ? 'Open' : 'Closed'}</Link>
                                </div>
                            </div>
                            <div className="premise">
                                <Link to="/nowy-dwor/" onClick={(e) => this.openPremise(e,1551)}>
                                    <h3>Gądów</h3>
                                </Link>
                                <a href="https://www.google.com/maps/place/Bulwar+Dedala+18B,+54-130+Wroc%C5%82aw/@51.127276,16.9647283,17z/data=!3m1!4b1!4m5!3m4!1s0x470fea73fe3a84a1:0x8159a0a2af1877f1!8m2!3d51.127276!4d16.966917" target="_blank" rel="noopener noreferrer">ul. Bulwar Dedala 18b</a>
                                <a href="tel:513741320">Tel. 513 741 320</a>
                                <div className="accentBox">
                                    <Link to="/nowy-dwor/" onClick={(e) => this.openPremise(e,1551)} className="accent">{this.state.activePremises[1] === true ? 'Open' : 'Closed'}</Link>
                                </div>
                            </div>
                            <div className="premise">
                                <Link to="/lokal/srodmiescie/" onClick={(e) => this.openPremise(e, 1552)}>
                                    <h3>Śródmieście</h3>
                                </Link>
                                <a href="https://www.google.com/maps/place/Ludwika+Rydygiera+34,+30-001+Krak%C3%B3w/@50.0055736,20.0025798,17z/data=!3m1!4b1!4m5!3m4!1s0x47164375b5f01739:0x66f13d0594977bc8!8m2!3d50.0055736!4d20.0047685" target="_blank" rel="noopener noreferrer">ul. Rydygiera 34</a>
                                <a href="tel:508603460">Tel. 508 603 460</a>
                                <div className="accentBox">
                                    <Link to="/srodmiescie/" onClick={(e) => this.openPremise(e, 1552)} className="accent">{this.state.activePremises[2] === true ? 'Open' : 'Closed'}</Link>
                                </div>
                            </div>
                            <div className="premise">
                                <Link to="/lokal/tarnogaj/" onClick={(e) => this.openPremise(e,1553)}>
                                    <h3>Tarnogaj</h3>
                                </Link>
                                <a href="https://www.google.com/maps?q=ul.+Gazowa+88&um=1&ie=UTF-8&sa=X&ved=2ahUKEwj2kpGd_qn2AhVphosKHYUJCJcQ_AUoAXoECAIQAw" target="_blank" rel="noopener noreferrer">ul. Gazowa 88</a>
                                <a href="tel:538484000">Tel. 538 484 000</a>
                                <div className="accentBox">
                                    <Link to="/tarnogaj/" onClick={(e) => this.openPremise(e,1553)} className="accent">{this.state.activePremises[3] === true ? 'Open' : 'Closed'}</Link>
                                </div>
                            </div>
                        </div>
                        <div className="arrow__content second">
                            <div className="arrowDown" />
                        </div>
                        <h2 className="description__header">Dlaczego Pizza Bunga</h2>
                        <div className="welcome__description">
                            <div className="welcome__description-text desktop">
                                <p>Bo wiemy jak łączyć <b>włoską pasję</b> z pizzą w <b>stylu amerykańskim,<br /> Od 2011 roku</b> zdobywamy Wrocław otwierając kolejne punkty.</p>
                                <p>Swoją tożsamość budujemy w oparciu o zespół zdolnych ludzi i surowce wysokiej jakosci.</p>
                                <p>Cenią nas za puszyste ciasto, świetne dodatki, własne sosy i szybką dostawę. Jako jedni z nielicznych dowóz do domu<br /> dla wiekszości miasta wciąz oferujemy gratis.</p>
                                <p>W menu proponujemy klasyczne pozycje, jak również niepowtarzalne<br /> chorizo z miodem, trufle ze szpinakiem czy <b>whisky bbq.</b></p>
                                <p>Dla fanów ostrosci wyjqtkowa pizza Carolina<br /> z <b>najostrzejsza papryką</b> na swiecie.</p>
                            </div>
                            <div className="welcome__description-text mobile">
                                <p>Bo wiemy jak łączyć <b>włoską pasję</b> z pizzą w <b>stylu amerykańskim,<br /> Od 2011 roku</b> zdobywamy Wrocław otwierając kolejne punkty.</p>
                                <p>Swoją tożsamość budujemy w oparciu o zespół zdolnych ludzi i surowce wysokiej jakosci.</p>
                                <p>Cenią nas za puszyste ciasto, świetne dodatki, własne sosy i szybką dostawę. Jako jedni z nielicznych dowóz do domu<br /> dla wiekszości miasta wciąz oferujemy gratis.</p>
                                <p>W menu proponujemy klasyczne pozycje, jak również niepowtarzalne<br /> chorizo z miodem, trufle ze szpinakiem czy <b>whisky bbq.</b></p>
                                <p>Dla fanów ostrosci wyjqtkowa pizza Carolina<br /> z <b>najostrzejsza papryką</b> na swiecie.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="background-index">
                    <div className="background-index__container">
                        <div className="background-index__top" title="shape top">&nbsp;</div>
                        <div className="background-index__pizza" title="pizza">&nbsp;</div>
                        <div className="background-index__pepper" title="pepper">&nbsp;</div>
                        <div className="background-index__tomatoes" title="tomatoes">&nbsp;</div>
                        <div className="background-index__basil" title="basil">&nbsp;</div>
                        <div className="background-index__basil-mobile" title="basil">&nbsp;</div>
                        <div className="background-index__bottom" title="bottom shape">&nbsp;</div>
                        <div className="background-index__tomato" title="tomato">&nbsp;</div>
                    </div>
                </div>
                <div className="premises">
                    <div className="premises__item" data-name="Grabiszyn"
                         data-href="https://pizzabunga.pl/fat/"
                         data-coordinates="51.095481608181416, 16.98021647114434" data-address="ul. Inżynierska 27a">
                        <a href="https://pizzabunga.pl/fat/?loader=true"
                           className="premises__link"></a>
                    </div>
                    <div className="premises__item" data-name="Gądów"
                         data-href="https://pizzabunga.pl/nowy-dwor/"
                         data-coordinates="51.127420746091055, 16.96695991347514" data-address="ul. Bulwar Dedala 18b">
                        <a href="https://pizzabunga.pl/nowy-dwor/?loader=true"
                           className="premises__link"></a>
                    </div>
                    <div className="premises__item" data-name="Śródmieście"
                         data-href="https://pizzabunga.pl/srodmiescie/"
                         data-coordinates="51.119995467736786, 17.032334113633517" data-address="ul. Rydygiera 34">
                        <a href="https://pizzabunga.pl/srodmiescie/?loader=true"
                           className="premises__link"></a>
                    </div>
                    <div className="premises__item" data-name="Tarnogaj"
                         data-href="https://pizzabunga.pl/tarnogaj/"
                         data-coordinates="51.078728460391645, 17.068588069297455" data-address="ul. Gazowa 88">
                        <a href="https://pizzabunga.pl/tarnogaj/?loader=true"
                           className="premises__link"></a>
                    </div>
                </div>
                <div id="map" className="map"></div>
                <div id="iconMap" link="https://pizza-bunga.ultimate.systems/wp-content/themes/ultimatesystems/front/build/assets/marker.svg"></div>
                <div className="welcome-page__footer">
                    <Footer />
                </div>
                <Popup active={this.state.popup} cities={this.state.cities} handleClick={this.handleClick} handlePopup={this.handlePopup} empty={this.state.empty} handleIgnore={this.handleIgnore} handleEmpty={this.handleEmpty} />
            </>
        )
    }
}

export default withRouter(Home);