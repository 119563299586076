import React, { Component } from "react";
import Navbar from "../components/navbar/Navbar";
import {Link, withRouter} from "react-router-dom";
import Checkbox from "../components/checkbox/Checkbox";
import { Connect } from "../components/connect/Connect";
import * as endpoint from "../endpoints/Endpoints";
import Login from "../components/logInPanel/login";
import trash from "../assets/trash.svg";
import Footer from "../components/footer/Footer";

class Place extends Component {
  state = {
    cart: [],
    categories: [],
    premises: [],
    qNumber: 0,
    activeCategory: 0,
    googlemaps:
      "https://www.google.com/maps?q=ul.+In%C5%BCynierska+27a+Wroc%C5%82aw&um=1&ie=UTF-8&sa=X&ved=2ahUKEwi4tMDvi6r2AhUPvosKHVL2BckQ_AUoAXoECAEQAw",
    history: null
  };
  componentDidMount() {
    if (sessionStorage.getItem('streetFull') === null) {
      window.location.href = `/`;
    }
    const { history } = this.props;
    Connect(
      `${endpoint.backend}${endpoint.getAllCategories}`,
      "GET",
      null
    ).then((response) => {
      this.setState({
        categories: response,
        history
      });
      Connect(
        `${endpoint.backend}${
          endpoint.getAllPizzasAndProducts
        }/${sessionStorage.getItem("lokalID")}/${response[0].id}`,
        "GET",
        null
      ).then((response) => {
        const d = new Date();
        response.map((item) => {
          if (item.specialOffer) {
            let day = d.getDay();
            let hour = d.getHours();
            let minute = d.getMinutes();
            let visible = false;
            item.specialOffer.specialOfferTimes.map((itemik) => {
              if ((itemik.dayName === 'Niedziela') && (day === 0)) {
                let dayStartHour = itemik.startHour.substring(0, 2);
                let dayStartMinute = itemik.startHour.substring(3, 5);
                let dayEndHour = itemik.endHour.substring(0, 2);
                let dayEndMinute = itemik.endHour.substring(3, 5);
                if ((parseInt(dayStartHour) <= hour) && parseInt(dayEndHour) >= hour) {
                  visible = true;
                  if ((parseInt(dayStartHour) === hour) && (parseInt(dayStartMinute) > minute)) {
                    visible = false;
                  }
                  if ((parseInt(dayEndHour) === hour) && (parseInt(dayEndMinute) < minute)) {
                    visible = false;
                  }
                }
              }
              if ((itemik.dayName === 'Poniedziałek') && (day === 1)) {
                let dayStartHour = itemik.startHour.substring(0, 2);
                let dayStartMinute = itemik.startHour.substring(3, 5);
                let dayEndHour = itemik.endHour.substring(0, 2);
                let dayEndMinute = itemik.endHour.substring(3, 5);
                if ((parseInt(dayStartHour) <= hour) && parseInt(dayEndHour) >= hour) {
                  visible = true;
                  if ((parseInt(dayStartHour) === hour) && (parseInt(dayStartMinute) > minute)) {
                    visible = false;
                  }
                  if ((parseInt(dayEndHour) === hour) && (parseInt(dayEndMinute) < minute)) {
                    visible = false;
                  }
                }
              }
              if ((itemik.dayName === 'Wtorek') && (day === 2)) {
                let dayStartHour = itemik.startHour.substring(0, 2);
                let dayStartMinute = itemik.startHour.substring(3, 5);
                let dayEndHour = itemik.endHour.substring(0, 2);
                let dayEndMinute = itemik.endHour.substring(3, 5);
                if ((parseInt(dayStartHour) <= hour) && parseInt(dayEndHour) >= hour) {
                  visible = true;
                  if ((parseInt(dayStartHour) === hour) && (parseInt(dayStartMinute) > minute)) {
                    visible = false;
                  }
                  if ((parseInt(dayEndHour) === hour) && (parseInt(dayEndMinute) < minute)) {
                    visible = false;
                  }
                }
              }
              if ((itemik.dayName === 'Środa') && (day === 3)) {
                let dayStartHour = itemik.startHour.substring(0, 2);
                let dayStartMinute = itemik.startHour.substring(3, 5);
                let dayEndHour = itemik.endHour.substring(0, 2);
                let dayEndMinute = itemik.endHour.substring(3, 5);
                if ((parseInt(dayStartHour) <= hour) && parseInt(dayEndHour) >= hour) {
                  visible = true;
                  if ((parseInt(dayStartHour) === hour) && (parseInt(dayStartMinute) > minute)) {
                    visible = false;
                  }
                  if ((parseInt(dayEndHour) === hour) && (parseInt(dayEndMinute) < minute)) {
                    visible = false;
                  }
                }
              }
              if ((itemik.dayName === 'Czwartek') && (day === 4)) {
                let dayStartHour = itemik.startHour.substring(0, 2);
                let dayStartMinute = itemik.startHour.substring(3, 5);
                let dayEndHour = itemik.endHour.substring(0, 2);
                let dayEndMinute = itemik.endHour.substring(3, 5);
                if ((parseInt(dayStartHour) <= hour) && parseInt(dayEndHour) >= hour) {
                  visible = true;
                  if ((parseInt(dayStartHour) === hour) && (parseInt(dayStartMinute) > minute)) {
                    visible = false;
                  }
                  if ((parseInt(dayEndHour) === hour) && (parseInt(dayEndMinute) < minute)) {
                    visible = false;
                  }
                }
              }
              if ((itemik.dayName === 'Piątek') && (day === 5)) {
                let dayStartHour = itemik.startHour.substring(0, 2);
                let dayStartMinute = itemik.startHour.substring(3, 5);
                let dayEndHour = itemik.endHour.substring(0, 2);
                let dayEndMinute = itemik.endHour.substring(3, 5);
                if ((parseInt(dayStartHour) <= hour) && parseInt(dayEndHour) >= hour) {
                  visible = true;
                  if ((parseInt(dayStartHour) === hour) && (parseInt(dayStartMinute) > minute)) {
                    visible = false;
                  }
                  if ((parseInt(dayEndHour) === hour) && (parseInt(dayEndMinute) < minute)) {
                    visible = false;
                  }
                }
              }
              if ((itemik.dayName === 'Sobota') && (day === 6)) {
                let dayStartHour = itemik.startHour.substring(0, 2);
                let dayStartMinute = itemik.startHour.substring(3, 5);
                let dayEndHour = itemik.endHour.substring(0, 2);
                let dayEndMinute = itemik.endHour.substring(3, 5);
                if ((parseInt(dayStartHour) <= hour) && parseInt(dayEndHour) >= hour) {
                  visible = true;
                  if ((parseInt(dayStartHour) === hour) && (parseInt(dayStartMinute) > minute)) {
                    visible = false;
                  }
                  if ((parseInt(dayEndHour) === hour) && (parseInt(dayEndMinute) < minute)) {
                    visible = false;
                  }
                }
              }
              return true;
            })
            item.visible = visible;
          }
          return true;
        })
        this.setState({
          premises: response,
        });
      });
    });
    let googlemaps = "";
    this.updateCart();
    sessionStorage.setItem('lokal', this.props.desc.replace('ul. ', ''));
    sessionStorage.setItem('lokalUrl', this.props.slug);
    sessionStorage.setItem('lokalID', this.props.premiseID);
    googlemaps = this.props.googleMaps;

    this.setState({
      googlemaps: googlemaps,
    });

    if (
      sessionStorage.getItem("codeMinimum") === null ||
      sessionStorage.getItem("codeMinimum") === "0"
    ) {
      sessionStorage.setItem("codeMinimum", "30");
      sessionStorage.setItem("codeDelivery", "0");
    }

    if (sessionStorage.getItem("firstLoad") === "true") {
      let content = `<div class="item">
                      <span>Twój adres:</span><br> ${sessionStorage.getItem(
                        "street"
                      )}
                  </div>
                  <div class="item">
                    <span>Twój lokal:</span> ${sessionStorage.getItem(
                      "lokal"
                    )}
                </div>
                  <div class="item">
                      <span>Koszt dostawy:</span> ${sessionStorage.getItem(
                        "codeDelivery"
                      )} zł
                  </div>
                  <div class="item">
                    <span>Minimalna kwota zamówienia:</span> ${sessionStorage.getItem(
                      "codeMinimum"
                    )} zł
                </div>`;
      document.querySelector(".lokal-modal .text__medium").innerHTML = content;
      document.querySelector(".lokal-modal").style.display = "block";
      sessionStorage.setItem("firstLoad", "false");
    }
    sessionStorage.setItem("ownDelivery", "false");
    let tempDeliver = sessionStorage.getItem("codeDelivery");
    if (!isNaN(parseFloat(tempDeliver))) {
      document.querySelector(".deliverPrice").innerText =
        "+" + tempDeliver + " zł";
    } else {
      document.querySelector(".deliverPrice").innerText = "+0 zł";
    }
    window.addEventListener("scroll", this.handleScroll, true);
    if (sessionStorage.getItem("codeMinimum") === null) {
      sessionStorage.setItem("codeMinimum", "0");
    }
    let nav = document.querySelectorAll(".navbar__link");
    for (let i = 0; i < nav.length; i++) {
      nav[i].classList.remove("is-active");
    }
    document
      .querySelector(".navbar__left-box .navbar__link:first-child")
      .classList.add("is-active");
  }
  initLoader = (container) => {
    let loadContainer = document.querySelector(".load-container");
    let clone = loadContainer.cloneNode(true);
    clone.classList.remove("d-none");
    clone.classList.add("is-active");
    container.classList.add("is-loading");
    container.append(clone);
  };
  removeLoader = (container) => {
    let element = document.querySelector(".load-container.is-active");
    if (element !== null) element.remove();
    container.classList.remove("is-loading");
  };
  addToCartSpecial = (e, visible, id, history) => {
    e.preventDefault();
    if (visible === true) {
      history.push(`/specjalna-oferta/${id}`);
    } else {
      let content = e.target.closest('article').querySelector('.visibleDate').innerHTML;
      document.querySelector('.visible-special-modal .text__medium').innerHTML = content;
      document.querySelector(".visible-special-modal").style.display = "block";
    }
  }
  addToCart = (e, edit, id, history) => {
    e.preventDefault();
    if (edit === true) {
      history.push(`/wlasna-pizza/${id}`);
    } else {
      let localCart = [];
      if (JSON.parse(sessionStorage.getItem("localCart"))) {
        localCart = JSON.parse(sessionStorage.getItem("localCart"));
      }
      let currentNumber = e.target
          .closest(".wppizza-article")
          .querySelector(".menu-item__price")
          .getAttribute("data-size");
      let dataIngredients = e.target
          .closest(".wppizza-article")
          .querySelector(".menu-item__size:first-child")
          .getAttribute("data-ingredients");
      e.target
          .closest(".wppizza-article")
          .querySelector(`.wppizza-article-prices li:nth-child(${currentNumber})`)
          .click();
      let post = e.target.closest(".wppizza-article").getAttribute("id");
      let name = e.target
          .closest(".wppizza-article")
          .querySelector(".wppizza-article-title").innerText;
      let getPrice = e.target
          .closest(".wppizza-article")
          .querySelector(
              `.wppizza-article-price:nth-child(${currentNumber}) span`
          ).innerText;
      let tempPrice = getPrice.replace(" zł", "");
      let price = parseFloat(tempPrice).toFixed(2).replace(".", ",") + " zł";
      let sizeID = e.target
          .closest(".wppizza-article")
          .querySelector(`.wppizza-article-price:nth-child(${currentNumber})`)
          .getAttribute("id");
      let size = e.target
          .closest(".wppizza-article")
          .querySelector(
              `.wppizza-article-price:nth-child(${currentNumber}) .wppizza-article-size`
          ).innerText;
      let image = e.target
          .closest(".wppizza-article")
          .querySelector(".wppizza-article-image img")
          .getAttribute("src");
      let pizza = true;
      let category = document
          .querySelector('.cat-item a[title="active"]')
          .getAttribute("data-id");
      console.log(category);
      if (category === "1252" || category === "1502" || category === "601152") {
        pizza = false;
      }
      let repeat = false;
      localCart.map((item) => {
        if (item.post === post && item.size === size) {
          item.counter = parseInt(item.counter) + 1;
          let oldPrice = price
              .toFixed(2)
              .substring(0, price.lastIndexOf(" zł"))
              .replace(".", ",");
          let tempPrice = parseFloat(oldPrice) * parseInt(item.counter);
          let newPrice = tempPrice.toFixed(2);
          item.price = newPrice.toString().replace(".", ",") + " zł";
          repeat = true;
        }
        return true;
      });
      if (repeat === false) {
        let localProduct = {
          id: post,
          name: name,
          price: price,
          counter: 1,
          size: size,
          sizeId: sizeID,
          pizza: pizza,
          info: [],
          image: image,
        };
        localCart.push(localProduct);
      }
      sessionStorage.setItem("localCart", JSON.stringify(localCart));
      this.updateCart();
    }
    sessionStorage.setItem(
        "sizeHistory",
        e.target
            .closest(".wppizza-article")
            .querySelector(".menu-item__size--active")
            .getAttribute("data-size")
    );
  };
  handleScroll = () => {
    const offset = document.querySelector(".blured-background").scrollTop;
    if (window.outerWidth > 1000 && window.outerWidth < 1399) {
      if (offset > 420) {
        if (document.querySelector(".shopping-card") !== null) {
          document.querySelector(".shopping-card").classList.add("sticky");
        }
      } else {
        if (document.querySelector(".shopping-card") !== null) {
          document.querySelector(".shopping-card").classList.remove("sticky");
        }
      }
    } else if (window.outerWidth > 1400 && window.outerWidth < 1499) {
      if (offset > 376) {
        if (document.querySelector(".shopping-card") !== null) {
          document.querySelector(".shopping-card").classList.add("sticky");
        }
      } else {
        if (document.querySelector(".shopping-card") !== null) {
          document.querySelector(".shopping-card").classList.remove("sticky");
        }
      }
    } else if (window.outerWidth > 1500) {
      if (offset > 300) {
        if (document.querySelector(".shopping-card") !== null) {
          document.querySelector(".shopping-card").classList.add("sticky");
        }
      } else {
        if (document.querySelector(".shopping-card") !== null) {
          document.querySelector(".shopping-card").classList.remove("sticky");
        }
      }
    }
  };
  sumCart = () => {
    let sum = 0;
    let counter = 0;
    this.state.cart.map((item) => {
      let price = item.price.replace(",", ".");
      price = price.replace(" zł", "");
      sum += parseFloat(price);
      counter += 1;
    });
    if (!isNaN(parseFloat(sessionStorage.getItem("codeDelivery")))) {
      if (sessionStorage.getItem("ownDelivery") !== "true") {
        sum += parseFloat(sessionStorage.getItem("codeDelivery"));
      }
    }
    if (!isNaN(parseFloat(sessionStorage.getItem("discountRealValue")))) {
      sum -= parseFloat(sessionStorage.getItem("discountRealValue"));
    }
    sum = sum.toFixed(2);
    sum = sum.toString().replace(".", ",") + " zł";
    this.props.changeSum(sum, counter);
  };
  updateCart = () => {
    let localCart = [];
    if (JSON.parse(sessionStorage.getItem("localCart"))) {
      localCart = JSON.parse(sessionStorage.getItem("localCart"));
    }
    this.setState({
      cart: localCart,
    });
    let self = this;
    setTimeout(function () {
      self.sumCart();
    }, 1);
  };
  scrollIt = (element) => {
    window.scrollTo({
      behavior: "smooth",
      left: 0,
      top: window.pageYOffset + element.getBoundingClientRect().top - 100,
    });
  };
  changeCategory = (e, name) => {
    e.preventDefault();
    this.setState({
      activeCategory: name,
    });
    if (e.target.getAttribute("title") !== "active") {
      let categories = document.querySelectorAll(".cat-item a");
      for (let i = 0; i < categories.length; i++) {
        categories[i].removeAttribute("title");
      }
      e.target.setAttribute("title", "active");
    }
    this.scrollIt(document.querySelector("#pizzamenu"));
    Connect(
      `${endpoint.backend}${
        endpoint.getAllPizzasAndProducts
      }/${sessionStorage.getItem("lokalID")}/${name}`,
      "GET",
      null
    ).then((response) => {
      const d = new Date();
      response.map((item) => {
        if (item.specialOffer) {
          console.log(item.specialOffer.specialOfferTimes)
          let day = d.getDay();
          let hour = d.getHours();
          let minute = d.getMinutes();
          let visible = false;
          let tempSpecialTimes = [];
          console.log(item.specialOffer.specialOfferTimes)
          item.specialOffer.specialOfferTimes.map((itemik) => {
            if (itemik.dayName === 'Poniedziałek') {
              tempSpecialTimes.push(itemik);
            }
            return true;
          })
          item.specialOffer.specialOfferTimes.map((itemik) => {
            if (itemik.dayName === 'Wtorek') {
              tempSpecialTimes.push(itemik);
            }
            return true;
          })
          item.specialOffer.specialOfferTimes.map((itemik) => {
            if (itemik.dayName === 'Środa') {
              tempSpecialTimes.push(itemik);
            }
            return true;
          })
          item.specialOffer.specialOfferTimes.map((itemik) => {
            if (itemik.dayName === 'Czwartek') {
              tempSpecialTimes.push(itemik);
            }
            return true;
          })
          item.specialOffer.specialOfferTimes.map((itemik) => {
            if (itemik.dayName === 'Piątek') {
              tempSpecialTimes.push(itemik);
            }
            return true;
          })
          item.specialOffer.specialOfferTimes.map((itemik) => {
            if (itemik.dayName === 'Sobota') {
              tempSpecialTimes.push(itemik);
            }
            return true;
          })
          item.specialOffer.specialOfferTimes.map((itemik) => {
            if (itemik.dayName === 'Niedziela') {
              tempSpecialTimes.push(itemik);
            }
            return true;
          })
          item.specialOffer.specialOfferTimes = tempSpecialTimes;
          item.specialOffer.specialOfferTimes.map((itemik) => {
            if ((itemik.dayName === 'Niedziela') && (day === 0)) {
              let dayStartHour = itemik.startHour.substring(0, 2);
              let dayStartMinute = itemik.startHour.substring(3, 5);
              let dayEndHour = itemik.endHour.substring(0, 2);
              let dayEndMinute = itemik.endHour.substring(3, 5);
              if ((parseInt(dayStartHour) <= hour) && parseInt(dayEndHour) >= hour) {
                visible = true;
                if ((parseInt(dayStartHour) === hour) && (parseInt(dayStartMinute) > minute)) {
                  visible = false;
                }
                if ((parseInt(dayEndHour) === hour) && (parseInt(dayEndMinute) < minute)) {
                  visible = false;
                }
              }
            }
            if ((itemik.dayName === 'Poniedziałek') && (day === 1)) {
              let dayStartHour = itemik.startHour.substring(0, 2);
              let dayStartMinute = itemik.startHour.substring(3, 5);
              let dayEndHour = itemik.endHour.substring(0, 2);
              let dayEndMinute = itemik.endHour.substring(3, 5);
              if ((parseInt(dayStartHour) <= hour) && parseInt(dayEndHour) >= hour) {
                visible = true;
                if ((parseInt(dayStartHour) === hour) && (parseInt(dayStartMinute) > minute)) {
                  visible = false;
                }
                if ((parseInt(dayEndHour) === hour) && (parseInt(dayEndMinute) < minute)) {
                  visible = false;
                }
              }
            }
            if ((itemik.dayName === 'Wtorek') && (day === 2)) {
              let dayStartHour = itemik.startHour.substring(0, 2);
              let dayStartMinute = itemik.startHour.substring(3, 5);
              let dayEndHour = itemik.endHour.substring(0, 2);
              let dayEndMinute = itemik.endHour.substring(3, 5);
              if ((parseInt(dayStartHour) <= hour) && parseInt(dayEndHour) >= hour) {
                visible = true;
                if ((parseInt(dayStartHour) === hour) && (parseInt(dayStartMinute) > minute)) {
                  visible = false;
                }
                if ((parseInt(dayEndHour) === hour) && (parseInt(dayEndMinute) < minute)) {
                  visible = false;
                }
              }
            }
            if ((itemik.dayName === 'Środa') && (day === 3)) {
              let dayStartHour = itemik.startHour.substring(0, 2);
              let dayStartMinute = itemik.startHour.substring(3, 5);
              let dayEndHour = itemik.endHour.substring(0, 2);
              let dayEndMinute = itemik.endHour.substring(3, 5);
              if ((parseInt(dayStartHour) <= hour) && parseInt(dayEndHour) >= hour) {
                visible = true;
                if ((parseInt(dayStartHour) === hour) && (parseInt(dayStartMinute) > minute)) {
                  visible = false;
                }
                if ((parseInt(dayEndHour) === hour) && (parseInt(dayEndMinute) < minute)) {
                  visible = false;
                }
              }
            }
            if ((itemik.dayName === 'Czwartek') && (day === 4)) {
              let dayStartHour = itemik.startHour.substring(0, 2);
              let dayStartMinute = itemik.startHour.substring(3, 5);
              let dayEndHour = itemik.endHour.substring(0, 2);
              let dayEndMinute = itemik.endHour.substring(3, 5);
              if ((parseInt(dayStartHour) <= hour) && parseInt(dayEndHour) >= hour) {
                visible = true;
                if ((parseInt(dayStartHour) === hour) && (parseInt(dayStartMinute) > minute)) {
                  visible = false;
                }
                if ((parseInt(dayEndHour) === hour) && (parseInt(dayEndMinute) < minute)) {
                  visible = false;
                }
              }
            }
            if ((itemik.dayName === 'Piątek') && (day === 5)) {
              let dayStartHour = itemik.startHour.substring(0, 2);
              let dayStartMinute = itemik.startHour.substring(3, 5);
              let dayEndHour = itemik.endHour.substring(0, 2);
              let dayEndMinute = itemik.endHour.substring(3, 5);
              if ((parseInt(dayStartHour) <= hour) && parseInt(dayEndHour) >= hour) {
                visible = true;
                if ((parseInt(dayStartHour) === hour) && (parseInt(dayStartMinute) > minute)) {
                  visible = false;
                }
                if ((parseInt(dayEndHour) === hour) && (parseInt(dayEndMinute) < minute)) {
                  visible = false;
                }
              }
            }
            if ((itemik.dayName === 'Sobota') && (day === 6)) {
              let dayStartHour = itemik.startHour.substring(0, 2);
              let dayStartMinute = itemik.startHour.substring(3, 5);
              let dayEndHour = itemik.endHour.substring(0, 2);
              let dayEndMinute = itemik.endHour.substring(3, 5);
              if ((parseInt(dayStartHour) <= hour) && parseInt(dayEndHour) >= hour) {
                visible = true;
                if ((parseInt(dayStartHour) === hour) && (parseInt(dayStartMinute) > minute)) {
                  visible = false;
                }
                if ((parseInt(dayEndHour) === hour) && (parseInt(dayEndMinute) < minute)) {
                  visible = false;
                }
              }
            }
            return true;
          })
          item.visible = visible;
        }
        return true;
      })
      this.setState({
        premises: response,
      });
    });
  };

  removeFromCart = (e, queNumber) => {
    e.preventDefault();
    this.setState({
      qNumber: queNumber,
    });
    document.querySelector(".confirm-modal-delete").style.display = "block";
    document
      .querySelector(".confirm-modal-delete .updateDelete")
      .addEventListener("click", (e) => {
        e.preventDefault();
        if (
          document.querySelector(".confirm-modal-delete").style.display ===
          "block"
        ) {
          document.querySelector(".confirm-modal-delete").style.display =
            "none";
          let localCart = [];
          if (JSON.parse(sessionStorage.getItem("localCart"))) {
            localCart = JSON.parse(sessionStorage.getItem("localCart"));
          }
          localCart.splice(this.state.qNumber, 1);
          sessionStorage.setItem("localCart", JSON.stringify(localCart));
          this.updateCart();
        }
      });
  };
  updateCounterCard = (e, queNumber) => {
    e.preventDefault();
    let localCart = [];
    if (JSON.parse(sessionStorage.getItem("localCart"))) {
      localCart = JSON.parse(sessionStorage.getItem("localCart"));
    }
    let currentValue = e.target
      .closest(".wppizza-item-quantity")
      .querySelector(".ui-spinner-input").value;
    let condition = "plus";
    if (e.target.classList.contains("ui-spinner-down")) {
      condition = "minus";
    }
    if (e.target.closest("a").classList.contains("ui-spinner-down")) {
      condition = "minus";
    }
    let newValue = 1;
    if (condition === "plus") {
      newValue = parseInt(currentValue) + 1;
    } else {
      if (currentValue > 1) {
        newValue = parseInt(currentValue) - 1;
      }
    }
    localCart.map((item, index) => {
      if (index === queNumber) {
        let oldPrice = item.price
          .substring(0, item.price.lastIndexOf(" zł"))
          .replace(".", ",");
        oldPrice = parseFloat(oldPrice) / parseInt(item.counter);
        item.counter = parseInt(newValue);
        let tempPrice = parseFloat(oldPrice) * parseInt(item.counter);
        let newPrice = tempPrice.toFixed(2);
        item.price = newPrice.toString().replace(".", ",") + " zł";
      }
      return true;
    });
    sessionStorage.setItem("localCart", JSON.stringify(localCart));
    this.updateCart();
  };
  changeSize = (e) => {
    e.target.closest(".menu-item__size-menu").classList.remove("open");
    if (!e.target.classList.contains("menu-item__size--active")) {
      e.target
        .closest(".menu-item__size-menu")
        .querySelector(".menu-item__size--active")
        .classList.remove("menu-item__size--active");
      e.target.classList.add("menu-item__size--active");
      let newCurrentPriceNumber = e.target.getAttribute("data-size");
      console.log(newCurrentPriceNumber);
      let getNewCurrentPrice = e.target
        .closest(".wppizza-article")
        .querySelector(
          `.wppizza-article-prices li:nth-child(${newCurrentPriceNumber}) span`
        ).innerText;
      let tempNewCurrentPrice = getNewCurrentPrice.replace(" zł", "");
      let newCurrentPrice =
        parseFloat(tempNewCurrentPrice).toFixed(2).replace(".", ",") + " zł";
      let newIDSize = e.target
        .closest(".wppizza-article")
        .querySelector(
          `.wppizza-article-prices li:nth-child(${newCurrentPriceNumber})`
        )
        .getAttribute("id");
      console.log(newIDSize);
      console.log(newCurrentPrice);
      e.target
        .closest(".wppizza-article")
        .querySelector(".menu-item__price").innerText = newCurrentPrice;
      e.target
        .closest(".wppizza-article")
        .querySelector(".menu-item__price")
        .setAttribute("data-size", newCurrentPriceNumber);
      e.target
        .closest(".wppizza-article")
        .querySelector(".menu-item__price")
        .setAttribute("data-id", newIDSize);
    }
  };
  delivery = (e) => {
    if (document.querySelector("#ownDelivery").checked === true) {
      sessionStorage.setItem("ownDelivery", "false");
    } else {
      sessionStorage.setItem("ownDelivery", "true");
    }
    if (sessionStorage.getItem("codeDelivery") !== null) {
      let tempDeliver = sessionStorage.getItem("codeDelivery");
      if (
        !isNaN(parseFloat(tempDeliver)) &&
        sessionStorage.getItem("ownDelivery") !== "true"
      ) {
        document.querySelector(".deliverPrice").innerText =
          "+" + tempDeliver + " zł";
      } else {
        document.querySelector(".deliverPrice").innerText = "+0 zł";
      }
    } else {
      document.querySelector(".deliverPrice").innerText = "+0 zł";
    }

    let self = this;
    setTimeout(function () {
      self.sumCart();
    }, 1);
  };
  getOrder = (e) => {
    e.preventDefault();
    let summary = document
      .querySelector(".cartLink")
      .getAttribute("data-summary");
    if (
      sessionStorage.getItem("ownDelivery") &&
      sessionStorage.getItem("ownDelivery") === "true" &&
      parseFloat(summary) > 0
    ) {
      window.location.href = "/podsumowanie-zamowienia/";
    } else {
      console.log(
        parseFloat(summary) > parseInt(sessionStorage.getItem("codeMinimum"))
      );
      if (
        parseFloat(summary) > parseInt(sessionStorage.getItem("codeMinimum"))
      ) {
        window.location.href = "/podsumowanie-zamowienia/";
      } else {
        document.querySelector(
          ".price-modal .text__w300"
        ).innerText = `minimalna kwota do zamówień z dostawą to ${sessionStorage.getItem(
          "codeMinimum"
        )} zł`;
        document.querySelector(".price-modal").style.display = "block";
      }
    }
  };
  render() {
    return (
      <>
        <Login />
        <Navbar
          sum={this.props.sum}
          counter={this.props.counter}
          summary={this.props.summary}
        />
        <div className="contact-box desktop">
          <div className="contact-box__circle">
            <svg
              width="50"
              height="50"
              viewBox="0 0 50 50"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M25 0C11.2153 0 0 11.2147 0 25C0 38.7853 11.2153 50 25 50C38.7853 50 50 38.7853 50 25C50 11.2147 38.7847 0 25 0ZM25 46.3504C13.2275 46.3504 3.64961 36.7725 3.64961 25C3.64961 13.2275 13.2275 3.64961 25 3.64961C36.7725 3.64961 46.3504 13.2274 46.3504 24.9994C46.3504 36.7725 36.7725 46.3504 25 46.3504Z"
                fill="#FE3838"
              ></path>
            </svg>
            <span className="contact-box__description text__small text__w500 timeOpeningLokal">
              12:00 - 22:00
            </span>
          </div>
          <div className="contact-box__circle">
            <svg
              width="36"
              height="50"
              viewBox="0 0 36 50"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 0C8.30742 0 0.421875 7.88555 0.421875 17.5781C0.421875 20.8529 1.329 24.0488 3.0458 26.8215L16.9994 49.3075C17.2667 49.7383 17.7376 50 18.244 50C18.2479 50 18.2518 50 18.2557 50C18.7665 49.996 19.2384 49.7262 19.5008 49.2879L33.0987 26.584C34.7208 23.8699 35.5781 20.7558 35.5781 17.5781C35.5781 7.88555 27.6926 0 18 0ZM30.5846 25.0799L18.2219 45.7213L5.53584 25.2779C4.10684 22.9701 3.33203 20.3076 3.33203 17.5781C3.33203 9.50098 9.92285 2.91016 18 2.91016C26.0771 2.91016 32.6582 9.50098 32.6582 17.5781C32.6582 20.2267 31.9345 22.8211 30.5846 25.0799Z"
                fill="#FE3838"
              ></path>
            </svg>
            <a
              href={this.state.googlemaps}
              target="_blank"
              className="contact-box__description text__small text__w500"
              rel="noreferrer"
            >
              {this.props.desc} <br />
              Wrocław
            </a>
          </div>
          <div className="contact-box__circle">
            <svg
              width="50"
              height="50"
              viewBox="0 0 50 50"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M35.9352 50C34.3212 50 32.7209 49.7147 31.156 49.1461C24.262 46.6417 17.8628 42.5628 12.6502 37.3502C7.43748 32.1376 3.35855 25.7384 0.854256 18.8443C0.0495037 16.6288 -0.187987 14.3418 0.148621 12.0466C0.46394 9.89666 1.29672 7.80954 2.55702 6.01079C3.82288 4.20412 5.51051 2.70145 7.43739 1.66526C9.49218 0.560329 11.735 0 14.1036 0C14.8403 0 15.477 0.514725 15.6314 1.23501L18.0838 12.6798C18.1949 13.198 18.0356 13.7372 17.6609 14.112L13.4703 18.3025C17.4235 26.1621 23.8381 32.5767 31.6977 36.5299L35.8882 32.3393C36.263 31.9646 36.8023 31.8055 37.3204 31.9164L48.7652 34.3688C49.4856 34.5232 50.0002 35.1599 50.0002 35.8966C50.0002 38.2652 49.4399 40.5081 48.3348 42.5629C47.2986 44.4898 45.796 46.1774 43.9893 47.4433C42.1907 48.7036 40.1035 49.5364 37.9535 49.8517C37.2798 49.9506 36.6062 49.9999 35.9352 50ZM12.8552 3.19323C9.73085 3.53814 6.95772 5.17576 5.11619 7.80407C3.04538 10.7595 2.56249 14.3946 3.79134 17.7775C8.60599 31.0314 18.9689 41.3944 32.2229 46.209C35.6057 47.4379 39.2408 46.9551 42.1963 44.8842C44.8246 43.0427 46.4623 40.2695 46.8072 37.1452L37.4969 35.1501L33.1205 39.5265C32.6548 39.9922 31.9472 40.1159 31.3513 39.8359C22.0558 35.4689 14.5315 27.9446 10.1645 18.649C9.88455 18.0531 10.0082 17.3455 10.4739 16.8799L14.8503 12.5035L12.8552 3.19323Z"
                fill="#FE3838"
              ></path>
            </svg>
            <a
              href={`tel:${this.props.tel}`}
              className="contact-box__description text__small text__w500"
            >
              tel: {this.props.tel}
            </a>
          </div>
        </div>
        <section id="pizzamenu" className="menu-section">
          <div className="menu-section__header">
            <h1 className="text text__w700 text__large--2">Nasze menu</h1>
            <ul id="wppizza-categories-1" className="wppizza-categories">
              {this.state.categories.map((data, index) => (
                <li className="cat-item cat-item-56">
                  <a
                    onClick={(e) => this.changeCategory(e, data.id)}
                    href="#"
                    title={index === 0 ? "active" : ""}
                    data-id={data.id}
                  >
                    {data.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className="menu-section__products">
            {this.state.premises &&
              this.state.premises.map((data) => (
                <article
                  id={data.pizza
                      ? data.pizza.id
                      : data.product
                          ? data.product.id
                          : data.specialOffer.id}
                  className={data.specialOffer && data.specialOffer.active === false ? "d-none" : "wppizza-article wppizza-article-default  wppizza-article-first wppizza-article-speciale wppizza-article-speciale-53 wppizza_menu-speciale wppizza_menu-term-53 wppizza_menu-mieso wppizza_menu-term-48 wppizza_menu-lokal-tarnogaj wppizza_menu-term-57 wppizza_menu-lokal-srodmiescie wppizza_menu-term-58 wppizza_menu-lokal-gadow wppizza_menu-term-59 wppizza_menu-lokal-grabiszynska wppizza_menu-term-60 entry-content post-454 wppizza type-wppizza status-publish has-post-thumbnail hentry"}
                >
                  <div className="visibleDate d-none">
                    {data.specialOffer && data.specialOffer.specialOfferTimes.map((data) => (
                        <>
                          <p style={{display: "flex", lineHeight: '25px'}}>
                            <span style={{width: 150}}>{data.dayName}:</span>
                            <span>{data.startHour} - {data.endHour}</span>
                          </p>
                        </>
                    ))}
                  </div>
                  <div
                    id="wppizza-article-img-454"
                    className="wppizza-article-image"
                  >
                    {data.pizza && data.pizza.image ? (
                      <img
                        width="382"
                        height="290"
                        src={data.pizza && data.pizza.image}
                        className={
                          data.pizza &&
                          data.pizza.categories &&
                          data.pizza.categories[0].id === 1252
                            ? "wppizza-article-image-thumb wp-post-image drink"
                            : "wppizza-article-image-thumb wp-post-image"
                        }
                        alt=""
                        title={data.pizza.name}
                        srcSet={data.pizza && data.pizza.image}
                        sizes="(max-width: 382px) 100vw, 382px"
                      />
                    ) : data.product && data.product.image ? (
                      <img
                        width="382"
                        height="290"
                        src={data.product && data.product.image}
                        className={
                          data.product &&
                          data.product.categories &&
                          data.product.categories[0] &&
                          data.product.categories[0].id === 1252
                            ? "wppizza-article-image-thumb wp-post-image drink"
                            : "wppizza-article-image-thumb wp-post-image"
                        }
                        alt=""
                        title={data.product.name}
                        srcSet={data.product && data.product.image}
                        sizes="(max-width: 382px) 100vw, 382px"
                      />
                    ) : (
                      data.specialOffer && (
                        <img
                          width="382"
                          height="290"
                          src={data.specialOffer && data.specialOffer.image}
                          className={
                            data.specialOffer &&
                            data.specialOffer.categories &&
                            data.specialOffer.categories[0].id === 1252
                              ? "wppizza-article-image-thumb wp-post-image drink"
                              : "wppizza-article-image-thumb wp-post-image"
                          }
                          alt=""
                          title={data.specialOffer.name}
                          srcSet={data.specialOffer && data.specialOffer.image}
                          sizes="(max-width: 382px) 100vw, 382px"
                        />
                      )
                    )}
                  </div>
                  <div>
                    <h2 className="wppizza-article-h2">
                      <span className="wppizza-article-title">
                        {data.pizza
                          ? data.pizza.name
                          : data.product
                          ? data.product.name
                          : data.specialOffer.name}
                      </span>
                    </h2>
                    <div className="wppizza-article-sizes wppizza-article-prices-2">
                      <ul>
                        <li className="wppizza-article-prices">
                          {data.pizza && data.pizza.sizes ? (
                            <ul>
                              <li
                                id={
                                  data.pizza.sizes[0] && data.pizza.sizes[0].id
                                }
                                className="wppizza-article-price wppizza-article-price-2-0 wppizza-price-454-0 wppizza-add-to-cart"
                                title="do koszyka"
                              >
                                <span className="">
                                  {data.pizza.sizes[0] &&
                                    data.pizza.sizes[0].price}{" "}
                                  zł
                                </span>
                                <div className="wppizza-article-size">
                                  {data.pizza.sizes[0] &&
                                    data.pizza.sizes[0].name}
                                </div>
                              </li>
                              <li
                                id={
                                  data.pizza.sizes[1] && data.pizza.sizes[1].id
                                }
                                className="wppizza-article-price wppizza-article-price-2-1 wppizza-price-454-1 wppizza-add-to-cart"
                                title="do koszyka"
                              >
                                <span className="">
                                  {data.pizza.sizes[1] &&
                                    data.pizza.sizes[1].price}{" "}
                                  zł
                                </span>
                                <div className="wppizza-article-size">
                                  {data.pizza.sizes[1] &&
                                    data.pizza.sizes[1].name}
                                </div>
                              </li>
                              <li
                                id={
                                  data.pizza.sizes[2] && data.pizza.sizes[2].id
                                }
                                className="wppizza-article-price wppizza-article-price-2-2 wppizza-price-454-2 wppizza-add-to-cart"
                                title="do koszyka"
                              >
                                <span className="">
                                  {data.pizza.sizes[2] &&
                                    data.pizza.sizes[2].price}{" "}
                                  zł
                                </span>
                                <div className="wppizza-article-size">
                                  {data.pizza.sizes[2] &&
                                    data.pizza.sizes[2].name}
                                </div>
                              </li>
                            </ul>
                          ) : data.product ? (
                            <ul>
                              <li
                                id={
                                  data.product.sizes[0] &&
                                  data.product.sizes[0].id
                                }
                                className="wppizza-article-price wppizza-article-price-2-0 wppizza-price-454-0 wppizza-add-to-cart"
                                title="do koszyka"
                              >
                                <span className="">
                                  {data.product.sizes[0] &&
                                    data.product.sizes[0].price}{" "}
                                  zł
                                </span>
                                <div className="wppizza-article-size">
                                  {data.product.sizes[0] &&
                                    data.product.sizes[0].name}
                                </div>
                              </li>
                              <li
                                id={
                                  data.product.sizes[1] &&
                                  data.product.sizes[1].id
                                }
                                className="wppizza-article-price wppizza-article-price-2-1 wppizza-price-454-1 wppizza-add-to-cart"
                                title="do koszyka"
                              >
                                <span className="">
                                  {data.product.sizes[1] &&
                                    data.product.sizes[1].price}{" "}
                                  zł
                                </span>
                                <div className="wppizza-article-size">
                                  {data.product.sizes[1] &&
                                    data.product.sizes[1].name}
                                </div>
                              </li>
                              <li
                                id={
                                  data.product.sizes[2] &&
                                  data.product.sizes[2].id
                                }
                                className="wppizza-article-price wppizza-article-price-2-2 wppizza-price-454-2 wppizza-add-to-cart"
                                title="do koszyka"
                              >
                                <span className="">
                                  {data.product.sizes[2] &&
                                    data.product.sizes[2].price}{" "}
                                  zł
                                </span>
                                <div className="wppizza-article-size">
                                  {data.product.sizes[2] &&
                                    data.product.sizes[2].name}
                                </div>
                              </li>
                            </ul>
                          ) : (
                            <ul>
                              <li
                                id={data.specialOffer && data.specialOffer.id}
                                className="wppizza-article-price wppizza-article-price-2-0 wppizza-price-454-0 wppizza-add-to-cart"
                                title="do koszyka"
                              >
                                {console.log(data.specialOffer)}
                                <span className="">
                                  {data.specialOffer && data.specialOffer.price}{" "}
                                  zł
                                </span>
                                <div className="wppizza-article-size">
                                  {data.specialOffer && data.specialOffer.name}
                                </div>
                              </li>
                              <li
                                id={data.specialOffer && data.specialOffer.id}
                                className="wppizza-article-price wppizza-article-price-2-1 wppizza-price-454-1 wppizza-add-to-cart"
                                title="do koszyka"
                              >
                                <span className="">
                                  {data.specialOffer && data.specialOffer.price}{" "}
                                  zł
                                </span>
                                <div className="wppizza-article-size">
                                  {data.specialOffer && data.specialOffer.name}
                                </div>
                              </li>
                              <li
                                id={data.specialOffer && data.specialOffer.id}
                                className="wppizza-article-price wppizza-article-price-2-2 wppizza-price-454-2 wppizza-add-to-cart"
                                title="do koszyka"
                              >
                                <span className="">
                                  {data.specialOffer && data.specialOffer.price}{" "}
                                  zł
                                </span>
                                <div className="wppizza-article-size">
                                  {data.specialOffer && data.specialOffer.name}
                                </div>
                              </li>
                            </ul>
                          )}
                        </li>
                      </ul>
                    </div>
                    <p className="wppizza-article-content">
                      {data.pizza &&
                        data.pizza.ingriedients &&
                        data.pizza.ingriedients.map((item) =>
                          item.type === "SAUCE" ? (
                            <div>{item.name + ","}</div>
                          ) : (
                            ""
                          )
                        )
                      }
                      {data.pizza &&
                        data.pizza.ingriedients &&
                        data.pizza.ingriedients.map((item) =>
                          item.type === "CHEESE" ? (
                            <div>{item.name + ","}</div>
                          ) : (
                            ""
                          )
                        )
                      }
                      {data.pizza &&
                        data.pizza.ingriedients &&
                        data.pizza.ingriedients.map((item, i, row) =>
                          item.type !== "SAUCE" && item.type !== "CHEESE" ? (
                            i + 1 === row.length ? (
                              <div>{item.name}</div>
                            ) : (
                              i + 2 === row.length ? (
                                <div>{item.name + ","}</div>
                              ) : (
                                i + 3 === row.length ? (
                                    <div>{item.name}</div>
                                ) : (
                                    <div>{item.name + ","}</div>
                                )
                              )
                            )
                          ) : (
                            ""
                          )
                        )
                      }
                      {data.product
                        ? data.product.description
                        : data.specialOffer && data.specialOffer.description}
                    </p>
                    {data.specialOffer && data.specialOffer.id !== undefined ? (
                      <Link
                        to={`/specjalna-oferta/${data.specialOffer.id}`}
                        onClick={(e) =>
                            this.addToCartSpecial(
                                e,
                                data.visible,
                                data.specialOffer.id,
                                this.state.history
                            )
                        }
                        className="menu-item__button"
                      >
                        <div className="menu-item__sticks-box">
                          <span className="menu-item__vertical-stick"></span>
                          <span className="menu-item__horizontal-stick"></span>
                        </div>
                      </Link>
                    ) : (
                      <Link
                        to={`/wlasna-pizza/${data.pizza && data.pizza.id}`}
                        onClick={(e) =>
                          this.addToCart(
                            e,
                            data.pizza ? data.pizza.editIngriedients : false,
                              data.pizza && data.pizza.id,
                              this.state.history
                          )
                        }
                        className="menu-item__button"
                      >
                        <div className="menu-item__sticks-box">
                          <span className="menu-item__vertical-stick"></span>
                          <span className="menu-item__horizontal-stick"></span>
                        </div>
                      </Link>
                    )}
                    {data.pizza && data.pizza.sizes ? (
                      <form className="menu-item__size-menu" action="#">
                        <label
                          className="menu-item__size menu-item__size--active"
                          data-size="1"
                          htmlFor="size1"
                          onClick={(e) => this.changeSize(e)}
                          data-ingredients="false"
                        >
                          {data.pizza.sizes[0] && data.pizza.sizes[0].name}
                        </label>
                        <input
                          className="menu-item__size-input"
                          check=""
                          id="size1"
                          type="checkbox"
                        />
                        {data.pizza.sizes[1] ? (
                          <label
                            className="menu-item__size "
                            data-size="2"
                            htmlFor="size2"
                            onClick={(e) => this.changeSize(e)}
                            data-ingredients="false"
                          >
                            {data.pizza.sizes[1] && data.pizza.sizes[1].name}
                          </label>
                        ) : (
                          ""
                        )}
                        <input
                          className="menu-item__size-input"
                          check=""
                          id="size2"
                          type="checkbox"
                        />
                        {data.pizza.sizes[2] ? (
                          <label
                            className="menu-item__size "
                            data-size="3"
                            htmlFor="size3"
                            onClick={(e) => this.changeSize(e)}
                            data-ingredients="false"
                          >
                            {data.pizza.sizes[2] && data.pizza.sizes[2].name}
                          </label>
                        ) : (
                          ""
                        )}
                        <input
                          className="menu-item__size-input"
                          check=""
                          id="size3"
                          type="checkbox"
                        />
                      </form>
                    ) : null}
                    {data.product && data.product.sizes ? (
                      <form className="menu-item__size-menu" action="#">
                        <label
                          className="menu-item__size menu-item__size--active"
                          data-size="1"
                          htmlFor="size1"
                          onClick={(e) => this.changeSize(e)}
                          data-ingredients="false"
                        >
                          {data.product.sizes[0] && data.product.sizes[0].name}
                        </label>
                        <input
                          className="menu-item__size-input"
                          check=""
                          id="size1"
                          type="checkbox"
                        />
                        {data.product.sizes[1] ? (
                          <label
                            className="menu-item__size "
                            data-size="2"
                            htmlFor="size2"
                            onClick={(e) => this.changeSize(e)}
                            data-ingredients="false"
                          >
                            {data.product.sizes[1] &&
                              data.product.sizes[1].name}
                          </label>
                        ) : (
                          ""
                        )}
                        <input
                          className="menu-item__size-input"
                          check=""
                          id="size2"
                          type="checkbox"
                        />
                        {data.product.sizes[2] ? (
                          <label
                            className="menu-item__size "
                            data-size="3"
                            htmlFor="size3"
                            onClick={(e) => this.changeSize(e)}
                            data-ingredients="false"
                          >
                            {data.product.sizes[2] &&
                              data.product.sizes[2].name}
                          </label>
                        ) : (
                          ""
                        )}
                        <input
                          className="menu-item__size-input"
                          check=""
                          id="size3"
                          type="checkbox"
                        />
                      </form>
                    ) : null}
                    {data.pizza && data.pizza.sizes ? (
                      <span
                        price=""
                        className="menu-item__price text__w600"
                        data-size="1"
                      >
                        {data.pizza.sizes[0] &&
                          data.pizza.sizes[0].price
                            .toFixed(2)
                            .replace(".", ",")}{" "}
                        zł
                      </span>
                    ) : data.product ? (
                      <span
                        price=""
                        className="menu-item__price text__w600"
                        data-size="1"
                      >
                        {data.product.sizes[0] &&
                          data.product.sizes[0].price
                            .toFixed(2)
                            .replace(".", ",")}{" "}
                        zł
                      </span>
                    ) : (
                      <span
                        price=""
                        className="menu-item__price text__w600"
                        data-size="1"
                      >
                        <span className="price-offer-before">
                          {data.specialOffer && data.specialOffer.priceBefore &&
                            data.specialOffer.priceBefore
                              .toFixed(2)
                              .replace(".", ",")}{" "}
                          zł
                        </span>
                        {data.specialOffer && data.specialOffer.price &&
                          data.specialOffer.price
                            .toFixed(2)
                            .replace(".", ",")}{" "}
                        zł
                      </span>
                    )}
                  </div>
                </article>
              ))}
            {this.state.premises.length === 0 ? (
              <div className="emptyProduct active">
                Brak dostępnych produktów w danej kategorii
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="menu-section__cart disable">
            <div className="shopping-card">
              <svg
                width="55"
                height="49"
                viewBox="0 0 55 49"
                fill="none"
                className="shopping-card__icon"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M53.3887 16.4433H47.7178L35.311 0.934845C34.7552 0.240148 33.7412 0.127247 33.0462 0.683263C32.3513 1.23917 32.2386 2.25313 32.7946 2.94804L43.5908 16.4433H11.4092L22.2055 2.94804C22.7614 2.25313 22.6487 1.23906 21.9539 0.683263C21.2589 0.127247 20.2449 0.24004 19.6891 0.934845L7.28224 16.4433H1.61133C0.721445 16.4433 0 17.1648 0 18.0547V24.5C0 25.3899 0.721445 26.1113 1.61133 26.1113H3.61861L9.73006 47.5013C9.92761 48.1931 10.5599 48.6699 11.2793 48.6699H43.7207C44.4401 48.6699 45.0724 48.1931 45.2701 47.5013L51.3814 26.1113H53.3887C54.2786 26.1113 55 25.3899 55 24.5V18.0547C55 17.1648 54.2786 16.4433 53.3887 16.4433ZM42.5053 45.4473H12.4947L6.97018 26.1113H48.0299L42.5053 45.4473ZM51.7773 22.8887C46.2575 22.8887 8.45195 22.8887 3.22266 22.8887V19.666H51.7773V22.8887Z"
                  fill="#FE3838"
                ></path>
              </svg>
              <span name="" className="shopping-card__header">
                Koszyk
              </span>
              <div className="shopping-card__content">
                <div
                  id="wppizza-cart-2-0"
                  className="wppizza-cart wppizza-cart-nocache"
                >
                  <div className="wppizza-cart-info">
                    <table className="wppizza-table wppizza-order-itemised">
                      <tbody
                        className={
                          this.state.cart.length === 0
                            ? "wppizza-table-row-group d-none"
                            : "wppizza-table-row-group"
                        }
                      >
                        {this.state.cart.map((data, index) => (
                          <tr className="wppizza-item-row wppizza-item-row-first">
                            <td className="wppizza-item-quantity">
                              <span
                                className="ui-spinner ui-corner-all ui-widget ui-widget-content"
                                style={{ height: "26px" }}
                              >
                                <input
                                  type="text"
                                  size="3"
                                  className="wppizza-cart-mod ui-spinner-input"
                                  name="wppizza-cart-mod-1.53.452.2.1"
                                  value={data.counter}
                                  aria-valuemin="0"
                                  aria-valuenow="2"
                                  autoComplete="off"
                                  role="spinbutton"
                                />
                                <a
                                  tabIndex="-1"
                                  aria-hidden="true"
                                  className="ui-button ui-widget ui-spinner-button ui-spinner-up ui-corner-tr ui-button-icon-only"
                                  role="button"
                                  onClick={(e) =>
                                    this.updateCounterCard(e, index)
                                  }
                                >
                                  <span className="ui-button-icon ui-icon ui-icon-triangle-1-n"></span>
                                  <span className="ui-button-icon-space">
                                    {" "}
                                  </span>
                                </a>
                                {data.counter > 1 ? (
                                  <a
                                    tabIndex="-1"
                                    aria-hidden="true"
                                    className="ui-button ui-widget ui-spinner-button ui-spinner-down ui-corner-br ui-button-icon-only"
                                    role="button"
                                    onClick={(e) =>
                                      this.updateCounterCard(e, index)
                                    }
                                  >
                                    <span className="ui-button-icon ui-icon ui-icon-triangle-1-s"></span>
                                    <span className="ui-button-icon-space">
                                      {" "}
                                    </span>
                                  </a>
                                ) : (
                                  ""
                                )}
                              </span>
                            </td>
                            <td className="wppizza-item-article">
                              <span className="wppizza-item-title">
                                {data.name}
                              </span>
                              {data.size !== undefined ? (
                                <span className="wppizza-item-size">
                                  rozmiar: {data.size}
                                </span>
                              ) : (
                                ""
                              )}
                              {data.info.map((data, index) => (
                                <span className="wppizza-item-size">
                                  {data.counter === 0 ? `BEZ ${data.name}` : ""}
                                  {data.type === "base" && data.counter > 1
                                    ? `${data.counter} x ${data.name}`
                                    : ""}
                                  {data.type === "cake"
                                    ? `ciasto ${data.name}`
                                    : ""}
                                  {data.type === "extend"
                                    ? `${data.counter} x EXTRA ${data.name}`
                                    : ""}
                                </span>
                              ))}
                              {data.infoPromo &&
                                data.infoPromo.map((data, index) => (
                                  <span className="wppizza-item-size">
                                    {data.type === "base"
                                      ? `pizza ${data.name}`
                                      : ""}
                                    {data.type === "extend"
                                      ? `${data.name}`
                                      : ""}
                                    {data.type === "cake"
                                      ? `ciasto ${data.name}`
                                      : ""}
                                  </span>
                                ))}
                            </td>
                            <td className="wppizza-item-total">{data.price}</td>
                            <td className="wppizza-item-delete">
                              <img
                                src={trash}
                                onClick={(e) => this.removeFromCart(e, index)}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <input
                      type="hidden"
                      className="wppizza-open"
                      name="wppizza-open"
                    />
                    {this.state.cart.length === 0 ? (
                      <p className="wppizza-cart-empty">
                        Twój koszyk jest pusty
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div
                  id="wppizza-orders-pickup-choice-2"
                  className="wppizza-orders-pickup-choice"
                >
                  <label>
                    <input
                      type="checkbox"
                      className="wppizza-order-pickup"
                      name="wppizza-order-pickup"
                      value="1"
                    />
                    Odbiór osobisty
                  </label>
                </div>
                <div item-box="" className="shopping-card__item-box">
                  <div id="productCartItems"></div>
                  <span className="shopping-card__break d-none"></span>
                  <div className="shopping-card__result-box d-none">
                    <span className="shopping-card__result-box__info">
                      Rabat
                    </span>
                    <span className="shopping-card__result-box__price">
                      0 zł
                    </span>
                  </div>
                  <div className="shopping-card__result-box">
                    <span className="shopping-card__result-box__info">
                      Dostawa
                    </span>
                    <span className="shopping-card__result-box__price deliverPrice">
                      +0 zł
                    </span>
                  </div>
                  {sessionStorage.getItem("discountRealValue") ? (
                    <div className="shopping-card__result-box">
                      <span className="shopping-card__result-box__info">
                        Zniżka
                      </span>
                      <span className="shopping-card__result-box__price">
                        -{" "}
                        {sessionStorage
                          .getItem("discountRealValue")
                          .replace(".", ",")}{" "}
                        zł
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="shopping-card__result-box">
                    <span className="shopping-card__result-box__info">
                      Łącznie do zapłaty:
                    </span>
                    <span className="shopping-card__result-box__price totalShoppingCart">
                      <div
                        id="wppizza-totals-2"
                        className="wppizza-totals-container wppizza-totals wppizza-totals-no-items"
                      >
                        <span className="wppizza-totals-order">
                          {this.props.sum}
                        </span>
                      </div>
                    </span>
                  </div>
                </div>
                <div checkbox="" className="shopping-card__option">
                  <div className="check-box">
                    <input
                      className="check-box__input"
                      type="checkbox"
                      name=""
                      id="ownDelivery"
                    />
                    <label
                      className="check-box__label"
                      htmlFor="ownDelivery"
                      onClick={(e) => this.delivery(e)}
                    >
                      <Checkbox />
                    </label>
                    <label
                      className="check-box__label-text"
                      htmlFor="ownDelivery"
                      onClick={(e) => this.delivery(e)}
                    >
                      Odbiór osobisty
                    </label>
                  </div>
                  <input
                    input=""
                    className="input__input input__input--shopping-card d-none"
                    type="text"
                  />
                  <Link
                    to="/podsumowanie-zamowienia/"
                    button=""
                    className="btn sendCart"
                    onClick={(e) => this.getOrder(e)}
                  >
                    Zamów
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="background-menu">
          <div className="background-menu__container">
            <div className="background-menu__top" title="shape top">
              &nbsp;
            </div>
            <div className="background-menu__pizza" title="pizza">
              &nbsp;
            </div>
            <div className="background-menu__basil" title="basil">
              &nbsp;
            </div>
            <div className="background-menu__basil-mobile" title="basil">
              &nbsp;
            </div>
            <div className="background-menu__tomato" title="tomato">
              &nbsp;
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default withRouter(Place);