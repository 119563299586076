import React, {Component} from "react";
import Navbar from "../components/navbar/Navbar";
import Checkbox from "../components/checkbox/Checkbox";
import {Link} from "react-router-dom";
import trash from "../assets/trash.svg";

class Cart extends Component {
    state = {
        cart: [],
        qNumber: 0
    }

    componentDidMount() {
        let tempDeliver = sessionStorage.getItem("codeDelivery");
        if (!isNaN(parseFloat(tempDeliver))) {
            document.querySelector(".deliverPrice").innerText =
                "+" + tempDeliver + " zł";
        } else {
            document.querySelector(".deliverPrice").innerText = "+0 zł";
        }
        this.updateCart();
    }

    sumCart = () => {
        let sum = 0;
        let counter = 0;
        this.state.cart.map((item) => {
            let price = item.price.replace(',', '.');
            price = price.replace(' zł', '');
            sum += parseFloat(price);
            counter += 1;
        })
        if (!isNaN(parseFloat(sessionStorage.getItem('codeDelivery')))) {
            sum += parseFloat(sessionStorage.getItem('codeDelivery'))
        }
        if (!isNaN(parseFloat(sessionStorage.getItem('discountRealValue')))) {
            sum -= parseFloat(sessionStorage.getItem('discountRealValue'));
        }
        sum = sum.toFixed(2);
        sum =sum.toString().replace('.', ',') + ' zł';
        this.props.changeSum(sum, counter);
    }

    removeFromCart = (e, queNumber) => {
        e.preventDefault();
        this.setState({
            qNumber: queNumber
        })
        document.querySelector(".confirm-modal-delete").style.display = "block";
        document.querySelector(".confirm-modal-delete .updateDelete").addEventListener("click", (e) => {
            e.preventDefault();
            if (document.querySelector(".confirm-modal-delete").style.display === 'block') {
                document.querySelector(".confirm-modal-delete").style.display = "none";
                let localCart = [];
                if (JSON.parse(sessionStorage.getItem("localCart"))) {
                    localCart = JSON.parse(sessionStorage.getItem("localCart"));
                }
                localCart.splice(this.state.qNumber, 1);
                sessionStorage.setItem("localCart", JSON.stringify(localCart));
                this.updateCart();
            }
        });
    };

    updateCart = () => {
        let localCart = [];
        if (JSON.parse(sessionStorage.getItem('localCart'))) {
            localCart = JSON.parse(sessionStorage.getItem('localCart'))
        }
        this.setState({
            cart: localCart
        })
        let self = this;
        setTimeout(function(){
            self.sumCart();
        }, 1);
    }

    updateCounterCard = (e, queNumber) => {
        e.preventDefault();
        let localCart = [];
        if (JSON.parse(sessionStorage.getItem('localCart'))) {
            localCart = JSON.parse(sessionStorage.getItem('localCart'))
        }
        let currentValue = e.target.closest('.wppizza-item-quantity').querySelector('.ui-spinner-input').value;
        let condition = 'plus';
        if (e.target.classList.contains('ui-spinner-down')){
            condition = 'minus';
        }
        if (e.target.closest('a').classList.contains('ui-spinner-down')){
            condition = 'minus';
        }
        let newValue = 1;
        if (condition === 'plus') {
            newValue = parseInt(currentValue) + 1;
        } else {
            if (currentValue > 1) {
                newValue = parseInt(currentValue) - 1;
            }
        }
        localCart.map((item, index) => {
            if (index === queNumber) {
                let oldPrice = item.price.substring(0, item.price.lastIndexOf(' zł')).replace('.', ',');
                oldPrice = parseFloat(oldPrice) / parseInt(item.counter);
                item.counter = parseInt(newValue);
                let tempPrice = parseFloat(oldPrice) * parseInt(item.counter);
                let newPrice = tempPrice.toFixed(2)
                item.price = newPrice.toString().replace('.', ',') + ' zł'
            }
            return true;
        })
        sessionStorage.setItem('localCart', JSON.stringify(localCart));
        this.updateCart();
    }

    delivery = (e) => {
        if (document.querySelector('#ownDelivery').checked === true) {
            sessionStorage.setItem('ownDelivery', 'false');
        } else {
            sessionStorage.setItem('ownDelivery', 'true');
        }
        if (sessionStorage.getItem('codeDelivery') !== null) {
            let tempDeliver = sessionStorage.getItem('codeDelivery');
            if((!isNaN(parseFloat(tempDeliver))) && sessionStorage.getItem('ownDelivery') !== 'true') {
                document.querySelector('.deliverPrice').innerText = '+' + tempDeliver + ' zł';
            } else {
                document.querySelector('.deliverPrice').innerText = '+0 zł';
            }
        } else {
            document.querySelector('.deliverPrice').innerText = '+0 zł';
        }
    }
    getOrder = (e) => {
        e.preventDefault();
        let summary = document.querySelector('.cartLink').getAttribute('data-summary');
        if (sessionStorage.getItem('ownDelivery') && sessionStorage.getItem('ownDelivery') === 'true' && parseFloat(summary) > 0) {
            window.location.href = "/podsumowanie-zamowienia/";
        } else {
            console.log(parseFloat(summary) > parseInt(sessionStorage.getItem('codeMinimum')))
            if (parseFloat(summary) > parseInt(sessionStorage.getItem('codeMinimum'))) {
                window.location.href = "/podsumowanie-zamowienia/";
            } else {
                document.querySelector('.price-modal .text__w300').innerText = `minimalna kwota do zamówień z dostawą to ${sessionStorage.getItem('codeMinimum')} zł`;
                document.querySelector('.price-modal').style.display = "block";
            }
        }
    }

    render() {
        return (
            <>
                <Navbar sum={this.props.sum} counter={this.props.counter} summary={this.props.summary} />
                <section id="pizzamenu" className="menu-section cartSection">
                    <Link to={sessionStorage.getItem('lokalUrl') ? sessionStorage.getItem('lokalUrl'): "/"} className="cartBack">
                        <div className="arrow" />
                        <p>Powrót do menu</p>
                    </Link>
                    <div className="menu-section__cart mobile">
                        <div className="shopping-card">
                            <svg width="55" height="49" viewBox="0 0 55 49" fill="none" className="shopping-card__icon"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M53.3887 16.4433H47.7178L35.311 0.934845C34.7552 0.240148 33.7412 0.127247 33.0462 0.683263C32.3513 1.23917 32.2386 2.25313 32.7946 2.94804L43.5908 16.4433H11.4092L22.2055 2.94804C22.7614 2.25313 22.6487 1.23906 21.9539 0.683263C21.2589 0.127247 20.2449 0.24004 19.6891 0.934845L7.28224 16.4433H1.61133C0.721445 16.4433 0 17.1648 0 18.0547V24.5C0 25.3899 0.721445 26.1113 1.61133 26.1113H3.61861L9.73006 47.5013C9.92761 48.1931 10.5599 48.6699 11.2793 48.6699H43.7207C44.4401 48.6699 45.0724 48.1931 45.2701 47.5013L51.3814 26.1113H53.3887C54.2786 26.1113 55 25.3899 55 24.5V18.0547C55 17.1648 54.2786 16.4433 53.3887 16.4433ZM42.5053 45.4473H12.4947L6.97018 26.1113H48.0299L42.5053 45.4473ZM51.7773 22.8887C46.2575 22.8887 8.45195 22.8887 3.22266 22.8887V19.666H51.7773V22.8887Z"
                                    fill="#FE3838"></path>
                            </svg>
                            <span name="" className="shopping-card__header">Koszyk</span>
                            <div className="shopping-card__content">
                                <div id="wppizza-cart-2-0" className="wppizza-cart wppizza-cart-nocache">
                                    <div className="wppizza-cart-info">
                                        <table className="wppizza-table wppizza-order-itemised">
                                            <tbody className={this.state.cart.length === 0 ? "wppizza-table-row-group d-none" : "wppizza-table-row-group"}>
                                            {this.state.cart.map((data, index) => (
                                                <tr className="wppizza-item-row wppizza-item-row-first">
                                                    <td className="wppizza-item-quantity">
                                                            <span className="ui-spinner ui-corner-all ui-widget ui-widget-content" style={{height: '26px'}}>
                                                                <input
                                                                    type="text"
                                                                    size="3"
                                                                    className="wppizza-cart-mod ui-spinner-input"
                                                                    name="wppizza-cart-mod-1.53.452.2.1"
                                                                    value={data.counter} aria-valuemin="0"
                                                                    aria-valuenow="2"
                                                                    autoComplete="off"
                                                                    role="spinbutton" />
                                                                <a tabIndex="-1" aria-hidden="true" className="ui-button ui-widget ui-spinner-button ui-spinner-up ui-corner-tr ui-button-icon-only" role="button" onClick={(e) => this.updateCounterCard(e, index)}>
                                                                    <span className="ui-button-icon ui-icon ui-icon-triangle-1-n"></span>
                                                                    <span className="ui-button-icon-space"> </span>
                                                                </a>
                                                                {data.counter > 1 ?
                                                                    <a tabIndex="-1" aria-hidden="true" className="ui-button ui-widget ui-spinner-button ui-spinner-down ui-corner-br ui-button-icon-only" role="button" onClick={(e) => this.updateCounterCard(e, index)}>
                                                                        <span className="ui-button-icon ui-icon ui-icon-triangle-1-s"></span>
                                                                        <span className="ui-button-icon-space"> </span>
                                                                    </a>
                                                                : ''}
                                                            </span>
                                                    </td>
                                                    <td className="wppizza-item-article">
                                                        <span className="wppizza-item-title">{data.name}</span>
                                                        {data.size !== undefined ?
                                                            <span className="wppizza-item-size">rozmiar: {data.size}</span>
                                                            : ''}
                                                        {data.info.map((data, index) => (
                                                            <span className="wppizza-item-size">{data.counter === 0 ? `BEZ ${data.name}` : ''}{data.type === 'base' && data.counter > 1 ? `${data.counter} x ${data.name}` : ''}{data.type === 'cake' ? `ciasto ${data.name}` : ''}{data.type === 'extend' ? `${data.counter} x EXTRA ${data.name}` : ''}</span>
                                                        ))}
                                                        {data.infoPromo && data.infoPromo.map((data, index) => (
                                                            <span className="wppizza-item-size">{data.type === 'base' ? `pizza ${data.name}` : ''}{data.type === 'extend' ? `${data.name}` : ''}{data.type === 'cake' ? `ciasto ${data.name}` : ''}</span>
                                                        ))}
                                                    </td>
                                                    <td className="wppizza-item-total">{data.price}</td>
                                                    <td className="wppizza-item-delete">
                                                        <img src={trash} onClick={(e) => this.removeFromCart(e, index)} />
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                        <input type="hidden" className="wppizza-open" name="wppizza-open" />
                                        {this.state.cart.length === 0 ? <p className="wppizza-cart-empty">Twój koszyk jest pusty</p> : ''}</div>
                                </div>
                                <div id="wppizza-orders-pickup-choice-2" className="wppizza-orders-pickup-choice">
                                    <label><input type="checkbox" className="wppizza-order-pickup"
                                                  name="wppizza-order-pickup" value="1" />Odbiór osobisty</label></div>
                                <div item-box="" className="shopping-card__item-box">
                                    <div id="productCartItems"></div>
                                    <span className="shopping-card__break d-none"></span>
                                    <div className="shopping-card__result-box d-none">
                                        <span className="shopping-card__result-box__info">Rabat</span>
                                        <span className="shopping-card__result-box__price">0 zł</span>
                                    </div>
                                    <div className="shopping-card__result-box">
                                        <span className="shopping-card__result-box__info">Dostawa</span>
                                        <span className="shopping-card__result-box__price deliverPrice">+0 zł</span>
                                    </div>
                                    {sessionStorage.getItem('discountRealValue') ?
                                      <div className="shopping-card__result-box">
                                          <span className="shopping-card__result-box__info">Zniżka</span>
                                          <span className="shopping-card__result-box__price">- {sessionStorage.getItem('discountRealValue').replace(".", ",")}{" "}
                                              zł</span>
                                      </div>
                                      : ''}
                                    <div className="shopping-card__result-box">
                                        <span className="shopping-card__result-box__info">Łącznie do zapłaty:</span>
                                        <span className="shopping-card__result-box__price totalShoppingCart"><div
                                            id="wppizza-totals-2"
                                            className="wppizza-totals-container wppizza-totals wppizza-totals-no-items"><span
                                            className="wppizza-totals-order">{this.props.sum}</span></div></span>
                                    </div>
                                </div>
                                <div checkbox="" className="shopping-card__option">
                                    <div className="check-box">
                                        <input className="check-box__input" type="checkbox" name="" id="ownDelivery" />
                                        <label className="check-box__label" htmlFor="ownDelivery" onClick={(e) => this.delivery(e)}>
                                            <Checkbox />
                                        </label>
                                        <label className="check-box__label-text" htmlFor="ownDelivery" onClick={(e) => this.delivery(e)}>Odbiór
                                            osobisty</label>
                                    </div>
                                    <input input="" className="input__input input__input--shopping-card d-none" type="text" />
                                    <Link to="/podsumowanie-zamowienia/" button=""
                                          className="btn sendCart" onClick={(e) => this.getOrder(e)}>Zamów</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default Cart;