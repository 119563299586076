import {toast} from "react-toastify";

export const Connect = (url, method, body, type = 'json', isText = false, raw= false) => {
    return (
        fetch(url,{
            method,
            headers: {
                'Content-Type': 'application/json',
                'Authorization':
                  sessionStorage.getItem('jhi-authenticationToken') == null
                    ? ''
                    : 'Bearer ' + JSON.parse(sessionStorage.getItem('jhi-authenticationToken')),
                'Accept-Language': localStorage.getItem('lang') || 'pl',
            },
            body: body ? (type !== 'json' ? body : JSON.stringify(body)) : null,
            //body: body ? JSON.stringify(body) : body,
        })
            .then(response => {
                // console.log(response)
                if(!response.ok){
                    // throw response
                    return Promise.reject(response);
                } else {
                    // if(method !== 'GET' && method !== 'POST'){
                    //     toast.success("Zmiany zostały zapisane!", {
                    //         position: toast.POSITION.BOTTOM_RIGHT,
                    //     });
                    // }
                    return response;
                }
            })
            .then(res => {
                if(raw){
                    return res;
                } else if (isText) {
                    return res.text()
                } else {
                    res.method = method;
                    if(method === 'DELETE' || raw){
                        return res;
                    } else {
                        return res.json();
                    }
                }
            })
            .catch((error) => {
                if(error.status === 500){
                    return "Błąd serwera";
                } else if (error.status === 401) {
                    if (typeof window !== 'undefined') {
                        if (localStorage.getItem('token')) {
                            localStorage.removeItem('token')
                        }
                        if (sessionStorage.getItem('token')) {
                            sessionStorage.removeItem('token')
                        }
                        if (localStorage.getItem('user')) {
                            localStorage.removeItem('user')
                        }
                        if (sessionStorage.getItem('user')) {
                            sessionStorage.removeItem('user')
                        }
                    }
                    toast.info("\"Błąd autoryzacji, Zaloguj się ponownie!", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    return error
                    //return "Błąd autoryzacji, Zaloguj się ponownie!";
                }
                else {
                    return error
                }
            })
            .then((error) => {
                // console.log(error)
                if(error !== 'Ok') {
                    toast.error(error, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
                return error;
            })
    )
}
