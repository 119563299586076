import React, {Component} from "react";
import Navbar from "../components/navbar/Navbar";
import {Link} from "react-router-dom";
import Footer from "../components/footer/Footer";
import Checkbox from "../components/checkbox/Checkbox";
import ReCAPTCHA from "react-google-recaptcha";
import {Connect} from "../components/connect/Connect";
import * as endpoint from "../endpoints/Endpoints";
import { Helmet } from "react-helmet"; // Import react-helmet

const SITE_KEY = "6LexmZIgAAAAAH1Z5nPfuzpzbCYyQ13aBKte65zP";
const recaptchaRef = React.createRef();

class Contact extends Component{
    state = {
        recaptcha: ''
    }

    componentDidMount() {
        let nav = document.querySelectorAll('.navbar__link');
        for (let i = 0; i < nav.length; i++) {
            nav[i].classList.remove('is-active');
        }
        document.querySelector('.navbar__left-box .navbar__link:nth-child(6)').classList.add('is-active');
        const modal = document.querySelector('.primary-modal');
        document.querySelector('.form-grid--contact .js-active-modal').addEventListener('click', (e) => {
            e.preventDefault();
            //modal.style.display = "block"
            validateForm(document.querySelector('.form-grid--contact'))
        })
        document.querySelector('.form-grid--contact').addEventListener('submit', (e) => {
            e.preventDefault();
        })
        let self = this;
        // Set Error Message
        function setError(input, errormsg) {
            const formGroup = input.parentElement;
            const inputAlert = formGroup.querySelector(".input__error");
            formGroup.className = "form__input input js-input error";
            inputAlert.innerText = errormsg;
        }
        // Set Success Message
        function setSuccess(input) {
            const formGroup = input.parentElement;
            const inputAlert = formGroup.querySelector(".input__error");
            if (inputAlert !== null) {
                inputAlert.innerText = '';
            }
            formGroup.className = "form__input input js-input success";
        }
        // Set Error Message
        function setErrorT(input, errormsg) {
            const formGroup = input.parentElement;
            const inputAlert = formGroup.querySelector(".input__error");
            formGroup.className = "input input--text-area form-group error";
            if (inputAlert !== null) {
                inputAlert.innerText = errormsg;
            }
        }
        // Set Success Message
        function setSuccessT(input) {
            const formGroup = input.parentElement;
            const inputAlert = formGroup.querySelector(".input__error");
            if (inputAlert !== null) {
                inputAlert.innerText = '';
            }
            formGroup.className = "input input--text-area form-group success";
        }
        // Set Error Message
        function setErrorRadioAccept(input, errormsg) {
            const formGroup = input.parentElement.closest(
                ".js-input"
            );
            const inputAlert = formGroup.querySelector(".error");
            formGroup.className = "js-input error";
            inputAlert.innerText = errormsg;
        }
        // Set Success Message
        function setSuccessRadioAccept(input) {
            const formGroup = input.parentElement.closest(
                ".js-input"
            );
            const inputAlert = formGroup.querySelector(".error");
            if (inputAlert !== null) {
                inputAlert.innerText = "";
            }
            formGroup.className = "js-input success";
        }
        function setSuccessRecaptcha() {
            const recaptcha = document.querySelector('.recaptcha');
            const inputAlert = recaptcha.querySelector('.error');
            if (inputAlert !== null) {
                inputAlert.innerText = "";
            }
            recaptcha.className = "recaptcha success";
        }
        function setErrorRecaptcha(errormsg) {
            const recaptcha = document.querySelector('.recaptcha');
            const inputAlert = recaptcha.querySelector('.error');
            recaptcha.className = "recaptcha error";
            inputAlert.innerText = errormsg;
        }
        function validEmail(email) {
            const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        }
        // Form Validation Check
        function validateForm(form) {
            let complete = true
            if (form.name.value.trim() === "") {
                setError(form.name, "Wymagane pole");
                complete = false;
            } else {
                setSuccess(form.name);
            }
            if (form.surname.value.trim() === "") {
                setError(form.surname, "Wymagane pole");
                complete = false;
            } else {
                setSuccess(form.surname);
            }

            if (form.terms.checked === false) {
                setErrorRadioAccept(form.terms, "Wymagane pole");
                complete = false;
            } else {
                setSuccessRadioAccept(form.terms);
            }

            if (form.email.value.trim() === "") {
                setError(form.email, "Wymagane pole");
                complete = false;
            } else if (!validEmail(form.email.value.trim())) {
                setError(form.email, "Nieprawidłowy Email");
                complete = false;
            } else {
                setSuccess(form.email);
            }

            if (form.content.value.trim() === "") {
                setErrorT(form.content, "Message cannot be blank");
                complete = false;
            } else {
                setSuccessT(form.content);
            }
            if (self.state.recaptcha !== '') {
                setSuccessRecaptcha();
            } else {
                setErrorRecaptcha('ReCaptcha jest polem wymaganym')
                complete = false;
            }
            if (complete === true) {
                let data = {
                    consent: false,
                    email: form.email.value,
                    message: form.content.value,
                    name: form.name.value,
                    surname: form.surname.value,
                }

                Connect(`${endpoint.backend}${endpoint.sendContactMessage}`, 'POST', data).then(response => {
                    modal.style.display = "block";
                    form.name.value = '';
                    form.surname.value = '';
                    form.email.value = '';
                    form.content.value = '';
                    document.querySelector('#terms').click();
                })
            }

            return true;
        }
    }

    changeRecaptcha = (value) => {
        this.setState({
            recaptcha: value
        })
        console.log(value)
    }

    closeModal = () => {
        document.querySelector('.primary-modal').style.display = "none";
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>Kontakt - Pizza Bunga</title>
                    <meta name="description" content="Gorąca pizza z puszystym ciastem, szybka i darmowa dostawa we Wrocławiu! Zamów pyszne pizze z dowozem do domu i ciesz się wyjątkowym smakiem już dziś!" />
                    <meta property="og:title" content="Kontakt - Pizza Bunga" />
                    <meta property="og:description" content="Gorąca pizza z puszystym ciastem, szybka i darmowa dostawa we Wrocławiu! Zamów pyszne pizze z dowozem do domu i ciesz się wyjątkowym smakiem już dziś!" />
                    <meta property="og:image" content="https://pizzabunga.pl/static/media/logo.cf443991.svg" />
                    <meta property="og:url" content="https://www.pizzabunga.pl/kontakt/" />
                </Helmet>
                <Navbar sum={this.props.sum} counter={this.props.counter} summary={this.props.summary} />
                <div className="contact-section">
                    <section className="contact-section__wrapper">
                        <h1 header="" className="text__large--2 text_w700">Masz jakieś pytania?</h1>
                        <form className="form-grid form-grid--contact" action="#">
                            <h3 col-full="" className="contact-section__form-header text__medium--2 text__w300">Napisz do
                                nas</h3>

                            <div col-1-2="" className="form__input input js-input" data-error="false">
                                <input className="input__input" type="text" id="name" placeholder="Imię"/>
                                <label className="input__label" htmlFor="name">Imię</label>
                                <span className="input__error"></span>
                            </div>

                            <div col-1-2="" className="form__input input js-input" data-error="false">
                                <input className="input__input" type="text" id="surname" placeholder="Nazwisko" />
                                <label className="input__label" htmlFor="surname">Nazwisko</label>
                                <span className="input__error"></span>
                            </div>

                            <div col-1-2="" className="form__input input js-input" data-error="false">
                                <input className="input__input" type="email" id="email" placeholder="E-mail" />
                                <label className="input__label" htmlFor="E-mail">E-mail</label>
                                <span className="input__error"></span>
                            </div>

                            <div textarea="" col-2-3="" row-2-5="" className="js-input" data-type="street"
                                 data-error="false">
                                <div className="input input--text-area">
                                <textarea className="input__input" id="content"
                                          placeholder="Treść wiadomości"></textarea>
                                    <label className="input__label" htmlFor="content">Treść wiadomości</label>
                                </div>
                            </div>

                            <div col-2-3="" className="js-input" data-error="false">
                                <input className="check-box__input" type="checkbox" name="" id="terms" />
                                <label className="check-box__label" htmlFor="terms">
                                    <Checkbox />
                                </label>
                                <label className="check-box__label-text text__small text__w300 text__lh-20"
                                       htmlFor="terms">Administratorem Twoich danych osobowych jest {sessionStorage.getItem('lokalID') === '7770101' ? 'M&M GASTRO Michał Bartczyszyn Chopina 24/2, kod pocztowy 48-340, NIP: 7532434659,' : 'Kraft Polska spółka z ograniczoną odpowiedzialnością z siedzibą we Wrocławiu przy ul. Rogowskiej 7, kod pocztowy 54 – 400, NIP: 8942996311, o kapitale zakładowym w wysokości 100&nbsp;000 zł której akta rejestrowe są prowadzone przez Sąd Rejonowy dla Wrocławia – Fabrycznej we Wrocławiu, VI Wydział Gospodarczy pod numerem KRS: 0000353723.'} Więcej informacji
                                    na temat przetwarzania danych osobowych znajdziesz w naszej <Link
                                        to={sessionStorage.getItem('lokalID') === '7770101' ? "/polityka-prywatnosci/?poleska=true" : "/polityka-prywatnosci/"}>Polityce
                                        Prywatności</Link> .</label>
                                <span className='error checkboxError'></span>
                            </div>
                            <div className="recaptcha" col-2-3="">
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    sitekey={SITE_KEY}
                                    onChange={this.changeRecaptcha}
                                />
                                <span className="error checkboxError errorRecaptcha"></span>
                            </div>
                            <div btn="" col-2-3="">
                                <button type="submit" className="btn btn--right btn--normal js-active-modal">Wyślij</button>
                            </div>
                        </form>
                        <div className="background-contact">
                            <div className="background-contact__container">
                                <div className="background-contact__tomato-1">&nbsp;</div>
                                <div className="background-contact__tomato-2">&nbsp;</div>
                                <div className="background-contact__pizza">&nbsp;</div>
                                <div className="background-contact__basil">&nbsp;</div>
                            </div>
                        </div>
                        <div className="modal js-modal primary-modal">
                            <div className="modal__content">
                                <div className="close-icon js-modal-close">
                                    <div className="close-icon__stick-1"></div>
                                    <div className="close-icon__stick-2"></div>
                                </div>
                                <div className="modal__info">
                                    <h1 className="text__medium--2 text__w600 mrg-bottom-30">Komunikat</h1>
                                    <h2 className="text__medium text__w300">Pomyślnie wysłano!</h2>
                                </div>
                                <button btn="" menu="" className="btn  btn--normal js-modal-btn" onClick={() => this.closeModal()}>Ok</button>
                            </div>
                        </div>
                    </section>
                    <div className="footer-contact-box">
                        <Footer />
                    </div>
                </div>
                <div className="modal js-modal primary-modal">
                    <div className="modal__content">
                        <div className="close-icon js-modal-close">
                            <div className="close-icon__stick-1"></div>
                            <div className="close-icon__stick-2"></div>
                        </div>
                        <div className="modal__info">
                            <h1 className="text__medium--2 text__w600 mrg-bottom-30">Komunikat</h1>
                            <h2 className="text__medium text__w300">Pomyślnie wysłano!</h2>
                        </div>
                        <button btn="" menu="" className="btn  btn--normal js-modal-btn" onClick={() => this.closeModal()}>Ok</button>
                    </div>
                </div>
            </>
        )
    }
}

export default Contact;