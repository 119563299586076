import React, {Component} from "react";
import Welcome from "../welcome/Welcome";
import './_popup.scss';

class Popup extends Component {
    close = (e) => {
        if (!e.target.closest('.popup__content')) {
            this.props.handlePopup(false);
        }
    }

    render() {
        return (
            <div className={this.props.active === true ? "popup is-active" : "popup"} onClick={(e) => this.close(e)}>
                <div className="popup__content">
                    {this.props.empty === true ?
                        <div className="modal__info">
                            <h1 className="text__medium--2 text__w600 mrg-bottom-30">
                                Komunikat
                            </h1>
                            <h2 className="text__medium text__w300">
                                Przepraszamy, wybrany produkt nie znajduje się w menu wybranego lokalu, czy mimo to chcesz przejść?
                            </h2>
                            <div className="popup__buttons">
                                <button
                                    btn=""
                                    menu=""
                                    className="btn  btn--normal"
                                    onClick={() => this.props.handleEmpty(false)}
                                >
                                    Wróć
                                </button>
                                <button
                                    btn=""
                                    menu=""
                                    className="btn  btn--normal"
                                    onClick={() => this.props.handleIgnore()}
                                >
                                    Tak
                                </button>
                            </div>
                        </div>
                    :
                        <Welcome cities={this.props.cities} popup={true} handleClick={this.props.handleClick}/>
                    }
                </div>
            </div>
        )
    }
}

export default Popup;