import {Component} from "react";
import { withRouter } from "react-router-dom";

class ScrollToTop extends Component {

    componentDidUpdate(prevProps) {
        if ((this.props.location !== prevProps.location) && (this.props.location.search === '') && (this.props.location.hash === '')) {
            document.querySelector('.main-container').scrollIntoView();
        }
    }
    render() {
        return this.props.children
    }
}

export default withRouter(ScrollToTop);