import React, {Component} from "react";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";

class Confirm extends Component {
    state = {
        ownDelivery: sessionStorage.getItem('ownDelivery')
    }

    componentDidMount() {
        sessionStorage.removeItem('discountRealValue')
        sessionStorage.removeItem('discountType')
        sessionStorage.removeItem('discountValue')
        sessionStorage.removeItem('discountSum')
        sessionStorage.removeItem('discountName')
        sessionStorage.removeItem('discountID')
        sessionStorage.removeItem('discountID')
        let localCart = [];
        sessionStorage.setItem('localCart', JSON.stringify(localCart));
        let nav = document.querySelectorAll('.navbar__link');
        for (let i = 0; i < nav.length; i++) {
            nav[i].classList.remove('is-active');
        }
    }

    render() {
        return (
            <>
                <Navbar sum={this.props.sum} counter={this.props.counter} summary={this.props.summary} />
                <div className="info-page-wrapper">
                    <section className="info-page">
                        <div className="info-page__info-box">
                            <h1 header="" className="text__white text__large text__w600">Dziękujemy za złożenie zamówienia!</h1>
                            <h1 txt="" className="text__white text__medium--2 text__w300">{this.state.ownDelivery !== 'true' ?
                              'Poinformujemy Cię oprzewidywanym czasie dostarczenia poprzez wiadomosć e-mail.'
                              : 'Zamówienie będzie gotowe za 15 min. Czekamy na Ciebie w lokalu!' }</h1>
                        </div>
                        <div className="info-page-background">
                            <div className="info-page-background__container">
                                <div className="info-page-background__shape-top"></div>
                                <div className="info-page-background__pizza"></div>
                                <div className="info-page-background__tomato"></div>
                                <div className="info-page-background__tomato-1"></div>
                                <div className="info-page-background__basil"></div>
                                <div className="info-page-background__pizza-mobile d-none"></div>
                                <div
                                    className="info-page-background__basil-mobile info-page-background__basil-mobile--confirm"></div>
                                <div className="info-page-background__tomato-mobile"></div>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </div>
            </>
        )
    }
}

export default Confirm;