import React, {Component} from "react";
import {Link} from "react-router-dom";

class Footer extends Component {
    render() {
        return (
            <footer className="footer">
                <Link to={sessionStorage.getItem('lokalID') === '7770101' ? "/polityka-prywatnosci/?poleska=true" : "/polityka-prywatnosci/"} className="text__small text__w300" target="_blank" rel="noopener noreferrer">Polityka
                    prywatności</Link>
                <a href={sessionStorage.getItem('lokalID') === '7770101' ? "https://pizzabunga.pl/Regulamin%20Poelska.pdf" : "https://pizzabunga.pl/Kraft_Polska_Regulamin_19.04.2021.docx.pdf"}
                   target="_blank" className="text__small text__w300">Regulamin zakupów</a>
                <a href="/"
                   className="text__small text__w300 d-none">Zgody marketingowe</a>
            </footer>
        )
    }
}

export default Footer;