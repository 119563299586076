import React, {Component, useEffect} from 'react';
import './App.css';
import {useDispatch, useSelector} from "react-redux";
import {login, logout, selectUser} from "./features/userSlice";
import Sidebar from "./components/sidebar/Sidebar";
import Loader from "./components/loader/Loader";
import './themes/_base.scss';
import './themes/_normalize.scss';
import './themes/_typograhy.scss';
import './themes/_animations.scss';
import './themes/components/_button.scss';
import './themes/components/_checkbox.scss';
import './themes/components/_close-icon.scss';
import './themes/components/_contact-box.scss';
import './themes/components/_counter.scss';
import './themes/components/_customize-item.scss';
import './themes/components/_hamburger.scss';
import './themes/components/_input.scss';
import './themes/components/_login-card.scss';
import './themes/components/_map.scss';
import './themes/components/_menu-item.scss';
import './themes/components/_navbar-busket.scss';
import './themes/components/_navbar-dropdown.scss';
import './themes/components/_order-item.scss';
import './themes/components/_pagination.scss';
import './themes/components/_shopping-card.scss';
import './themes/components/_sing-in-form.scss';
import './themes/components/_species-menu.scss';
import './themes/components/_text-area.scss';
import './themes/components/_work-item.scss';
import './themes/layout/_background.scss';
import './themes/layout/_background-about.scss';
import './themes/layout/_background-contact.scss';
import './themes/layout/_background-customize-pizza.scss';
import './themes/layout/_background-index.scss';
import './themes/layout/_background-localization.scss';
import './themes/layout/_background-menu.scss';
import './themes/layout/_background-user.scss';
import './themes/layout/_blured-background.scss';
import './themes/layout/_customize-result.scss';
import './themes/layout/_footer.scss';
import './themes/layout/_form-grid.scss';
import './themes/layout/_header.scss';
import './themes/layout/_info-page-background.scss';
import './themes/layout/_job-descriptions.scss';
import './themes/layout/_modal.scss';
import './themes/layout/_summary-deliver-box.scss';
import './themes/layout/_summary-payment-box.scss';
import './themes/layout/_summary-result-box.scss';
import './themes/layout/_summary-shopping-cart.scss';
import './themes/pages/_about.scss';
import './themes/pages/_change-password.scss';
import './themes/pages/_contact.scss';
import './themes/pages/_customize-pizza.scss';
import './themes/pages/_index.scss';
import './themes/pages/_info-page.scss';
import './themes/pages/_job-offers.scss';
import './themes/pages/_localizations.scss';
import './themes/pages/_menu.scss';
import './themes/pages/_order-history.scss';
import './themes/pages/_order-summary.scss';
import './themes/pages/_user-panel.scss';
import './themes/pages/_work-apply.scss';
import './themes/pages/_franchise.scss';
import './themes/pages/text-section.scss';
import {Switch, Route, BrowserRouter as Router} from "react-router-dom";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import Place from "./pages/Place";
import Summary from "./pages/Summary";
import Premises from "./pages/Premises";
import About from "./pages/About";
import Work from "./pages/Work";
import Privacy from "./pages/Privacy";
import ScrollToTop from "./components/scrollToTop/scrollToTop";
import Confirm from "./pages/Confirm";
import OwnPizza from "./pages/OwnPizza";
import ConfirmOffer from "./pages/ConfirmOffer";
import SpecialOffer from "./pages/SpecialOffer";
import Franchise from "./pages/Franchise";
import Cart from "./pages/Cart";
import {Connect} from "./components/connect/Connect";
import * as endpoint from "./endpoints/Endpoints";
import premises from "./pages/Premises";
import NotFound from "./pages/NotFound";

class App extends Component{
    state = {
        sum: '0,00 zł',
        counter: 0,
        summary: 0,
        premises: []
    }

    changeSum = (value, counter) => {
        let summary = value.replace(' zł', '');
        summary = summary.replace(',', '.');
        this.setState({
            sum: value,
            counter: counter,
            summary: parseFloat(summary).toFixed(2)
        })
    }

    componentDidMount() {
        let googleMaps = [];
        Connect(
            `${endpoint.backend}${endpoint.getPremises}`,
            "GET",
            null
        ).then((response) => {
            this.setState({
                premises: response,
            });
            response.map((data) => {
                googleMaps.push(data.mapLink)
            })

            sessionStorage.setItem('googleMaps', JSON.stringify(googleMaps));
        });
    }

    render() {
        return (
            <Router>
                <div className="app">
                    {/* Sidebar */}
                    <Sidebar/>

                    {/* Main */}
                    <main>
                        <div className="blured-background" data-blured='false'>
                            <div className="main-container">
                                <ScrollToTop>
                                    <Switch>
                                        {/*<Route path="/" exact component={() => {*/}
                                        {/*    window.location.href = "https://pizzabunga.pl/"*/}
                                        {/*}}></Route>*/}
                                        {/*<Route path="/homepage/"><Home sum={this.state.sum} counter={this.state.counter} summary={this.state.summary} /></Route>*/}
                                        <Route path="/" exact><Home sum={this.state.sum} counter={this.state.counter} summary={this.state.summary} /></Route>
                                        {this.state.premises.map((data) => (
                                            <Route path={'/' + data.slug + '/'}><Place sum={this.state.sum} counter={this.state.counter} summary={this.state.summary} changeSum={this.changeSum} desc={data.address} tel={data.phone} premiseID={data.id} slug={'/' + data.slug + '/'} googleMaps={data.mapLink} /></Route>
                                        ))}
                                        {/*<Route path="/fat/"><Place sum={this.state.sum} counter={this.state.counter} summary={this.state.summary} changeSum={this.changeSum} desc={'ul. Inżynierska 27a'} tel={'608 342 942'} /></Route>*/}
                                        {/*<Route path="/nowy-dwor/"><Place sum={this.state.sum} counter={this.state.counter} summary={this.state.summary} changeSum={this.changeSum} desc={'ul. Bulwar Dedala 18b'} tel={'513 741 320'} /></Route>*/}
                                        {/*<Route path="/srodmiescie/"><Place sum={this.state.sum} counter={this.state.counter} summary={this.state.summary} changeSum={this.changeSum} desc={'ul. Rydygiera 34'} tel={'508 603 460'} /></Route>*/}
                                        {/*<Route path="/tarnogaj/"><Place sum={this.state.sum} counter={this.state.counter} summary={this.state.summary} changeSum={this.changeSum} desc={'ul. Gazowa 88'} tel={'538 484 000'} /></Route>*/}
                                        <Route path="/podsumowanie-zamowienia/"><Summary sum={this.state.sum} counter={this.state.counter} summary={this.state.summary} changeSum={this.changeSum} /></Route>
                                        <Route path="/lokale/"><Premises sum={this.state.sum} counter={this.state.counter} summary={this.state.summary} /></Route>
                                        <Route path="/o-nas/"><About sum={this.state.sum} counter={this.state.counter} summary={this.state.summary} /></Route>
                                        <Route path="/praca/"><Work sum={this.state.sum} counter={this.state.counter} summary={this.state.summary} /></Route>
                                        <Route path="/franczyza/"><Franchise sum={this.state.sum} counter={this.state.counter} summary={this.state.summary} /></Route>
                                        <Route path="/kontakt/"><Contact sum={this.state.sum} counter={this.state.counter} summary={this.state.summary} /></Route>
                                        <Route path="/polityka-prywatnosci/"><Privacy sum={this.state.sum} counter={this.state.counter} summary={this.state.summary} /></Route>
                                        <Route path="/potwierdzenie-zamowienia/"><Confirm sum={this.state.sum} counter={this.state.counter} summary={this.state.summary} /></Route>
                                        <Route path="/potwierdzenie-cv/"><ConfirmOffer sum={this.state.sum} counter={this.state.counter} summary={this.state.summary} /></Route>
                                        <Route path="/koszyk"><Cart sum={this.state.sum} counter={this.state.counter} summary={this.state.summary} changeSum={this.changeSum} /></Route>
                                        <Route path="/wlasna-pizza/:id" render={(matchProps) =>
                                            <OwnPizza {...matchProps} {...this.props} sum={this.state.sum} counter={this.state.counter} summary={this.state.summary} />
                                            }
                                        />
                                        <Route path="/specjalna-oferta/:id" render={(matchProps) =>
                                            <SpecialOffer {...matchProps} {...this.props} sum={this.state.sum} counter={this.state.counter} summary={this.state.summary}/>
                                            }
                                        />
                                        {/*<Route path='*'><NotFound /></Route>*/}
                                    </Switch>
                                </ScrollToTop>
                            </div>
                        </div>
                    </main>
                    <Loader/>
                </div>
            </Router>
        );
  }
}

export default App;