import React, {Component} from "react";
import Navbar from "../components/navbar/Navbar";
import Checkbox from "../components/checkbox/Checkbox";
import {Connect} from "../components/connect/Connect";
import * as endpoint from "../endpoints/Endpoints";
import Footer from "../components/footer/Footer";
import { Helmet } from "react-helmet"; // Import react-helmet
import ReCAPTCHA from "react-google-recaptcha";
const SITE_KEY = "6LexmZIgAAAAAH1Z5nPfuzpzbCYyQ13aBKte65zP";
const recaptchaRef = React.createRef();

class Work extends Component{
    state = {
        recaptcha: ''
    }

    componentDidMount() {
        let nav = document.querySelectorAll('.navbar__link');
        for (let i = 0; i < nav.length; i++) {
            nav[i].classList.remove('is-active');
        }
        document.querySelector('.navbar__left-box .navbar__link:nth-child(4)').classList.add('is-active');

        let form =  document.querySelectorAll('.form-grid--work-apply');
        for (let i = 0; i < form.length; i++) {
            form[i].addEventListener('submit', (e) => {
                e.preventDefault();
                validateForm(form[i])
            })
        }
        let self = this;
        // Set Error Message
        function setError(input, errormsg) {
            const formGroup = input.parentElement;
            const inputAlert = formGroup.querySelector(".input__error");
            formGroup.className = "form__input input js-input error";
            inputAlert.innerText = errormsg;
        }
        // Set Success Message
        function setSuccess(input) {
            const formGroup = input.parentElement;
            const inputAlert = formGroup.querySelector(".input__error");
            if (inputAlert !== null) {
                inputAlert.innerText = '';
            }
            formGroup.className = "form__input input js-input success";
        }
        // Set Error Message
        function setErrorT(input, errormsg) {
            const formGroup = input.parentElement;
            const inputAlert = formGroup.querySelector(".input__error");
            formGroup.className = "work-apply-section__cv-terms-box error";
            inputAlert.innerText = errormsg;
        }
        // Set Success Message
        function setSuccessT(input) {
            const formGroup = input.parentElement;
            const inputAlert = formGroup.querySelector(".input__error");
            if (inputAlert !== null) {
                inputAlert.innerText = '';
            }
            formGroup.className = "work-apply-section__cv-terms-box success";
        }
        // Set Error Message
        function setErrorRadioAccept(input, errormsg) {
            const formGroup = input.parentElement.closest(
                ".check-box"
            );
            const inputAlert = formGroup.querySelector(".error");
            formGroup.className = "check-box error";
            inputAlert.innerText = errormsg;
        }
        // Set Success Message
        function setSuccessRadioAccept(input) {
            const formGroup = input.parentElement.closest(
                ".check-box"
            );
            const inputAlert = formGroup.querySelector(".error");
            if (inputAlert !== null) {
                inputAlert.innerText = "";
            }
            formGroup.className = "check-box success";
        }
        function setSuccessRecaptcha() {
            const recaptcha = document.querySelector('.recaptcha');
            const inputAlert = recaptcha.querySelector('.error');
            if (inputAlert !== null) {
                inputAlert.innerText = "";
            }
            recaptcha.className = "recaptcha success";
        }
        function setErrorRecaptcha(errormsg) {
            const recaptcha = document.querySelector('.recaptcha');
            const inputAlert = recaptcha.querySelector('.error');
            recaptcha.className = "recaptcha error";
            inputAlert.innerText = errormsg;
        }
        function validEmail(email) {
            const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        }
        // Form Validation Check
        function validateForm(form) {
            console.log(form)
            let complete = true
            if (form.offerName.value.trim() === "") {
                setError(form.offerName, "Wymagane pole");
                complete = false;
            } else {
                setSuccess(form.offerName);
            }
            if (form.offerSurname.value.trim() === "") {
                setError(form.offerSurname, "Wymagane pole");
                complete = false;
            } else {
                setSuccess(form.offerSurname);
            }

            if (form.offerEmail.value.trim() === "") {
                setError(form.offerEmail, "Wymagane pole");
                complete = false;
            } else if (!validEmail(form.offerEmail.value.trim())) {
                setError(form.offerEmail, "Nieprawidłowy Email");
                complete = false;
            } else {
                setSuccess(form.offerEmail);
            }

            if (form.terms.checked === false) {
                setErrorRadioAccept(form.terms, "Wymagane pole");
                complete = false;
            } else {
                setSuccessRadioAccept(form.terms);
            }

            if (form.offerFile.value.trim() === "") {
                setErrorT(form.offerFile, "Wymagany plik");
                complete = false;
            } else if (form.offerFile.value.trim().includes('.pdf')) {
                setSuccessT(form.offerFile);
            } else {
                setErrorT(form.offerFile, "Nieprawidłowy typ pliku");
                complete = false;
            }

            // if (form.confirm.value.trim() === "") {
            //     setError(form.confirm, "Wymagana zgoda");
            //     complete = false;
            // } else {
            //     setSuccess(form.confirm);
            // }

            if (self.state.recaptcha !== '') {
                setSuccessRecaptcha();
            } else {
                setErrorRecaptcha('ReCaptcha jest polem wymaganym')
                complete = false;
            }

            if (complete === true) {
                let name, surname, email, file, jobOfferID;
                name = document.querySelector('#offerName').value;
                surname = document.querySelector('#offerSurname').value;
                email = document.querySelector('#offerEmail').value;
                file = document.querySelector('#offerFile').value;
                jobOfferID = document.querySelector('.work-item').getAttribute('data-id');

                let data = {
                    consent: true,
                    name: name,
                    surname: surname,
                    email: email,
                    file: file,
                    jobOffer: {
                        id: jobOfferID
                    }
                }

                Connect(`${endpoint.backend}${endpoint.sendWorkCV}`, 'POST', data).then(response => {
                    document.querySelector('#successOffer').click();
                })
            }

            return true;
        }
    }

    changeRecaptcha = (value) => {
        this.setState({
            recaptcha: value
        })
        console.log(value)
    }

    showJob = (e) => {
        e.preventDefault();
        let jobOffer = e.target.closest('.work-item').nextSibling;
        if (e.target.innerText === 'Zobacz ofertę') {
            jobOffer.classList.remove('d-none');
            e.target.innerText = 'Wróć';
        } else {
            jobOffer.classList.add('d-none');
            e.target.innerText = 'Zobacz ofertę';
        }
    }

    send = (e) => {
        e.preventDefault();
    }

    handleChange = (e) => {
        let filename = e.target.files[0].name;
        e.target.closest('.work-apply-section__cv-terms-box').querySelector('.work-apply-section__add-cv-text').innerText = `${filename} - Podmień plik CV (maksymalny rozmiar 1mb, format .pdf)`
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>Praca - Pizza Bunga</title>
                    <meta name="description" content="Dołącz do naszego zespołu! Oferujemy elastyczne godziny pracy, konkurencyjne wynagrodzenie i możliwość rozwoju. Pracuj w przyjaznej atmosferze i zdobywaj cenne doświadczenie!" />
                    <meta property="og:title" content="Praca - Pizza Bunga" />
                    <meta property="og:description" content="Dołącz do naszego zespołu! Oferujemy elastyczne godziny pracy, konkurencyjne wynagrodzenie i możliwość rozwoju. Pracuj w przyjaznej atmosferze i zdobywaj cenne doświadczenie!" />
                    <meta property="og:image" content="https://pizzabunga.pl/static/media/logo.cf443991.svg" />
                    <meta property="og:url" content="https://www.pizzabunga.pl/" />
                </Helmet>
                <Navbar sum={this.props.sum} counter={this.props.counter} summary={this.props.summary} />
                <section className="work-section">
                    <div className="work-section__items-wrapper">
                        <h1 className="text__medium--2 text__w500">Aktualne oferty pracy</h1>
                        <div className="work-section__offers-box">
                            <div className="work-item" data-id={301251}>
                                <div className="work-item__description-box">
                                    <div className="work-item__text-box">
                                        <span className="text__medium text__w500">Nazwa stanowiska:</span>
                                        <span className="text__medium text__w300">Pomoc kuchenna</span>
                                    </div>

                                </div>
                                <div className="work-item__location-box">
                                    <div className="work-item__text-box">
                                        <span txt="" className="text__medium text__w500">Miejsce pracy:</span>
                                        <span txt="" className="text__medium text__w300">ul.Grabiszyńska Wrocław</span>
                                    </div>

                                </div>
                                <a btn="" href=""
                                   className="btn" onClick={(e) => this.showJob(e)}>Zobacz ofertę</a>
                            </div>
                            <div className="work-apply-section__description-form-wrapper d-none">
                                <div className="job-descriptions">
                                    <div className="job-descriptions__tasks-ul-box">
                                        <h3 className="text__medium text__w500 job-descriptions__ul-header">Twoje
                                            zadania:</h3>
                                        <ul className="job-descriptions__tasks-list text__medium text__w300">
                                            <li>przygotowywanie posiłków zgodnie z zaplanowanym jadłospisem</li>
                                            <li>dbanie o najwyższą jakość i smak posiłków</li>
                                            <li>przestrzeganie ścisłych godzin wydawania posiłków</li>
                                            <li>wydawanie posiłków z uwzględnieniem dziecięcych diet</li>
                                        </ul>
                                    </div>
                                    <div className="job-descriptions__expectations-ul-box">
                                        <h3 className="text__medium text__w500 job-descriptions__ul-header">Oczekujemy
                                            od Ciebie:</h3>
                                        <ul className="job-descriptions__tasks-list text__medium text__w300">
                                            <li>doświadczenie w pracy w zawodzie kucharza</li>
                                            <li>wykształcenie gastronomiczne</li>
                                            <li>kreatywność, pomysłowość, energia i pasja do gotowania</li>
                                            <li>dobra organizacja pracy</li>
                                            <li>umiejętność pracy w zespole</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="work-apply-section__break-line"></div>
                                <form className="work-apply-section__form form-grid form-grid--work-apply" action="#">
                                    <div className="work-apply-section__input-group">
                                        <div className="input js-input" data-type="" data-error="false">
                                            <input className="input input__input" type="text" name="offerName"
                                                   id="offerName" placeholder="Imię" />
                                            <label className="input__label" htmlFor="offerName">Imię</label>
                                            <span className="input__error"></span>
                                        </div>
                                        <div className="input js-input" data-type="0" data-error="false">
                                            <input className="input input__input" type="text" name="offerSurname"
                                                   id="offerSurname" placeholder="Nazwisko" />
                                            <label className="input__label" htmlFor="offerSurname">Nazwisko</label>
                                            <span className="input__error"></span>
                                        </div>
                                        <div className="input js-input" data-type="0" data-error="false">
                                            <input className="input input__input" type="text" name="offerEmail"
                                                   id="offerEmail" placeholder="E-mail" />
                                            <label className="input__label" htmlFor="offerEmail">E-mail</label>
                                            <span className="input__error"></span>
                                        </div>
                                    </div>

                                    <div className="work-apply-section__cv-terms-box">
                                        <input className="work-apply-section__cv-input" type="file" id="offerFile"
                                               name="offerFile" accept="application/pdf" onChange={(e) => this.handleChange(e)} />
                                        <label className="work-apply-section__cv-label" htmlFor="offerFile">
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                 className="work-apply-section__icon"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M7.12565 6.07341L9.06077 4.13829V14.375C9.06077 14.6236 9.15954 14.8621 9.33535 15.0379C9.51117 15.2137 9.74963 15.3125 9.99827 15.3125C10.2469 15.3125 10.4854 15.2137 10.6612 15.0379C10.837 14.8621 10.9358 14.6236 10.9358 14.375V4.13829L12.8709 6.07341C12.9579 6.16085 13.0612 6.23028 13.1751 6.27771C13.2889 6.32513 13.411 6.34963 13.5343 6.3498C13.6576 6.34997 13.7798 6.3258 13.8938 6.27868C14.0077 6.23156 14.1113 6.16242 14.1985 6.07521C14.2857 5.98801 14.3549 5.88445 14.402 5.77048C14.4491 5.65651 14.4733 5.53436 14.4731 5.41103C14.4729 5.2877 14.4484 5.16562 14.401 5.05177C14.3536 4.93793 14.2841 4.83456 14.1967 4.74759L10.6612 1.21204C10.5741 1.12498 10.4708 1.05592 10.357 1.00881C10.2433 0.96169 10.1214 0.937439 9.99827 0.937439C9.87515 0.937439 9.75323 0.96169 9.63949 1.00881C9.52574 1.05592 9.42239 1.12498 9.33534 1.21204L5.79983 4.74759C5.71239 4.83456 5.64296 4.93793 5.59553 5.05177C5.54811 5.16562 5.52361 5.2877 5.52344 5.41103C5.52327 5.53436 5.54744 5.65651 5.59456 5.77048C5.64168 5.88445 5.71082 5.98801 5.79802 6.07521C5.88523 6.16242 5.98879 6.23156 6.10276 6.27868C6.21673 6.3258 6.33888 6.34997 6.46221 6.3498C6.58554 6.34963 6.70762 6.32513 6.82147 6.27771C6.93531 6.23028 7.03868 6.16085 7.12565 6.07341Z"
                                                    fill="black"></path>
                                                <path
                                                    d="M18.125 9.0625C17.8764 9.0625 17.6379 9.16127 17.4621 9.33709C17.2863 9.5129 17.1875 9.75136 17.1875 10V17.1875H2.8125V10C2.8125 9.75136 2.71373 9.5129 2.53791 9.33709C2.3621 9.16127 2.12364 9.0625 1.875 9.0625C1.62636 9.0625 1.3879 9.16127 1.21209 9.33709C1.03627 9.5129 0.9375 9.75136 0.9375 10V17.5C0.9375 17.9144 1.10212 18.3118 1.39515 18.6049C1.68817 18.8979 2.0856 19.0625 2.5 19.0625H17.5C17.9144 19.0625 18.3118 18.8979 18.6049 18.6049C18.8979 18.3118 19.0625 17.9144 19.0625 17.5V10C19.0625 9.75136 18.9637 9.5129 18.7879 9.33709C18.6121 9.16127 18.3736 9.0625 18.125 9.0625Z"
                                                    fill="black"></path>
                                            </svg>
                                            <span className="work-apply-section__add-cv-text">Załącz plik CV (maksymalny rozmiar 1mb, format .pdf)</span>
                                        </label>
                                        <span className="input__error fileError"></span>
                                        <div className="check-box work-apply-section__terms-check-box">
                                            <input className="check-box__input" type="checkbox" name="confirm" id="terms" />
                                            <label className="check-box__label" htmlFor="terms">
                                                <Checkbox />
                                            </label>
                                            <label
                                                className="check-box__label-text text__small text__w300 text__lh-20"
                                                htmlFor="terms">Wyrażam zgodę na przetwarzanie przez Pizza Bunga
                                                sp. z o.o. moich danych osobowych zawartych w aplikacji o pracę
                                                na potrzeby przyszłych procesów rekrutacyjnych. Jestem
                                                świadomy/a, że mam prawo do wycofania zgody w każdym czasie.
                                                Wycofanie zgody nie ma wpływu na zgodność z prawem przetwarzania
                                                dokonanego przed jej wycofaniem.</label>
                                            <span className='error checkboxError'></span>
                                        </div>
                                    </div>
                                    <div className="recaptcha" col-2-3="">
                                        <ReCAPTCHA
                                          ref={recaptchaRef}
                                          sitekey={SITE_KEY}
                                          onChange={this.changeRecaptcha}
                                        />
                                        <span className="error checkboxError errorRecaptcha"></span>
                                    </div>
                                    <button className="work-apply-section__btn btn js-active-modal"
                                            type="submit">Aplikuj
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div pagination="" className="pagination d-none">
                        <span className="pagination__current-page-no">1</span>
                        <span>...</span>
                        <span className="pagination__last-page-no">3</span>
                        <div className="pagination__icon">→</div>
                    </div>
                    <Footer />
                </section>
            </>
        )
    }
}

export default Work;